import React,{Component}from'react';
import PropTypes from'prop-types';import{connect}from"react-redux";import{getDeveloper,changePassword}from"../../actions/DeveloperActions";import{Grid,Card,CardContent,FormControl,InputLabel,Input,Button,FormHelperText}from'@material-ui/core';import{Avatar}from"@material-ui/core";
import setJWTToken from"../../securityUtils/setJWTToken";
import Snackbar from'@material-ui/core/Snackbar';
import MuiAlert from'@material-ui/lab/Alert';import{REACT_URL_APP_LIST}from'../../actions/ReactEndPoints';import{Link}from'react-router-dom';
import IconButton from'@material-ui/core/IconButton';
import InputAdornment from'@material-ui/core/InputAdornment';
import Visibility from'@material-ui/icons/Visibility';
import VisibilityOff from'@material-ui/icons/VisibilityOff';import"../../App.css";import"../Application/Application.css";import"../DeveloperManagement/DeveloperMgmt.css";

import{withTranslation}from'react-i18next';import{compose}from'redux';

class ChangePassword extends Component {
	state=
	{
		isPasswordShown: false,
		isPasswordShown1: false,
		isPasswordShown2: false
	  };

	togglePasswordVisiblity=()=>
	{
		const { isPasswordShown } = this.state;
		this.setState({ isPasswordShown: !isPasswordShown });
	  };togglePasswordVisiblity1=()=>
	{
		const { isPasswordShown1 } = this.state;
		this.setState({ isPasswordShown1: !isPasswordShown1 });
	  };togglePasswordVisiblity2=()=>
	{
		const { isPasswordShown2 } = this.state;
		this.setState({ isPasswordShown2: !isPasswordShown2 });
	  };
	// set state

	constructor(props) {
    super(props);
    this.state = {
      id:"",
      developerId:"",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      secondaryEmail: "",
      state: "",
      city: "",
      country: "",
      zipCode: "",
      role:"",
      isActive:"",
      password:"",
      confirmPassword:"",
      newPassword:"",
      oldPassword:"",
      address:"",
      profileUrl:"",
      success:false,
      errors: {},
	  show:"none",
	  edit:false,
	  readonly:true,
	  tempFirstName:'',
	  tempLastName:'',
	  tempEmail:'',
	  tempPhone:'',
	  tempAddress:'',
	  tempCity:'',
	  tempState:'',
	  tempCountry:'',
	  tempPasswordTemp:"",
      tempPasswordNew:"",
      tempPasswordOld:"",
	};
	this.resetState = this.resetState.bind(this);
	this.onCloseAlert = this.onCloseAlert.bind(this);
    this.onChange = this.onChange.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	this.handleEdit = this.handleEdit.bind(this);
  }

	onCloseAlert() {
	this.setState({ success: false, edit: false, readonly: true, errors:{} });
	localStorage.removeItem("jwtToken");
	setJWTToken(false);
	window.location.href = "/dev/login";
	}

	handleEdit(){
	 
	this.setState({
		
			edit:!this.state.edit,
			readonly:false,
			tempPasswordNew:this.state.newPassword,
			tempPasswordOld:this.state.oldPassword,
			tempPasswordTemp:this.state.confirmPassword
	
	})
	
  }

	resetState(){
	 
	this.setState({
		
			edit:!this.state.edit,
			readonly:true,
			errors:{},
			newPassword:this.state.tempPasswordNew,
			oldPassword:this.state.tempPasswordOld,
			confirmPassword:this.state.tempPasswordTemp
	
	})
  }

	async componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			
			const {
        id,
        firstName,
        lastName,
        email,
        phone,
        secondaryEmail,
        state,
        city,
        country,
        zipCode,
        role,
        isActive,
        address,
        ciry,
        profileUrl,
        success,
		show,
			} = nextProps.developer;
			this.setState({
        id,
        firstName,
        lastName,
        email,
        phone,
        state,
        city,
        country,
        zipCode,
        role,
        isActive,
        secondaryEmail,
        address,
        ciry,
        profileUrl,
        success,
		show
			});
		} else {

    	}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		this.state.developerId = id;
		
		this.props.getDeveloper(this.state.developerId, this.props.history);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		const newDeveloper = {
		id:this.state.id,
		firstName:this.state.firstName,
		lastName:this.state.lastName,
		email:this.state.email,
		phone:this.state.phone,
		secondaryEmail:this.state.secondaryEmail,
		state:this.state.state,
		city:this.state.city,
		country:this.state.country,
		zipCode:this.state.zipCode,
		role:this.state.role,
		address:this.state.address,
		profileUrl:this.state.profileUrl,
		newPassword:this.state.newPassword,
		oldPassword:this.state.oldPassword,
		confirmPassword:this.state.confirmPassword,
		success:this.state.success,
		show:this.state.show
		};
		this.props.changePassword(newDeveloper, this.props.history);
		
	}

	render() {
		const { isPasswordShown } = this.state;
		const { isPasswordShown1 } = this.state;
		const { isPasswordShown2 } = this.state;

		const { t } = this.props;

		const { errors } = this.state;

	function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

		const avatar = {
			height: "90px !important",
			width: "90px !important",
			backgroundColor: "rgba(0, 143, 165, 1)",
			marginTop: "4%",
			border: "2px solid",
		};

		return (
			<div className="devPortalLabel changePasswordSection changePasswordStyle"> 
				<Grid container justify="center"  >
					
					<Grid item xs={12} sm={2}>
					</Grid>

					<Grid item xs={10} sm={8} style={{ height: "100%" }}>
						
						<div >
                            <Grid container style={{ paddingTop: "7%" }}>
                                <Grid item xs={12} sm={8} style={{ borderBottom: "1px solid #0099FF", boxShadow: "none !important", textAlign: "left", flexBasis: "100%", maxWidth: "100%", marginBottom: "20px" }}>
                                    <label style={{ font: "Montserrat,medium ", fontSize: "20px", marginRight: "5px", color: "#003E67" }}>
                                        {t("changePassword")}
                        			</label>
                                </Grid>
                            </Grid>

 

                        </div>
						<Card className="changePasswordBlock" >
							<CardContent title="" className="changePasswordHeader">
							<Avatar size={100} style={avatar} className="test" >{this.state.firstName?this.state.firstName[0]:'none'}</Avatar>
								
							</CardContent>
							<form onSubmit={this.onSubmit}>
								<CardContent className="changePasswordBody">

									<Grid container>																				
										<Grid item xs={12} sm={2}/>
											<Grid item xs={12} sm={5}>
												<FormControl className="form-control">
													<InputLabel className="input-label"> {t("currentPassword")}</InputLabel>
													<Input required  name="oldPassword"  className="input-field"
													 
														autoComplete="off"
														value={this.state.oldPassword}
														onChange={this.onChange}
														type={isPasswordShown ? "text" : "password"}
														error={this.state.errors.password ? true : false}
														endAdornment={
															<InputAdornment position="end">
															  <IconButton className="password-icon"
															   tabIndex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity}
																>
																{isPasswordShown ? <Visibility /> : <VisibilityOff />}
															 </IconButton>
															</InputAdornment>
														  }
													/>
													<FormHelperText error={true}>{this.state.errors.password}</FormHelperText>													
												</FormControl>
												<FormControl className="form-control">
													<InputLabel required className="input-label">{t("newPassword")}</InputLabel>
													<Input required name="newPassword"  className="input-field"
														 
														autoComplete="off"
														value={this.state.newPassword}
														type={isPasswordShown1 ? "text" : "password"}
														onChange={this.onChange}
														error={this.state.errors.newPassword ? true : false}
														endAdornment={
															<InputAdornment position="end">
															  <IconButton className="password-icon"
																tabIndex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity1}
																>
																{isPasswordShown1 ? <Visibility /> : <VisibilityOff />}
															 </IconButton>
															</InputAdornment>
														  }
														inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30' }}
													/>
													<FormHelperText error={true}>{this.state.errors.newPassword}</FormHelperText>													
												</FormControl>
												<FormControl className="form-control">
													<InputLabel className="input-label">{t("currentPassword")}</InputLabel>
													<Input  required name="confirmPassword"  className="input-field"
														 
														autoComplete="off"
														value={this.state.confirmPassword}
														type={isPasswordShown2 ? "text" : "password"}
														onChange={this.onChange}
														error={this.state.errors.confirmPassword ? true : false}
														endAdornment={
															<InputAdornment position="end">
															  <IconButton className="password-icon"
															 tabIndex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity2}
																>
																{isPasswordShown2 ? <Visibility /> : <VisibilityOff />}
															 </IconButton>
															</InputAdornment>
														  }
														inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30' }}
													/>
													<FormHelperText error={true}>{this.state.errors.confirmPassword}</FormHelperText>
													
												</FormControl>
												
											</Grid>
											<Grid item xs={12} sm={5}>									
										</Grid>
										{/* <Grid container style={{display:this.state.edit ? '' : 'none'}}>
											<Grid item xs={12} sm={5}></Grid>
											<Grid item xs={6} sm={1} className="two-button-grid">
												<Button variant="contained" color="primary" type="submit" className="update-button">
													Update
     					 						</Button>
											</Grid>
											<Grid item xs={6} sm={1} className="two-button-grid">
												<Button variant="contained" color="secondary" className="addApplicationBtn" onClick={this.resetState}>CANCEL</Button>
											</Grid>
										</Grid> */}
										
										<Grid container justify="center">
											<Grid item xs={6} className="two-button-grid" container
												direction="row"
												justify="flex-end"
												alignItems="flex-start" >
												<Button variant="contained" color="primary" type="submit"  className="update-button">
													{t("update")}
     					 						</Button>
											</Grid>
											<Grid item xs={6} className="two-button-grid" 
												container
												direction="row"
												justify="flex-start"
												alignItems="flex-start">
												<Button variant="contained" color="secondary" className="addApplicationBtn"  component={ Link } to={REACT_URL_APP_LIST}>{t("cancel")}</Button>
											</Grid>
										</Grid>



									</Grid>
								</CardContent>


							</form>
						</Card>
					</Grid>

					<Grid item xs={12} sm={2}>
						{(this.state.success && (Object.keys(errors).length === 0)) && (
							<Snackbar anchorOrigin={{ vertical: "center", horizontal: "center" }} open={this.state.success} autoHideDuration={2000} onClose={this.onCloseAlert}>
							<Alert onClose={this.onCloseAlert} severity="success">
								{t("passwordUpdateStatus")}
								</Alert>
						</Snackbar>
						)}
					</Grid>

				</Grid >

			</div >
		);
	}
}

// export default ChangePassword;
ChangePassword.propTypes={getDeveloper:PropTypes.func.isRequired,changePassword:PropTypes.func.isRequired,errors:PropTypes.object.isRequired};

const mapStateToProps=state=>({developer:state.developer.developer,security:state.security,errors:state.errors});

/*
 * export default connect( mapStateToProps, { getDeveloper, changePassword }
 * )(ChangePassword);
 */

export default compose(withTranslation(),connect(mapStateToProps,{getDeveloper,changePassword}))(ChangePassword);