import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import securityReducer from "./securityReducer";
import UserReducer from "./UserReducer";
import ApplicationReducer from "./ApplicationReducer";
import DeveloperReducer from "./DeveloperReducer";
import ServiceReducer from "./ServiceReducer";

export default combineReducers({
  errors: errorReducer,
  security: securityReducer,
  user: UserReducer,
  devapp:ApplicationReducer,
  developer:DeveloperReducer,
  services:ServiceReducer
});
