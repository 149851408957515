import axios from "axios";
import { GET_ERRORS, GET_FEATURES, GET_FEATURE, DELETE_FEATURE } from "./types";

export const createFeature = (appid, feature) => async dispatch => {
  try {
    await axios.post("/api/v1.0/feature/" + appid+"/features", feature);
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

export const updateFeature = (appid, feature) => async dispatch => {
  try {
    await axios.put("/api/v1.0/feature/" + appid+"/features", feature);
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

export const getFeatures = (appId) => async dispatch => {
  const res = await axios.get("/api/v1.0/applications/"+ appId+"/feature");
  dispatch({
    type: GET_FEATURES,
    payload: res.data.data
  });
};

export const getFeature = (id, history) => async dispatch => {
  try {
    const res = await axios.get(`/api/v1.0/feature/${id}/features`);
    dispatch({
      type: GET_FEATURE,
      payload: res.data.data
    });
  } catch (error) {
    history.push("/");
  }
};

export const deleteFeature = id => async dispatch => {
  await axios.delete(`/api/v1.0/feature/${id}/features`);
  dispatch({
    type: DELETE_FEATURE,
    payload: id
  });
};
