import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { withTranslation } from 'react-i18next';

import { compose } from 'redux';

import axios from 'axios';
import AllUsers from './AllUsers';
import DevPortalLabel from '../Layout/DevPortalLabel';

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      allCount: 0,
      allactiveCount: 0,
      active: true,
    };
  }

  componentDidMount() {
    axios.get('/api/v1.0/developers/dashboard_data').then((response) => {
      this.setState({
        data: response.data.data,
      });
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <DevPortalLabel></DevPortalLabel>
        <div style={{ padding: '15px', margin: '10px', marginLeft: '324px' }}>
          <Card style={{ margin: '10px', marginTop: '40px', minWidth: 275, display: 'inline-block', borderBottom: 'solid 4px #0099ff' }}>
            <CardContent>
              <Typography style={{ color: '#0099ff' }}>{t('allRigisterUsersCount')}</Typography>
            </CardContent>
            <CardActions>
              <h1 style={{ color: '#002143', marginLeft: '2%' }}>{this.state.data.registerUsers}</h1>
            </CardActions>
          </Card>
          <Card style={{ margin: '10px', marginTop: '20px', minWidth: 275, display: 'inline-block', borderBottom: 'solid 4px #0099ff' }}>
            <CardContent>
              <Typography style={{ color: '#0099ff' }}>{t('allActiveUsersCount')}</Typography>
            </CardContent>
            <CardActions>
              <h1 style={{ color: '#002143', marginLeft: '2%' }}>{this.state.data.activeCount}</h1>
            </CardActions>
          </Card>
        </div>
        <AllUsers data={this.state.data.usersList}></AllUsers>
      </div>
    );
  }
}

DashboardPage.propTypes = {};

export default compose(withTranslation())(DashboardPage);
