import axios from "axios";
import { GET_ERRORS, GET_APPLICATIONS, GET_APPLICATION, DELETE_APPLICATION } from "./types";
import { REACT_PATH_APP_LIST } from "./ReactEndPoints";


export const createApplication = (devId, application, history) => async dispatch => {
  try {
    const res = await axios.post("/api/v1.0/applications/"+devId, application);
    history.push("/dev/ApplicationDetail/"+res.data.data.clientId+"&"+res.data.data.clientSecrate);
      dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

export const updateApplication = (devId, app, history) => async dispatch => {
	try {
    const res = await axios.put("/api/v1.0/applications/"+devId, app);			
    if(res.status === 200 || res.status === 201){
      app.success = true;
      app.show = "";
      dispatch({
        type: GET_APPLICATION,
        payload: app
      }
      );
    }

    dispatch({
			type: GET_ERRORS,
			payload: {}
    });

	} catch (err) {
    dispatch({
			type: GET_ERRORS,
			payload: err.response.data.data
		});
	}
};



export const getApplications = (devId) => async dispatch => {
  const res = await axios.get("/api/v1.0/applications/developers/"+devId);
  dispatch({
    type: GET_APPLICATIONS,
    payload: res.data.data
  });
};

export const getApplication = (id, history) => async dispatch => {
  try {
    const res = await axios.get(`/api/v1.0/applications/${id}`);
    dispatch({
      type: GET_APPLICATION,
      payload: res.data.data
    });
  } catch (error) {
    history.push("/");
  }
};

export const regenerate = (id, history) => async dispatch => {
  try {
    const res = await axios.get(`/api/v1.0/applications/${id}/secretes`);
    dispatch({
      type: GET_APPLICATION,
      payload: res.data.data
    });
  } catch (error) {
    history.push("/");
  }
};

export const deleteApplication = (id, devId, history) => async dispatch => {
  await axios.delete(`/api/v1.0/applications/${id}`);
  history.push(REACT_PATH_APP_LIST+devId);
  dispatch({
    type: DELETE_APPLICATION,
    payload: id
  });
};

export const getAppRole = (devId, appId) => async dispatch => {
  const res = await axios.get("/api/v1.0/applications/appRole?appId="+appId+"&devId="+devId);
  return res.data.data;
};