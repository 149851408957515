import React, { Component } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Avatar,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { createApplication } from "../../actions/ApplicationAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./Application.css";
import { REACT_URL_DASHBOARD } from "../../actions/ReactEndPoints";
import TimeLine from "../Layout/Breadcrumb";
import DevPortalLabel from "../Layout/DevPortalLabel";
import "./AddApplicationStyle.css";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { default as i18n } from "i18next";
import ApplicationLogo from "./ApplicationLogo";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggesduser: this.props.security.user,
      applicationName: "",
      applicationLogo: "",
      applicationUrl: "",
      applicationType: "",
      applicationPlatform: "",
      developerId: "",
      callbackUrl: "",
      appDeletionWebhook: "",
      userDeletionWebhook: "",
      version: "",
      apiSpecificationLink: "",
      errors: {},
      error: false,
      hasError: false,
      status: "",
      image: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  componentDidMount() {}

  onSubmit(e) {
    e.preventDefault();
    const newApplication = {
      applicationName: this.state.applicationName,
      applicationLogo: this.state.applicationLogo,
      applicationUrl: this.state.applicationUrl,
      applicationType: this.state.applicationType,
      applicationPlatform: this.state.applicationPlatform,
      callbackUrl: this.state.callbackUrl,
      appDeletionWebhook: this.state.appDeletionWebhook,
      userDeletionWebhook: this.state.userDeletionWebhook,
      version: this.state.version,
      apiSpecificationLink: this.state.apiSpecificationLink,
      status: this.state.status,
    };

    this.props.createApplication(
      this.state.loggesduser.id,
      newApplication,
      this.props.history
    );
  }

  handleImage(file) {
    this.setState({ applicationLogo: file.base64 });
    this.setState({ image: file });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { t } = this.props;
    const { id } = this.props.match.params;
    this.state.developerId = id;

    return (
      <div className="projects addApplicationStyle">
        <DevPortalLabel></DevPortalLabel>
        <TimeLine path={t("applicationsAndCreateAppllication")} />
        <Grid container justify="center">
          <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
            <div className="addApplicationBlock">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                // style={{
                // 	backgroundImage: `url(${FormHeaderSmall})`,
                // }}
              >
                <Typography component="div">
                  <Box
                    justifyContent="center"
                    textAlign="center"
                    m={1}
                    color="white"
                    font="normal normal 600  Lato"
                    letterSpacing="0.8px"
                    fontSize="small"
                    opacity="1"
                  >
                    {t("createApp")}
                  </Box>
                </Typography>
              </Box>
              <form onSubmit={this.onSubmit} autoComplete="off">
                <Box display="flex" justifyContent="center">
                  <ApplicationLogo
                    id={"application-logo-image"}
                    onsetProfile={(image) => this.handleImage(image)}
                    profilePhoto={this.state.image}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.applicationName ? true : false}
                    helperText={this.state.errors.applicationName}
                    name="applicationName"
                    //id="applicationName"
                    value={this.state.applicationName}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("appName")}
                    inputProps={{
                      pattern: "^d*[a-zA-Z][a-zA-Z0-9 ]*$",
                      title: i18n.t("allowsAlphaNumeric"),
                      style: { textTransform: "uppercase" },
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      {t("appPlatform")}
                    </InputLabel>
                    <Select
                      error={
                        !this.state.errors.applicationPlatform ? false : true
                      }
                      //helperText={this.state.errors.applicationPlatform}
                      name="applicationPlatform"
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={this.state.open}
                      onClose={this.handleClose}
                      onOpen={this.handleOpen}
                      value={this.state.applicationPlatform}
                      onChange={this.onChange}
                    >
                      <MenuItem value="">
                        <em>{t("none")}</em>
                      </MenuItem>
                      <MenuItem value="Web">{t("web")}</MenuItem>
                      <MenuItem value="Native">{t("native")}</MenuItem>
                      <MenuItem value="Service">{t("service")}</MenuItem>
                      <MenuItem value="single-page app">
                        {t("singlePageApp")}
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {this.state.errors.applicationPlatform}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="center" width="100%">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      {t("appType")}
                    </InputLabel>
                    <Select
                      name="applicationType"
                      error={
                        !this.state.errors.applicationPlatform ? false : true
                      }
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={this.state.open}
                      onClose={this.handleClose}
                      onOpen={this.handleOpen}
                      value={this.state.applicationType}
                      onChange={this.onChange}
                    >
                      <MenuItem value="">
                        <em>{t("none")}</em>
                      </MenuItem>
                      <MenuItem value="Personal">{t("personal")}</MenuItem>
                      <MenuItem value="Team Application">
                        {t("teamApp")}
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {this.state.errors.applicationType}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    name="callbackUrl"
                    value={this.state.callbackUrl}
                    error={this.state.errors.callbackUrl ? true : false}
                    helperText={this.state.errors.callbackUrl}
                    onChange={this.onChange}
                    id="custom-css-standard-input"
                    placeholder=""
                    label={t("callBackUrl")}
                    //inputProps={{pattern: '^(ftp|http|https):\/\/[^ "]+$',title:i18n.t('validUrl')}}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    name="appDeletionWebhook"
                    value={this.state.appDeletionWebhook}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("appDltWebhook")}
                    //inputProps={{pattern: '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',title:i18n.t('validUrl')}}
                    inputProps={{
                      pattern: '^(ftp|http|https)://[^ "]+$',
                      title: i18n.t("validUrl"),
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    name="applicationUrl"
                    value={this.state.applicationUrl}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={"Application URL"}
                    //inputProps={{pattern: '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',title:i18n.t('validUrl')}}
                    inputProps={{
                      pattern: '^(ftp|http|https)://[^ "]+$',
                      title: i18n.t("validUrl"),
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    name="userDeletionWebhook"
                    value={this.state.userDeletionWebhook}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("userDltWebhook")}
                    //inputProps={{pattern: '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',title:i18n.t('validUrl')}}
                    inputProps={{
                      pattern: '^(ftp|http|https)://[^ "]+$',
                      title: i18n.t("validUrl"),
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.version ? true : false}
                    helperText={this.state.errors.version}
                    name="version"
                    id="version"
                    value={this.state.version}
                    onChange={this.onChange}
                    label={t("version")}
                    inputProps={{
                      pattern: "^(?:1|[1-9][1-9]*)(?:.(0|[0-9][0-9]*))*$",
                      title: i18n.t("versionAllowsNumsAndDots"),
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="center" width="100%">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      {t("status")}
                    </InputLabel>
                    <Select
                      name="status"
                      error={!this.state.errors.status ? false : true}
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={this.state.open}
                      onClose={this.handleClose}
                      onOpen={this.handleOpen}
                      value={this.state.status}
                      onChange={this.onChange}
                    >
                      <MenuItem value="">
                        <em>{t("none")}</em>
                      </MenuItem>
                      <MenuItem value="CREATED">{t("created")}</MenuItem>
                      <MenuItem value="RELEASED">{t("released")}</MenuItem>
                      <MenuItem value="ABSOLUTE">{t("absolute")}</MenuItem>
                    </Select>
                    <FormHelperText>{this.state.errors.status}</FormHelperText>
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="center" p={3}>
                  <Box justifyContent="center" p={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="submitBtn"
                    >
                      {t("create")}
                    </Button>
                  </Box>
                  <Box justifyContent="center" p={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="go-forward"
                      component={Link}
                      to={REACT_URL_DASHBOARD}
                    >
                      {t("cancel")}
                    </Button>
                  </Box>
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
Home.propTypes = {
  //classes: PropTypes.object.isRequired,
  createApplication: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  security: state.security,
});

/*
export default connect(
	mapStateToProps,
	{ createApplication }
)(Home); */

export default compose(
  withTranslation(),
  connect(mapStateToProps, { createApplication })
)(Home);
