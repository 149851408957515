import React, { Component } from 'react' 
import { Button } from '@material-ui/core';
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { withTranslation } from 'react-i18next';
import {compose} from 'redux';

  class AllUsers extends Component {  
    
      constructor(props) {  
    
        super(props)  
    
        this.state = {  
    
          data: []  ,
          page:0,
          rowsPerPage:5
    
        }  
    
      }  
        handleChangePage = (event, newPage) => {  
        
            
            this.setState({page:newPage})  
        
          };  
        
          
        
              handleChangeRowsPerPage = event => {  
        
            
            this.setState({rowsPerPage:+event.target.value})
             
            this.setState({page:0})
        
          }; 
          
          render() {  
            
            
            const { t } = this.props;	
            
            const columns = [
              {title:  t("firstName"), field: "firstName"},
              {title: t('lastName'), field: "lastName"},
              {title: t("email"), field: "email"},
              {title: t("role"), field: "role"},
              {title: t("isActive"), field: "isActive",
             	 render:rowData=> rowData.isActive?"Active":"InActive"}
              
              ];

              const tableIcons = {
                Add: forwardRef((props, ref) => (<Button {...props} ref={ref} variant="contained" style={{background:"#0099FF",color:"white"}} disabled>{t("create")}</Button>
                        )),
                Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'green' }} tooltip=""/>),
                Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'red' }} />),
                Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }}/>),
                DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
                Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }}/>),
                Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }}/>),
                Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }}/>),
                FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }}/>),
                LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
                NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
                PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }}/>),
                ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
                Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }}/>),
                SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }}/>),
                ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }}/>),
                ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }}/>)
              };
                return (  
             
                  <MaterialTable
				title="status"
                columns={columns}
                icons={tableIcons}
				data={this.props.data} 
				options={{
					actionsColumnIndex: -1,
					addRowPosition: 'first',
					showTitle: false,
					toolbarButtonAlignment:"left",
					 
				}}
				 localization={{
                                body: {
                                    emptyDataSourceMessage: t("emptyRecordsInfo"),
                                    editTooltip: t("edit"),
                                    deleteTooltip: t("delete"),
                                    addTooltip: t("create"),
                                    editRow: {
                                        deleteText: t("deleteWarning"),
                                        cancelTooltip: t("cancel"),
                                        saveTooltip: "",
                                    },
                                },
                                pagination: {
                                    labelDisplayedRows:"{from}-{to}" + "  "+t("of")+"  "+"{count}",
                                    labelRowsSelect: t("rows"),
                                    firstAriaLabel: t("firstPage"),
                                    firstTooltip: t("firstPage"),
                                    previousAriaLabel: t("previousPage"),
                                    previousTooltip: t("previousPage"),
                                    nextAriaLabel: t("nextPage"),
                                    nextTooltip: t("nextPage"),
                                    lastAriaLabel: t("lastPage"),
                                    lastTooltip: t("lastPage"),
                                },
								header: {
									actions: t("action")
								},
								toolbar: {
									searchPlaceholder: t("searchPlaceholder"),
									searchTooltip: t("search")
								},

 

                            }}

			 
				/>
                );  
            
              }  


    }
    export default compose(withTranslation()) (AllUsers)
    
