import React,{Component}from'react';import"./styles.css";import{Link}from'react-router-dom';
import{Button}from'@material-ui/core';
import axios from'axios'
import { default as i18n } from 'i18next';

class SuccessPage extends Component {
	constructor(props) {
	    super(props);
	    
	   this.handleClick=this.handleClick.bind(this); 
	}
	
	handleClick(data)
		{
		   const { history } = this.props;
		   axios.post("/api/v1.0/reregister/"+data.email).then(
		   history.push({
	 		  pathname: '/dev/SuccessPage',
	 		  state: { action:"ACCOUNT_ACTIVATE", message:  i18n.t("accountActivation"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage"),data:data }
		    }))
		    
		}

	render() {
	 console.log(this.props	)
	     
    	const {message,link,linkLabel,data} = this.props.location.state;
		let sucsess;

		if (data.ret =="0") {
			  	sucsess =<div id="successPage">
						<div className="successPage">
						 <h2>{message}</h2>
						 <Button variant="contained" color="primary" onClick={()=>this.handleClick(data.data)} style= {{marginTop:"15px"}}>
						 {linkLabel}
						</Button>
					   </div>
                   </div>
				 	
		}else {
			 	sucsess =<div id="successPage">
					<div className="successPage">
						 
						<div className="successmsg">
							<span className="message">
								<h3>{message}</h3>
							</span>
						</div>
						<div className="successLink">
							<Link to={link}>{linkLabel}</Link>
						</div>
					</div>
		</div>
		}
		 
		
		return (
				<div id="successPage">
				{sucsess}
				</div>
	)
		/*return (
			<div id="successPage">
			{isLoggedIn ? <button>Logout</button> : <button>Login</button>}
				<div className="successPage">
					{ <div class="notfound-404">
						<h1>Coming soon</h1>
					</div> }
					<div className="successmsg">
						<span className="message">
							<h3>{message}</h3>
						</span>
					</div>
					<div className="successLink">
						<Link to={link}>{linkLabel}</Link>
					</div>
				</div>
			</div>
		);*/
	}
}

export default SuccessPage;