import React, { Component } from 'react';
import { Grid, Fab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Servicetabs from './ServiceTabs';
import TimeLine from '../Layout/Breadcrumb';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DevPortalLabel from '../Layout/DevPortalLabel';
import { getServiceList } from '../../actions/ServiceAction';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import { withTranslation } from 'react-i18next';

class ServiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceList: [],
      publicServiceArr: [],
      internalServiceArr: [],
      isLoading: true,
      role: '',
    };
  }

  componentDidMount() {
    this.props.getServiceList(this.props.history);
  }

  render() {
    // const { publicServiceArr,internalServiceArr,serviceList } = this.state;

    //const {serviceList} = this.props;

    let serviceDataTemp = this.props.serviceList;
    let publicServiceTempArr = [],
      internalServiceTempArr = [];
    if (serviceDataTemp.length > 0) {
      publicServiceTempArr = serviceDataTemp.filter(function(service) {
        return service.serviceType === 'public' || service.serviceType === 'PUBLIC';
      });

      internalServiceTempArr = serviceDataTemp.filter(function(service) {
        return service.serviceType === 'internal' || service.serviceType === 'INTERNAL';
      });
    }
    const { id } = this.props.match.params;

    this.state.role = id;

    const classes = {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
      },
      gridList: {
        width: '100',
        height: '100',
      },
    };

    const btnStyle = {
      background: '#0099FF',
      '&:focus': {
        outline: 'none !important',
        outlineOffset: 'none !important',
        boxShadow: 'none !important',
      },
      ':hover': {
        outline: 'none !important',
        outlineOffset: 'none !important',
        boxShadow: 'none !important',
      },
    };
    /*  const path = "Services"; */

    const { t } = this.props;
    return (
      <div className='root'>
        <DevPortalLabel></DevPortalLabel>
        <Grid>
          <TimeLine path={t('services')} page={'/dev/services/' + this.state.role} />
          <Grid container justify='center' style={{ backgroundColor: 'white' }}>
            <Grid item xs={8} m={3} style={{ border: 'none', boxShadow: 'none !importent' }} container direction='row' justify='flex-end' alignItems='flex-end'>
              {this.props.security.user.email === 'developer@zkteco.in' && (
                <Fab size='medium' color='primary' aria-label='add' className={classes.margin} style={btnStyle} component={Link} to='/dev/create'>
                  <PlaylistAddRoundedIcon />
                </Fab>
              )}
            </Grid>
          </Grid>

          <Servicetabs
            publicServiceArr={publicServiceTempArr}
            internalServiceArr={internalServiceTempArr}
            serviceData={serviceDataTemp}
            appRole={this.state.appRole}
            id={id}
            role={this.props.match.params.id}
            user={this.props.security.user}
          />

          <Grid item xs style={{ backgroundColor: 'white' }} display='flex'></Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  serviceList: state.services.serviceList,
  security: state.security,
});

// export default connect(
//   mapStateToProps,
//   { getServiceList }
// )(ServiceList);

export default compose(withTranslation(), connect(mapStateToProps, { getServiceList }))(ServiceList);
