import axios from "axios";
import { GET_ERRORS, GET_DEVELOPERS, GET_DEVELOPER, DELETE_DEVELOPER,GET_DASHBOARD} from "./types";
import { URL_GET_DEVELOPER, URL_DELETE_DEVELOPER, URL_UPDATE_PASSWORD } from "./EndPoints";
import { REACT_PATH_CHANGE_PASSWORD } from "./ReactEndPoints";

export const updateDeveloper = (developer, history) => async dispatch => {
  dispatch({
        type: GET_DEVELOPER,
        payload: developer
      }
      );
};

export const changePassword = (developer, history) => async dispatch => {
  try {
    const res = await axios.put(URL_UPDATE_PASSWORD, developer);
    history.replace(REACT_PATH_CHANGE_PASSWORD + developer.id)
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
    if (res.status === 201) {
      developer.success = true;
      developer.show = "none";
      dispatch({
        type: GET_DEVELOPER,
        payload: developer
      }
      );
    } else if (res.status === 401) {
      history.replace("/logout")
    }

  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};


export const getDevelopers = () => async dispatch => {
  const res = await axios.get("/api/v1.0/developers");
  dispatch({
    type: GET_DEVELOPERS,
    payload: res.data.data
  });
};

export const getDashBoardDevelopers = () => async dispatch => {
	  const res = await axios.get("/api/v1.0/developers/dashboard_data");
	  dispatch({
	    type: GET_DASHBOARD,
	    payload: res.data.data
	  });
	};

export const getDeveloper = (id, history) => async dispatch => {
  try {
    const res = await axios.get(URL_GET_DEVELOPER + id);
    if(res.status===200)
	  {
		dispatch({
			type: GET_ERRORS,
			payload: {}
			
		  }); 
	  }
    dispatch({
      type: GET_DEVELOPER,
      payload: res.data.data
    });
  } catch (error) {
    history.push("/");
  }
};

export const deleteDeveloper = id => async dispatch => {
  await axios.delete(URL_DELETE_DEVELOPER + id);
  dispatch({
    type: DELETE_DEVELOPER,
    payload: id
  });
};
