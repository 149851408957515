import React, { Component } from 'react';
import "./ComingSoon.css";
import { Link } from 'react-router-dom';
class FailurePage extends Component {
	render() {
		const {message,link,linkLabel} = this.props.location.state;
		return (
			<div id="failurePage">
				<div className="failurePage">
					<div className="failuremsg">
						<span className="message">
							<h3>{message}</h3>
						</span>
					</div>
					<div className="failureLink">
						<Link to={link}>{linkLabel}</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default FailurePage;