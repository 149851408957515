import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import FileBase64 from "react-file-base64";
import { useTranslation } from "react-i18next";

export default function ApplicationLogo(props) {
  const { t } = useTranslation();
  const [profile, setProfile] = React.useState();
  const [file, setFile] = React.useState({});

  const [state, setState] = React.useState({
    profilePhoto: "",
  });

  const handleProfile = (file) => {
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/bmp"
    ) {
      let fileSize = file.size.split(" ")[0];
      let size = parseInt(fileSize);
      if (size <= 3072) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(",")[1],
        });
        props.onsetProfile(file);
        setProfile(file.base64);
      } else {
        setFile("");
      }
    } else {
      setFile("");
      //setProfile(state.profilePhoto);
    }
  };

  return (
    <Box
      display={"flex"}
      direction="row"
      alignItems="center"
      id="applicationLogo-main-box"
    >
      <Box
        display={"flex"}
        sx={{ width: props.profilePhoto && "calc(20%)" }}
        justifyContent={"flex-start"}
        id="applicationLogo-main-inner-box"
      >
        {props.profilePhoto ? (
          <img
            src={props.isEdit ? props.profilePhoto : props.profilePhoto.base64}
            alt=""
            style={{
              width: "50px",
              height: "50px",
            }}
          />
        ) : null}
      </Box>

      <Box
        component="label"
        sx={{
          border: "1px solid #D9D9D9",
          borderRadius: "6px",
          padding: {
            sm: "7px 40px",
            md: "7px 40px",
            xl: "12px 40px",
            lg: "7px 40px",
            xs: "7px 40px",
          },
          width: props.profilePhoto ? "calc(80% - 10px)" : "100%",
          marginLeft: props.profilePhoto && "10px",
        }}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        id="applicationLogo-componet-box-label"
      >
        <Box
          paddingRight={"10px"}
          id="applicationLogo-componet-inner-box-label"
        >
          {/* <Uploa
            sx={{ color: "grey" }}
            id="applicationLogo-componet-inner-box-icon"
          /> */}
        </Box>
        {props.profilePhoto ? (
          <Typography
            sx={{ whiteSpace: "nowrap" }}
            id="applicationLogo-componet-inner-box-updateLogo"
          >
            {t("DPAL0004")}
          </Typography>
        ) : (
          <Typography
            sx={{ whiteSpace: "nowrap" }}
            id="applicationLogo-componet-inner-box-uploadLogo"
          >
            {t("DPAL0005")}
          </Typography>
        )}
        <Box
          variant="contained"
          style={{
            position: "relative",
            font: "normal normal normal 18px/24px Roboto",
            letterSpacing: "0px",
            display: "none",
          }}
          disabled={props.disabled}
          id="applicationLogo-box-input-upload-immage"
        >
          <FileBase64
            multiple={false}
            onDone={(file) => handleProfile(file)}
            id="applicationLogo-base64"
          />
        </Box>
      </Box>
    </Box>
  );
}

ApplicationLogo.defaultProps = {
  disabled: false,
};
