export const LOGIN_DEVELOPER = "/api/v1.0/login"
export const CREATE_DEVELOPER = "/api/v1.0/register"
export const URL_UPDATE_DEVELOPER = "/api/v1.0/developers/"
export const URL_DELETE_DEVELOPER = "/api/v1.0/developers/"
export const URL_LIST_DEVELOPER = "/api/v1.0/developers/"
export const URL_GET_DEVELOPER = "/api/v1.0/developers/"
export const URL_GET_DEVELOPERS = "/api/v1.0/developers/"
export const URL_UPDATE_PASSWORD = "/api/v1.0/developers/change_password"
export const URL_EMAIL_VALIDATE = "/api/v1.0/developers/reset_password"
export const URL_VALIDATE_CODE = "/api/v1.0/developers/validate-link"
export const URL_VALIDATE_ACCOUNT = "/api/v1.0/developers/activate_account"	
	
	
	