import React, { Component } from 'react';
import { connect } from "react-redux";
import {validateAccount } from "../../actions/securityActions";
import "../../App.css";
import "../Application/Application.css";
import "../DeveloperManagement/DeveloperMgmt.css";


class AccountVerification extends Component {
	//set state


	constructor(props) {
    super(props);
    this.state = {
      password:"",
      confirmPassword:"",
      newPassword:"",
      success:false,
      errors: {},
	  show:"none",
	  edit:false,
	  readonly:true,
	  tempPasswordTemp:"",
      tempPasswordNew:"",
	};

  }

	componentDidMount() {
		//const { id } = this.props.match.params;
		const value = this.props.location.search
		var paramsArr = [];
		paramsArr = value.split('&temp');
		var code = paramsArr[0].substring(6);
		if(code != null) {
			this.props.validateAccount(code, this.props.history);
		}
	}

	render() {
		return (
			<div></div>
		)
	}
	
}



const mapStateToProps = state => ({
	security: state.security,
  	errors: state.errors
});

export default connect(
	mapStateToProps,
	{ validateAccount }
)(AccountVerification);