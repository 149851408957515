import React, { Component } from 'react';
import { ButtonGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { login } from '../../actions/securityActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { REACT_PATH_SER_EDIT } from '../../actions/ReactEndPoints';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios';

class ServiceListDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ServiceArr: this.props.internalServiceArr,
      isLoading: true,
      open: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async remove(id) {
    this.setState({ show: 'none', success: true });
    await Axios.delete(`/api/v1.0/rest/service/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(() => {
      window.location.reload(false);
    });
  }

  confirm = (id) => {
    const { t } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const confirmStyle = {
          textAlign: 'center',
          width: '500px',
          padding: '40px',
          background: '#28bae6',
          boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
          color: '#fff',
        };
        return (
          <div style={confirmStyle}>
            <h1>{t('areYouSure')}</h1>
            <h6>{t('youWantToDeleteThisService')}</h6>
            <ButtonGroup>
              <Button onClick={onClose}>{t('no')}</Button>
              <Button
                color='danger'
                onClick={() => {
                  this.remove(id);
                  onClose();
                }}
              >
                {t('deleteIt')}
              </Button>
            </ButtonGroup>
          </div>
        );
      },
    });
  };

  render() {
    const ServiceArrDet = this.props.ServiceArr;

    const { t } = this.props;

    const classes = {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'visible',
        margin: '0% 15%',
      },
      gridList: {
        width: '100',
        height: '100',
      },
    };

    function Alert(props) {
      return <MuiAlert elevation={6} variant='filled' {...props} />;
    }

    const appList = ServiceArrDet.map((service, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index} style={{ marginButtom: 15, paddingButtom: 10 }}>
        <Card
          className='root'
          style={{
            boxShadow: '0px 5px 5px #00000066',
            borderRadius: '5px',
            width: '100%',

            height: '400px',
            background: 'transparent linear-gradient(180deg, #0984DB 0%, #004372 100%) 0% 0% no-repeat padding-box',
            opacity: 1,
            marginBottom: '20px',
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8} sm={8}>
                <div>
                  <label>
                    <small style={{ textTransform: 'uppercase', color: '#FFFFFFB3' }}> {t('serviceName')}</small>
                    <br />
                    <small style={{ textTransform: 'uppercase', color: 'White' }}>{service.serviceName}</small>
                  </label>
                </div>
              </Grid>

              <Grid item xs={4} sm={4}>
                <div style={{ float: 'right' }}>
                  {this.props.user.email === 'developer@zkteco.in' && <DeleteIcon fontSize='small' style={{ fill: '#FFFFFF' }} onClick={() => this.confirm(service.id)} />}
                  <Link
                    style={{
                      textAlign: 'left',
                      textDecoration: 'underline',
                      font: 'normal normal small 12px/5px Lato',
                      letterSpacing: '0px',
                      color: '#FFFFFF',
                      opacity: '1',
                    }}
                    to={{
                      pathname: REACT_PATH_SER_EDIT + service.id,
                      serId: service.id,
                    }}
                  >
                    <EditOutlinedIcon fontSize='small' style={{ fill: '#FFFFFF', display: this.props.security.user.email === 'developer@zkteco.in' ? '' : 'none' }} />
                  </Link>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  color: 'aquamarine',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '11rem',
                  display: service.serviceUrl === '' || service.serviceUrl === null ? 'none' : '',
                }}
              >
                <a
                  title={service.serviceUrl}
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    opacity: '1',
                    fontSize: 'small',
                    wordBreak: 'normal',
                    textDecoration: 'underline',
                    color: 'aquamarine',
                  }}
                >
                  <CopyToClipboard text={service.serviceUrl} onCopy={() => this.handleClick()}>
                    <span>{service.serviceUrl}</span>
                  </CopyToClipboard>
                </a>
                {/* <div>
									  {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
									</div> */}
              </Grid>

              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    opacity: '1',
                    textTransform: 'none',
                  }}
                >
                  <label>
                    <small style={{ textTransform: 'uppercase', color: '#FFFFFFB3' }}> {t('version')}</small>
                    <br />
                    <small style={{ textTransform: 'capitalize', color: 'White' }}>{service.version}</small>
                  </label>
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    color: '#FFFFFF',
                    opacity: '1',
                  }}
                >
                  <label>
                    <small style={{ textTransform: 'uppercase', color: '#FFFFFFB3' }}> {t('serviceType')}</small>
                    <br />
                    <small style={{ textTransform: 'capitalize', color: 'White' }}>{service.serviceType}</small>
                  </label>
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    opacity: '1',
                    textTransform: 'none',
                  }}
                >
                  <label>
                    <small style={{ textTransform: 'uppercase', color: '#FFFFFFB3' }}> {service.isDeprecated === true ? 'Deprecated' : 'Active'}</small>
                  </label>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} style={{ display: service.tutorial === '' || service.tutorial === null ? 'none' : '' }}>
                <a
                  href={service.tutorial}
                  target='_blank'
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    opacity: '1',
                    fontSize: 'small',
                    textDecoration: 'underline',
                    color: 'aquamarine',
                  }}
                >
                  {t('tutorial')}
                </a>
              </Grid>

              <Grid item xs={12} sm={12} style={{ display: service.apiReference === '' || service.apiReference === null ? 'none' : '' }}>
                <a
                  href={service.apiReference}
                  target='_blank'
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    opacity: '1',
                    fontSize: 'small',
                    textDecoration: 'underline',
                    color: 'aquamarine',
                  }}
                >
                  {t('apiReference')}
                </a>
              </Grid>

              <Grid item xs={12} sm={12} style={{ display: service.apiPlayground === '' || service.apiPlayground === null ? 'none' : '' }}>
                <a
                  href={service.apiPlayground}
                  target='_blank'
                  style={{
                    textAlign: 'left',
                    font: 'normal normal small 12px/5px Lato',
                    letterSpacing: '0px',
                    opacity: '1',
                    fontSize: 'small',
                    textDecoration: 'underline',
                    color: 'aquamarine',
                  }}
                >
                  {t('apiPlayGround')}
                </a>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    ));
    /*  const classes = useStyles(); */
    return (
      <div className='root'>
        <Grid>
          <Grid container>
            <Grid item xs={12} style={{ border: '2px', boxShadow: 'none !important' }}>
              <div style={classes.root}>
                <Grid container spacing={3} justify='flex-start'>
                  <Snackbar anchorOrigin={{ vertical: 'center', horizontal: 'center' }} open={this.state.open} autoHideDuration={4000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity='success'>
                      Link Copied To clipboard!
                    </Alert>
                  </Snackbar>
                  {appList}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ security: state.security });

export default compose(withTranslation(), connect(mapStateToProps, { login }))(ServiceListDetails);
