import React, { Component } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import { getService, updateService } from "../../actions/ServiceAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import './ServiceStyle.css';
//import { REACT_URL_DASHBOARD,REACT_PATH_SERVICE_LIST } from "../../actions/ReactEndPoints";
import TimeLine from "../Layout/Breadcrumb";
import DevPortalLabel from "../Layout/DevPortalLabel";
import "./AddServiceStyle.css";

import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { default as i18n } from "i18next";
import { getServiceList } from "../../actions/ServiceAction";
class ServiceEditDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      serviceName: "",
      serviceType: "",
      version: "",
      serviceUrl: "",
      tutorial: "",
      apiReference: "",
      apiPlayground: "",
      errors: {},
      error: false,
      hasError: false,
      success: false,
      show: "none",
      status: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }
  componentDidMount() {
    this.props.getService(this.state.id, this.props.history);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      if (JSON.stringify(nextProps.errors) === "{}") {
        this.setState({ serviceName: {} });
      } else {
      }

      this.setState({ errors: nextProps.errors });
    }

    const {
      id,
      serviceName,
      serviceType,
      version,
      serviceUrl,
      tutorial,
      apiReference,
      apiPlayground,
      success,
      show,
      status,
    } = nextProps.service;
    this.setState({
      id,
      serviceName,
      serviceType,
      version,
      serviceUrl,
      tutorial,
      apiReference,
      apiPlayground,
      success,
      show,
      status,
    });
  }
  onSubmit(e) {
    e.preventDefault();

    const newService = {
      serviceName: this.state.serviceName,
      serviceType: this.state.serviceType,
      version: this.state.version,
      serviceUrl: this.state.serviceUrl,
      tutorial: this.state.tutorial,
      apiReference: this.state.apiReference,
      apiPlayground: this.state.apiPlayground,
      status: this.state.status,
    };

    this.props.updateService(this.state.id, newService, this.props.history);
    this.setState({ errors: {} });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  clearErrors() {
    this.setState({
      errors: {},
    });
  }

  render() {
    const { t } = this.props;

    const { role } = this.props.security.user;

    return (
      <div className="projects addServiceStyle">
        <DevPortalLabel></DevPortalLabel>
        <TimeLine
          path={t("updateService") + this.state.serviceName}
          page={"/dev/services/" + role}
        />
        <Grid container justify="center">
          <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
            <div className="addServiceBlock">
              <Box display="flex" justifyContent="center" alignItems="center">
                <div component="div">
                  <Box
                    justifyContent="center"
                    textAlign="center"
                    m={1}
                    color="white"
                    font="normal normal 600  Lato"
                    letterSpacing="0.8px"
                    fontSize="small"
                    opacity="1"
                  >
                    {t("updateService")}
                  </Box>
                </div>
              </Box>
              <form onSubmit={this.onSubmit} autoComplete="off">
                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.serviceName ? true : false}
                    helperText={this.state.errors.serviceName || ""}
                    name="serviceName"
                    //id="serviceName"
                    value={this.state.serviceName || ""}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("serviceName")}
                    inputProps={{
                      pattern: "^d*[a-zA-Z][a-zA-Z0-9 ]*$",
                      title: i18n.t("allowsAlphaNumeric"),
                    }}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.props.errors.serviceUrl ? true : false}
                    helperText={this.props.errors.serviceUrl || ""}
                    name="serviceUrl"
                    //id="applicationName"
                    value={this.state.serviceUrl || ""}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("serviceUrl")}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.version ? true : false}
                    helperText={this.state.errors.version || ""}
                    name="version"
                    //id="version"
                    value={this.state.version || ""}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("version")}
                    inputProps={{
                      pattern: "^(?:0|[0-9][0-9]*)(?:.(0|[0-9][0-9]*))*$",
                      title: i18n.t("versionAllowsNumsAndDots"),
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      {t("serviceType")}
                    </InputLabel>
                    <Select
                      error={!this.state.errors.serviceType ? false : true}
                      //helperText={this.state.errors.serviceType}
                      name="serviceType"
                      labelId="demo-controlled-open-select-label"
                      id="serviceType"
                      open={this.state.open}
                      onClose={this.handleClose}
                      onOpen={this.handleOpen}
                      value={
                        this.state.serviceType ? this.state.serviceType : ""
                      }
                      onChange={this.onChange}
                    >
                      <MenuItem value={this.state.serviceType || ""}>
                        <em>{t("none")}</em>
                      </MenuItem>
                      <MenuItem value="PUBLIC">{t("public")}</MenuItem>
                      <MenuItem value="INTERNAL">{t("internal")}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {this.state.errors.serviceType || ""}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display="flex" justifyContent="center">
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      {t("status")}
                    </InputLabel>
                    <Select
                      error={!this.state.errors.status ? false : true}
                      //helperText={this.state.errors.serviceType}
                      name="status"
                      labelId="demo-controlled-open-select-label"
                      id="status"
                      open={this.state.open}
                      onClose={this.handleClose}
                      onOpen={this.handleOpen}
                      value={this.state.status ? this.state.status : ""}
                      onChange={this.onChange}
                    >
                      <MenuItem value={this.state.status || ""}>
                        <em>{t("none")}</em>
                      </MenuItem>
                      <MenuItem value="CREATED">{t("created")}</MenuItem>
                      <MenuItem value="RELEASED">{t("released")}</MenuItem>
                      <MenuItem value="ABSOLUTE">{t("absolute")}</MenuItem>
                    </Select>
                    <FormHelperText>
                      {this.state.errors.status || ""}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.apiReference ? true : false}
                    helperText={this.state.errors.apiReference || ""}
                    name="apiReference"
                    //id="apiReference"
                    value={this.state.apiReference || ""}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("apiReference")}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.apiPlayground ? true : false}
                    helperText={this.state.errors.apiPlayground || ""}
                    name="apiPlayground"
                    //id="apiPlayground"
                    value={this.state.apiPlayground || ""}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("apiPlayGround")}
                  />
                </Box>

                <Box display="flex" justifyContent="center">
                  <TextField
                    error={this.state.errors.tutorial ? true : false}
                    helperText={this.state.errors.tutorial || ""}
                    name="tutorial"
                    //id="tutorial"
                    value={this.state.tutorial || ""}
                    onChange={this.onChange}
                    placeholder=""
                    id="custom-css-standard-input"
                    label={t("tutorial")}
                  />
                </Box>

                <Box display="flex" justifyContent="center" p={3}>
                  <Box justifyContent="center" p={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={this.clearErrors}
                      className="submitBtn"
                    >
                      {t("update")}
                    </Button>
                  </Box>
                  <Box justifyContent="center" p={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="go-forward"
                      component={Link}
                      to="/dev/services/INTERNAL_DEVELOPER"
                    >
                      {t("cancel")}
                    </Button>
                  </Box>
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
ServiceEditDetails.propTypes = {
  getService: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  service: state.services.service,
  errors: state.errors,
  security: state.security,
});
//export default  AddServices;
export default compose(
  withTranslation(),
  connect(mapStateToProps, { getService, getServiceList, updateService })
)(ServiceEditDetails);
