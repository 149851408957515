import React, { Component } from "react";
class Dashboard extends Component {

  render() {

    return (
      <div className="projects">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4 text-center">My Dashboard</h1>
              <br />

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;