import React, { Component } from 'react';
import { ButtonGroup } from 'reactstrap';
import { connect } from "react-redux";
import {compose} from 'redux';
import { login } from "../../actions/securityActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
class ServiceListDetail extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			ServiceArr: this.props.internalServiceArr,
			isLoading: true
		};

    }
    confirm = (id) => {
		const { t } = useTranslation();
		confirmAlert({
			customUI: ({ onClose }) => {
				const confirmStyle = {
					textAlign: "center",
					width: "500px",
					padding: "40px",
					background: "#28bae6",
					boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
					color: "#fff"
				}
				return (
					<div style={confirmStyle}>
			<h1>{t("areYouSure")}</h1>
				<p>{t("youWantToDeleteThisApplication")}</p>
						<ButtonGroup>
				<Button onClick={onClose}>{t("no")}</Button>
							<Button color="danger"
								onClick={() => {
									this.remove(id);
									onClose();
								}}
							>
								{t("deleteIt")}
              </Button>
						</ButtonGroup>

					</div>
				);
			}
		});
    };
    
	render() {
		 
		const ServiceArrDet = this.props.ServiceArr;
		 
	 
		
		const classes = {
			root: {
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'space-around',
				overflow: 'hidden',
				margin: '0% 15%'
			},
			gridList: {
				width: "100",
				height: "100",
			}
        }
        
        const appList = ServiceArrDet.map(service => (
            <Grid item xs={12} sm={6} md={4}  lg={3}  xl={2}>
        
					<Card
					className="root"
					style={{
						boxShadow: "0px 5px 5px #00000066",
						borderRadius: "5px",
						width: "100%",
						height: "350px",
						maxHeight: "350px",
						background:
						"transparent linear-gradient(180deg, #0984DB 0%, #004372 100%) 0% 0% no-repeat padding-box",
						opacity: 1
					}}
					>
                <CardContent>
			            <Grid container spacing={0.98}>

                         
                        <Grid item xs={8} sm={8}>
						<div>
						<Link className="appName" style={{
										textAlign: "left",
										font: "normal normal small 12px/5px Lato",
										letterSpacing: "0px",
										opacity: "1",
										textTransform:"none"
									}}
						to={{
							pathname: "#"
						}}>
						{service.serviceName} 
						</Link>
					</div>
                   </Grid>
			</Grid>
			</CardContent>
			</Card>
			</Grid>
		























		));

		return (

			<div className="root">
				<Grid>

					<Grid container >

						<Grid item xs={12} style={{ border: "2px", boxShadow: "none !important" }}>
							<div style={classes.root}>
								<Grid
									container
									spacing={3} justify="flex-start">
									{appList}
								</Grid>
							</div>

						</Grid>

					</Grid>
				</Grid>
			</div>
		);

    }
}
const mapStateToProps = state => ({
	security: state.security
  });
export default compose(withTranslation(),connect(mapStateToProps, { login }))(ServiceListDetail);