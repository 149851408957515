
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import './UnSecureHeaderLinksStyle.css';
import LanguageIcon from '@material-ui/icons/Language';

import { Button, Menu, MenuItem } from '@material-ui/core';

import { useTranslation } from "react-i18next";
import BrowserLanguage from "../Util/BrowserLanguage";
import {
    IconFlagUS,
    IconFlagES
} from 'material-ui-flags';
import IconButton from "@material-ui/core/IconButton";



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginBottom: '10px',
    '& > span': {
      maxWidth: 15,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props) => <Tabs {...props}  />);

 


const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    border: 'none',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(0.5),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#0099FF',
  },
}));

export default function HeaderLinks(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const align = props.align;
  const [value, setValue] = React.useState(0);
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const [search, setSearch] = React.useState(null);
 // const user = props.user;
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const handleOnclickLanguages = (event) => {
      
        setAnchorLanguage(event.currentTarget);
      };

      const handleCloseLangMenu = (event) => {
        
    let newLang = event;
    if (newLang === "") {
      const newLang = BrowserLanguage.getDefaultLanguage();
      //newLang = tempLang === 'en' ? 'fr' : 'en';
    }
    
    if (BrowserLanguage.setLanguage(newLang)) {
      
      i18n.changeLanguage(newLang);
    }

    setAnchorLanguage(null);
    setSearch(newLang);
  };

  const handleClose = () => {
	    setAnchorLanguage(null);
	  };

  return (
    <div className={classes.root}>

      <div className={classes.demo2}>
        <StyledTabs className="customUnsubscribeHeader" value={value} onChange={handleChange} aria-label="styled tabs example" orientation={align} style={{display: "flex", paddingRight: "5px",}}>	
        <StyledTab style={{display:"none"}} />
        <StyledTab label={t("platform")}
          className="nav-link"          
            to={{
              pathname: "../dev/platform",
            }} component={Link}>
        </StyledTab>
{/*  <a className="custom-nav-link" href =
			  "https://checkbucketsstorage.s3.ap-south-1.amazonaws.com/Minarva-iot-platform/index.html"
			  target = "_blank" > <span className="MuiTab-wrapper">{t("platform")}</span>
			  </a>  */}
          <StyledTab label={t("application")}
          className="nav-link"
          
            to={{
              pathname: "../dev/application-tab",
            }} component={Link}>
          </StyledTab>
          {/* <StyledTab label="API"
            className="nav-link"
            to={{
              pathname: "../dev/login",
            }} component={Link}>
          </StyledTab> */}
          <StyledTab label={t("onlineMarket")}
            className="nav-link"
            to={{
              pathname: "../dev/online-market",
            }} component={Link}>
          </StyledTab>        
          <StyledTab label={t("forum")}
            className="nav-link"
            		target="_blank" href="http://192.168.11.13:8083/user/login.page"
             >
          </StyledTab>
          <Button className="headerLoginBtn" variant="outlined" component={Link}  to={{pathname:"../dev/login"}} >
              {t("signIn")}
                </Button> 
          {/* <Button className="headerLoginBtn" variant="outlined" component={Link}  to={{pathname:"../dev/signUp"}}>
            {t("signUp")}
              </Button> */}
              
             <IconButton className="language-icon" id="fasdfasd"  aria-label="Language" color="inherit"  onClick={handleOnclickLanguages}  >
              <LanguageIcon  />
            </IconButton>
            
            <Menu
              id="simple-menu"              
              anchorEl={anchorLanguage}
              keepMounted
              open={Boolean(anchorLanguage)}
              onClose={handleClose}
              style={{zIndex:"999999" ,fontSize:"2px"}}            >
              <MenuItem onClick={() => {handleCloseLangMenu("en")}}><IconFlagUS />{t("english")}</MenuItem>
              <MenuItem onClick={() => {handleCloseLangMenu("es")}}><IconFlagES />{t("spanish")}</MenuItem>
            </Menu>        
        </StyledTabs>
        <Typography className={classes.padding} />
      </div>
    </div>
  );
}
