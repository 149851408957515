

export const REACT_URL_APP_LIST = "/dev/apps/"
export const REACT_URL_APP_HOME = "/dev/apps/home/:id&:appType&:appRole"
export const REACT_URL_APP_ADD = "/dev/apps/add/"
export const REACT_URL_APP_EDIT = "/dev/apps/update/:id"
export const REACT_URL_SER_EDIT = "/dev/service/update/:id"
export const REACT_URL_FEATURE_LIST = "/dev/apps/feature/:id"
export const REACT_URL_FEATURE_EDIT = "/dev/apps/feature/update/:id"
export const REACT_URL_FEATURE_ADD = "/dev/apps/feature/:id"
export const REACT_URL_USERROLE_ADD = "/dev/apps/userRole/:id"
export const REACT_URL_USERROLE_EDIT = "/dev/apps/userRole/update/:id"
export const REACT_URL_MEMBER_ADD = "/dev/apps/member/:id"
export const REACT_URL_DASHBOARD = "/dev/console"
export const REACT_URL_SERVICE_LIST = "/dev/services/:id"
export const REACT_URL_SERVICE_API = "/dev/serviceDetail"
export const REACT_URL_DEVELOPER_EDIT = "/dev/profile/:id"
export const REACT_URL_CHANGE_PASSWORD = "/dev/profile/password/:id"

export const REACT_PATH_LOGIN = "/dev/login/"
export const REACT_PATH_APP_LIST = "/dev/apps/"
export const REACT_PATH_APP_HOME = "/dev/apps/home/"
export const REACT_PATH_APP_ADD = "/dev/apps/add/"
export const REACT_PATH_APP_EDIT = "/dev/apps/update/"
export const REACT_PATH_FEATURE_LIST = "/dev/apps/feature/"
export const REACT_PATH_FEATURE_EDIT = "/dev/apps/feature/update/"
export const REACT_PATH_FEATURE_ADD = "/dev/apps/feature/"
export const REACT_PATH_USERROLE_ADD = "/dev/apps/userRole/"
export const REACT_PATH_USERROLE_EDIT = "/dev/apps/userRole/update/"
export const REACT_PATH_MEMBER_ADD = "/dev/apps/member/"
export const REACT_PATH_DASHBOARD = "/dev/console"
export const REACT_PATH_SERVICE_LIST = "/dev/services/"
export const REACT_PATH_ADD_SERVICE = "/dev/create/"
export const REACT_PATH_SER_EDIT = "/dev/service/update/"
export const REACT_PATH_SERVICE_API = "/dev/serviceDetail"
export const REACT_PATH_SERVICE = "/dev/serviceDts"
export const REACT_PATH_DEVELOPER_EDIT = "/dev/profile/"
export const REACT_PATH_CHANGE_PASSWORD = "/dev/profile/password/"
export const REACT_PATH_RESET_PASSWORD = "/dev/profile/resetPassword/"
export const REACT_PATH_TEST = "/dev/allUsers/user"