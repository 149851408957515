import React,{Component}from'react';
import PropTypes from'prop-types';import{connect}from"react-redux";import{getDeveloper,updateDeveloper}from"../../actions/DeveloperActions";import{Grid,Card,CardContent,FormControl,InputLabel,Input,Button}from'@material-ui/core';import{Avatar}from"@material-ui/core";
import Snackbar from'@material-ui/core/Snackbar';
import MuiAlert from'@material-ui/lab/Alert';
import IconButton from'@material-ui/core/IconButton';
import EditIcon from'@material-ui/icons/Edit';import"../DeveloperManagement/DeveloperMgmt.css";
import axios from'axios';import {URL_UPDATE_DEVELOPER} from'../../actions/EndPoints';import {REACT_PATH_DEVELOPER_EDIT} from'../../actions/ReactEndPoints';

import{withTranslation}from'react-i18next';import{compose}from'redux';

class ProfilePage extends Component {
	// set state

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			developerId: "",
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			secondaryEmail: "",
			state: "",
			city: "",
			country: "",
			zipCode: "",
			role: "",
			isActive: "",
			password: "",
			confirmPassword: "",
			newPassword: "",
			oldPassword: "",
			address: "",
			profileUrl: "",
			success: false,
			errors: {},
			show: "none",
			edit: false,
			readonly: true,
			tempFirstName: '',
			tempLastName: '',
			tempEmail: '',
			tempPhone: '',
			tempAddress: '',
			tempCity: '',
			tempState: '',
			tempCountry: '',
			tempPasswordTemp: "",
			tempPasswordNew: "",
			tempPasswordOld: "",
		};
		this.resetState = this.resetState.bind(this);
		this.onCloseAlert = this.onCloseAlert.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.clearFirstName=this.clearFirstName(this);
	}

	onCloseAlert() {
		this.setState({ success: false, edit: false, readonly: true, errors: {} });
	}

	handleEdit() {
		 
		this.setState({
			
			edit: !this.state.edit,
			readonly: false,
			tempFirstName: this.state.firstName,
			tempLastName: this.state.lastName,
			tempEmail: this.state.email,
			tempPhone: this.state.phone,
			tempAddress: this.state.address,
			tempCity: this.state.city,
			tempState: this.state.state,
			tempCountry: this.state.country,
			tempPasswordNew: this.state.newPassword,
			tempPasswordOld: this.state.oldPassword,
			tempPasswordTemp: this.state.confirmPassword
		})
	}

	resetState() {
		 
		this.setState({
			
			edit: !this.state.edit,
			readonly: true,
			errors: {},
			firstName: this.state.tempFirstName,
			lastName: this.state.tempLastName,
			email: this.state.tempEmail,
			phone: this.state.tempPhone,
			address: this.state.tempAddress,
			city: this.state.tempCity,
			 state : this.state.tempState,
			 country : this.state.tempCountry,
			 newPassword : this.state.tempPasswordNew,
			 oldPassword : this.state.tempPasswordOld,
			confirmPassword : this.state.tempPasswordTemp
			
			
		})
	}

	async componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });

			const {
				id,
				firstName,
				lastName,
				email,
				phone,
				secondaryEmail,
				state,
				city,
				country,
				zipCode,
				role,
				isActive,
				address,
				ciry,
				profileUrl,
				success,
				show,
			} = nextProps.developer;
			this.setState({
				id,
				firstName,
				lastName,
				email,
				phone,
				state,
				city,
				country,
				zipCode,
				role,
				isActive,
				secondaryEmail,
				address,
				ciry,
				profileUrl,
				success,
				show
			});
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		this.state.developerId = id;
		this.state.success = false;
		this.props.getDeveloper(this.state.developerId, this.props.history);
		
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		const newDeveloper = {
			id: this.state.id,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			phone: this.state.phone,
			secondaryEmail: this.state.secondaryEmail,
			state: this.state.state,
			city: this.state.city,
			country: this.state.country,
			zipCode: this.state.zipCode,
			role: this.state.role,
			address: this.state.address,
			profileUrl: this.state.profileUrl,
			newPassword: this.state.newPassword,
			oldPassword: this.state.oldPassword,
			confirmPassword: this.state.confirmPassword,
			success: false,
			show: this.state.show
		};
		//this.state.success = false;
		this.setState({success: false});
		
		if (this.state.edit) {
			//this.props.updateDeveloper(newDeveloper, this.props.history);
			axios.put(URL_UPDATE_DEVELOPER, newDeveloper)
				.then((response) => {
					this.setState({
						success: true
					});
				});
			this.props.updateDeveloper(newDeveloper, this.props.history);
			this.props.history.replace(REACT_PATH_DEVELOPER_EDIT + newDeveloper.id)

		}
	}

	clearFirstName(){
		this.setState({
			errors:''
			
		})
	}
	render() {
		const { errors } = this.state;
		const { t } = this.props;

	function Alert(props) {
			return <MuiAlert elevation={6} variant="filled" {...props} />;
		}
		const avatar = {
			height: "90px !important",
			width: "90px !important",
			backgroundColor: "rgba(0, 143, 165, 1)",
			marginTop: "4%",
			border: "2px solid",
		};

		return (
			<div className="devPortalLabel profileSection profilePageStyle">
				<Grid container justify="center"  >

					<Grid item xs={12} sm={2}>
					</Grid>

					<Grid item xs={10} sm={8} style={{ height: "100%" }}>

						<div >
							<Grid container style={{ paddingTop: "7%" }}>
								<Grid item xs={12} sm={8} style={{ borderBottom: "1px solid #0099FF", boxShadow: "none !important", textAlign: "left", flexBasis: "100%", maxWidth: "100%", marginBottom: "20px" }}>
									<label style={{ font: "Montserrat,medium ", fontSize: "20px", marginRight: "5px", color: "#003E67" }}>
										{t("myProfile")}
                        			</label>
									<IconButton style={{ float: "right", display: this.state.edit ? 'none' : '' }} onClick={this.handleEdit} >
										<EditIcon />
									</IconButton>
								</Grid>
							</Grid>



						</div>
						<Card className="profileBlock profilePageBlock">
							<CardContent title="" className="profileHeader" >
								<Avatar size={100} style={avatar} className="test" >{this.state.firstName?this.state.firstName[0]:'none'}</Avatar>

							</CardContent>
							
							<form onSubmit={this.onSubmit} className="profilePageForm">
								<CardContent className="profileBody">

									<Grid container>
										<Grid item xs={12} sm={2}></Grid>
										<Grid item xs={12} sm={5}>
											
											<FormControl className="form-control customText">
												<InputLabel className="input-label">{t("firstName")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="text" name="firstName" 
													required 
													className="input-field"
													 
													/* inputProps={{ pattern: '[a-zA-Z ]{2,30}', title: "Characters only of length 2 to 30" }} */
													autoComplete="off"
													value={this.state.firstName || ""}
													onChange={this.onChange}
													onClick={this.clearFirstName}
													error={this.state.errors.firstName ? true : false}
												/>
												{this.state.errors.firstName && (
													<div className="invalid-feedback">{this.state.errors.firstName}</div>
												)}
											</FormControl>
											
											
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("lastName")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="text" 
													name="lastName" 
													className="input-field"
													 
													/* inputProps={{ pattern: '[a-zA-Z ]{2,30}', title: "Characters only of length 2 to 30" }} */
													autoComplete="off"
													value={this.state.lastName || ""}
													onChange={this.onChange}
													error={this.state.errors.lastName ? true : false}
												/>
												{this.state.errors.lastName && (
													<div className="invalid-feedback">{this.state.errors.lastName}</div>
												)}
											</FormControl>
											
											
										</Grid>

										<Grid item xs={12} sm={5}>
											
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("email")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="email" 
													name="email" 
													required 
													className="input-field"
													 
													autoComplete="off"
													value={this.state.email || ""}
													onChange={this.onChange}
													error={this.state.errors.email ? true : false}
												/>
												{this.state.errors.email && (
													<div className="invalid-feedback">{this.state.errors.email}</div>
												)}
											</FormControl>
											
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("phone")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="text" 
													name="phone" 
													className="input-field"
													 
													autoComplete="off"
													value={this.state.phone || ""}
													onChange={this.onChange}
													error={this.state.errors.phone ? true : false}
													inputProps={{ pattern: '[0-9]{8,15}', title: "Numbers only of length 8 to 15" }}
												/>
												{this.state.errors.phone && (
													<div className="invalid-feedback">{this.state.errors.phone}</div>
												)}
											</FormControl>
											
											
										</Grid>
										<Grid item xs={12} sm={2}>
										</Grid>
										<Grid item xs={12} sm={5}>
											
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("address")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="text" 
													name="address" 
													className="input-field"
													 
													autoComplete="off"
													value={this.state.address || ""}
													onChange={this.onChange}
													error={this.state.errors.address ? true : false}
												/>
												{this.state.errors.address && (
													<div className="invalid-feedback">{this.state.errors.address}</div>
												)}
											</FormControl>
											
											
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("city")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="text" 
													name="city" 
													className="input-field"
													 
													inputProps={{ pattern: '[a-zA-Z ]{1,30}', title: "Alphabet only" }}
													autoComplete="off"
													value={this.state.city || ""}
													onChange={this.onChange}
													error={this.state.errors.city ? true : false}
												/>
												{this.state.errors.city && (
													<div className="invalid-feedback">{this.state.errors.city}</div>
												)}
											</FormControl>
											
											
										</Grid>
										<Grid item xs={12} sm={5}>
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("state")}</InputLabel>
												<Input 
													readOnly={this.state.readonly} 
													type="text" 
													name="state" 
													className="input-field"
													 
													autoComplete="off"
													value={this.state.state || ""}
													onChange={this.onChange}
													error={this.state.errors.state ? true : false}
													inputProps={{ pattern: '[a-zA-Z ]{1,30}', title: "Alphabet only" }}
												/>
												{this.state.errors.state && (
													<div className="invalid-feedback">{this.state.errors.state}</div>
												)}
											</FormControl>
										 
											
											<FormControl className="form-control">
												<InputLabel className="input-label">{t("country")}</InputLabel>
												<Input 
													inputProps={{ pattern: "[a-z]" }} 
													readOnly={this.state.readonly} 
													type="text" 
													name="country" 
													className="input-field"
													 
													autoComplete="off"
													value={this.state.country || ""}
													onChange={this.onChange}
													error={this.state.errors.country ? true : false}
													inputProps={{ pattern: '[a-zA-Z ]{1,30}', title: "Alphabet only" }}

												/>

												{this.state.errors.country && (
													<div className="invalid-feedback">{this.state.errors.country}</div>
												)}
											</FormControl>
											
											
										</Grid>



										<Grid container justify="center" style={{ display: !this.state.edit ? "none" : "" }} >
											<Grid item xs={6} className="two-button-grid" container
												direction="row"
												justify="flex-end"
												alignItems="flex-start" >
												<Button variant="contained" color="primary" type="submit" className="update-button">
													{t("update")}
     					 						</Button>
											</Grid>
											<Grid item xs={6} className="two-button-grid"
												container
												direction="row"
												justify="flex-start"
												alignItems="flex-start">
												<Button variant="contained" color="secondary" className="addApplicationBtn" onClick={this.resetState}>{t("cancel")}</Button>
											</Grid>
										</Grid>
									</Grid>
								</CardContent>


							</form>
						</Card>
					</Grid>
					<Grid item xs={12} sm={2} >
						{(this.state.success && (Object.keys(errors).length === 0)) && (
							<Snackbar anchorOrigin={{ vertical: "center", horizontal: "center" }} open={this.state.success} autoHideDuration={2000} onClose={this.onCloseAlert}  >

								<Alert onClose={this.onCloseAlert} severity="success" >
									{t("profileUpdateStatus")}
								</Alert>
							</Snackbar>
						)}
					</Grid>

				</Grid >

			</div >
		);
	}
}

// export default ProfilePage;
ProfilePage.propTypes={getDeveloper:PropTypes.func.isRequired,updateDeveloper:PropTypes.func.isRequired,errors:PropTypes.object.isRequired};

const mapStateToProps=state=>({developer:state.developer.developer,errors:state.errors});

/*
 * export default connect( mapStateToProps, { getDeveloper, updateDeveloper }
 * )(ProfilePage);
 */

export default compose(withTranslation(),connect(mapStateToProps,{getDeveloper,updateDeveloper}))(ProfilePage);