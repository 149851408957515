import React, { Component } from 'react';
import './Application.css';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid'

import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addMember, getMembers } from "../../actions/MemberActions";
import './Application.css';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './MemberDataTableStyle.css';
import { withTranslation } from 'react-i18next';
import {compose} from 'redux';

class MemberDataTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			email: "",
			developerId: this.props.developerId,
			applicationId: this.props.appId,
			members: [],
			isLoading: true,
			appRole: this.props.appRole,
			appType: this.props.appType,
			data: [],
			iserror: false,
			errorMessages: [],
			suggestions: [],
			errors: {},
			open: false
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.getSuggestions = this.getSuggestions.bind(this);
		this.getSelectedItem = this.getSelectedItem.bind(this);
		this.clearErrors = this.clearErrors.bind(this);
		this.handleRowDelete = this.handleRowDelete.bind(this);
		this.handleRowAdd = this.handleRowAdd.bind(this);
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		
		this.setState({ open: false });
		this.setState({ 
			errors: {}, 
			suggestions: [] 
					
		});
		
		//this.state.suggestions = [];
	};

	getSuggestions(email) {
		if (email.length === 3) {
			axios.get("/api/v1.0/developers/email/" + email)
				.then(({ data }) => {
					this.setState({
						suggestions: data.data
					});
				});
		}
		
		
		
	}

	clearErrors() {
		this.setState({ errors: {} })
	}

	onChange(e) {
		this.setState({ error: {} })
		this.setState({ [e.target.name]: e.target.value });
		this.getSuggestions(e.target.value);
	}

	onSubmit(e) {
		e.preventDefault();
		
		axios.post("/api/v1.0/applications/add/" + this.state.applicationId+"/developers", {
			email: this.state.email
		}).then((response) => {
				this.setState({
					open: false,
					suggestions : []
				});
			}, (error) => {
				this.setState({ errors: error.response.data.data })
			});

		axios.get("/api/v1.0/applications/"+ this.state.applicationId+"/developers")
			.then(({ data }) => {
				this.setState({
					members: data.data, isLoading: false
				});
			});
	}

	handleRowAdd = (newData, resolve) => {

		//validation
		const { t } = this.props;
		let errorList = []
		if (newData.email === "" || newData.email === undefined) {
			errorList.push(t("enterEmail") )
		}

		if (errorList.length < 1) {
			const newDeveloper = {
				email: this.state.email,

			};
			this.props.addMember(this.state.applicationId, newDeveloper, this.props.history, this.state.appType, this.state.appRole);

			setTimeout(() => {
				axios.get("/api/v1.0/applications/" + this.state.applicationId+"/developers")
					.then(({ data }) => {
						this.setState({
							members: data.data, isLoading: false
						});
					});
				resolve();
			}, 2000)

		} else {
			this.setState({ errorMessages: errorList })
			this.setState({ iserror: true })
			resolve()
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	componentDidMount() {
		this.props.getMembers(this.state.applicationId);
		this.setState({ isLoading: true });
		axios.get("/api/v1.0/applications/" + this.state.applicationId+"/developers")
			.then(({ data }) => {
				this.setState({
					members: data.data, isLoading: false
				});
			});
	}

	async handleRowDelete(id, resolve) {
		 
		await axios.delete("/api/v1.0/applications/remove/"+this.state.applicationId+"/developers/"+id , {
			             
		method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(() => {
			let updated = [...this.state.members].filter(i => i.id !== id);
			this.setState({ members: updated });
		});
		resolve();
	}

	getSelectedItem(val) {
		this.state.email = val;
	}

	render() {
		const { t } = this.props;
		 
		
		const columns = [
			{ title: t("id"), field: "id", hidden: true },
			/* {title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.featureName} />  }, */
			{
				title: t("developerName"), field: "firstName",
				headerStyle: {
					color: 'black',
					backgroundColor: 'transparent',
				}
			},
			{
				title:  t("email"), field: "email",
				headerStyle: {
					color: 'black',
					backgroundColor: 'transparent',
				}
			},
			{
				title: t("role"), field: "role",
				headerStyle: {
					color: 'black',
					backgroundColor: 'transparent',
				}
			}
		];

		const tableIcons = {
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'black' }}/>),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }}/>),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }}/>),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }}/>),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }}/>),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }}/>),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }}/>),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }}/>),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }}/>),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }}/>),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }}/>),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }}/>)
		};
		const { errors } = this.state;

		return (
			<div className="App memberDataTableStyle">
				<Grid container>
					<Grid item xs={2}>

					</Grid>
					<Grid item xs={12} sm={8}>
						{this.state.appRole === 'ADMIN' && (
							<Button className="memberAddButtonStyle" variant="contained" onClick={this.handleClickOpen} color="primary">
								{t("addMember")}
							</Button>
						)}
						<form noValidate autoComplete="off" onSubmit={this.onSubmit}>
							<Dialog className="memberDataTableAddDialog" open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth>
								<DialogTitle id="form-dialog-title">{t("addMember")}</DialogTitle>
								<DialogContent>
									<Autocomplete
										id="free-solo-demo"
										freeSolo
										options={this.state.suggestions}
										getOptionLabel={option => option.firstName + " " + option.lastName}
										onChange={(event, newValue) => {
                                            if(newValue!=null)
                                            this.getSelectedItem(newValue.email);
                                          }}
										  
										renderInput={(params) => (
											<TextField {...params} label={t("email")} margin="normal" variant="outlined"  
												name="email"
												key="Confirmation Code"
												fullWidth
												id="email"
												placeholder={t("email")}
												value={this.state.email}
												onChange={this.onChange}
												onClick={this.clearErrors}
												helperText={errors.email}
												error={errors.email == null ? "" : "error"}
											InputLabelProps={{  style: {textTransform: 'capitalize'} }}
											/>
												
												)}
									/>
								</DialogContent>
								<DialogActions>
									<Button color="primary" onClick={this.onSubmit}>{t("add")}</Button>
									<Button onClick={this.handleClose} color="primary">
										{t("cancel")}
								</Button>
								</DialogActions>
							</Dialog>
						</form>
					</Grid>
					<Grid item xs={2}></Grid>
				</Grid>
				<Grid className="memberDataTableMaterialTable" container>
					<Grid item xs={2}></Grid>
					<Grid item xs={12} sm={8}>
						<MaterialTable
							columns={columns}
							data={this.state.members}
							icons={tableIcons}
							options={{
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								showTitle: false,
								toolbarButtonAlignment: "left",
								toolbar: false,
								pageSize: 5,
								headerStyle: { position: 'sticky', top: 0 },
						          maxBodyHeight: 500,
							}}
						   localization={{
									  body: {
									     deleteTooltip: t("delete"),
									     editRow: {
		                                        deleteText: t("deleteWarning"),
		                                    },
								         },
									
									pagination: {
								        	 labelDisplayedRows:'{from}-{to}' + "  "+t("of")+"  "+'{count}',
		                            labelRowsSelect: t("rows"),
		                            firstAriaLabel: t("firstPage"),
		                            firstTooltip: t("firstPage"),
		                            previousAriaLabel: t("previousPage"),
		                            previousTooltip: t("previousPage"),
		                            nextAriaLabel: t("nextPage"),
		                            nextTooltip: t("nextPage"),
		                            lastAriaLabel: t("lastPage"),
		                            lastTooltip: t("lastPage"),
		                         }
							
						}}
							editable={{
								isDeletable: rowData => rowData.role !== 'ADMIN',
								isDeleteHidden: rowData => this.state.appRole === 'TEAM_MEMBER',
								onRowDelete: (oldData) =>
									new Promise((resolve) => {
										this.handleRowDelete(oldData.id, resolve)
									}),
							}}
						/>
					</Grid>
					<Grid item xs={2}></Grid>
				</Grid>
			</div>
		);
	}
}

MemberDataTable.propTypes = {
	getMembers: PropTypes.func.isRequired,
	addMember: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	devapp: state.devapp.devapp,
	memberList: state.devapp.memberList,
	errors: state.errors,
	security: state.security
});
export default compose(
	withTranslation(),connect(mapStateToProps, {getMembers, addMember }))(MemberDataTable);
