import React from 'react';
//import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { REACT_PATH_APP_LIST, REACT_PATH_DEVELOPER_EDIT, REACT_PATH_SERVICE_LIST, REACT_PATH_CHANGE_PASSWORD } from '../../actions/ReactEndPoints';
import setJWTToken from '../../securityUtils/setJWTToken';
import { Avatar, Button, Menu, MenuItem } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import './HeaderLinksStyle.css';
import { connect } from 'react-redux';

const handleOnclickLogout = () => {
  localStorage.removeItem('jwtToken');
  setJWTToken(false);
  window.location.href = '/dev/login';
};

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginBottom: '10px',
    '& > span': {
      maxWidth: 15,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    border: 'none',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(0.5),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#0099FF',
  },
}));

function HeaderLinks(props) {
  //const { t, i18n } = useTranslation();

  const classes = useStyles();
  const align = props.align;
  const [value, setValue] = React.useState(0);
  const user = props.user;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //
  //const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.demo2}>
        <StyledTabs className='headerLinksStyle' value={value} onChange={handleChange} aria-label='styled tabs example' orientation={align}>
          <StyledTab
            label={t('myApplications')}
            className='nav-link'
            to={{
              pathname: REACT_PATH_APP_LIST,
              devId: user.id,
            }}
            component={Link}
          ></StyledTab>
          <StyledTab
            label={t('services')}
            className='nav-link'
            to={{
              pathname: REACT_PATH_SERVICE_LIST + user.role,
              devId: user.id,
            }}
            component={Link}
          />

          {props.security.user.email === 'developer@zkteco.in' && (
            <StyledTab
              label={t('developerStatus')}
              className='nav-link'
              to={{
                pathname: '/dev/allusers/user',
              }}
              component={Link}
            ></StyledTab>
          )}
          {props.security.user.email === 'developer@zkteco.in' && (
            <StyledTab
              label={t('Dashboard - Cloud access')}
              className='nav-link'
              to={{
                pathname: '/dev/cloud-access',
              }}
              component={Link}
            ></StyledTab>
          )}
          <StyledTab
            className='profileTab'
            label={
              <Avatar size={100} className='profileAvatar'>
                {user.firstName[0] ? user.firstName[0] : ''}
              </Avatar>
            }
            /* to={{
                  pathname: REACT_PATH_DEVELOPER_EDIT+user.id,
                  devId: user.id
                }} component={Link} */ onClick={handleClick}
          />
          <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} style={{ zIndex: '99999' }}>
            <MenuItem
              onClick={handleClose}
              to={{
                pathname: REACT_PATH_DEVELOPER_EDIT + user.id,
                devId: user.id,
              }}
              component={Link}
            >
              {t('profile')}
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              to={{
                pathname: REACT_PATH_CHANGE_PASSWORD + user.id,
                devId: user.id,
              }}
              component={Link}
            >
              {t('changePassword')}
            </MenuItem>
          </Menu>
          <Button className='headerLoginBtn' variant='outlined' onClick={handleOnclickLogout} to={{ pathname: '/dev/login' }}>
            {t('signOut')}
          </Button>
        </StyledTabs>

        <Typography className={classes.padding} />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  serviceList: state.services.serviceList,
  security: state.security,
});
export default connect(mapStateToProps)(HeaderLinks);
