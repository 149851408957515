import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../actions/securityActions";
import { Grid, Card, CardHeader, CardActionArea, CardContent, Button, Typography  } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { withTranslation } from 'react-i18next';
import {compose} from 'redux';
import "./LoginStyle.css";
import { default as i18n } from 'i18next';
import { REACT_PATH_DASHBOARD } from "../../actions/ReactEndPoints";

class Login extends Component {
	state = {
		isPasswordShown: false,
	  };

	  togglePasswordVisiblity = () => {
		const { isPasswordShown } = this.state;
		this.setState({ isPasswordShown: !isPasswordShown });
	  };
	constructor() {
		super();
		this.state = {
			email: "",
			password: "",
			errors: {}
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.clearEmail=this.clearEmail.bind(this);
		this.clearPassword=this.clearPassword.bind(this);
	}
	componentDidMount() {
		if (this.props.security.validToken) {
			this.props.history.push(REACT_PATH_DASHBOARD);
		}
	}

  	componentWillReceiveProps(nextProps) {
    if (nextProps.security.validToken) {
      this.props.history.push(REACT_PATH_DASHBOARD);
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

	onSubmit(e) {
		
		
		e.preventDefault();
		const LoginRequest = {
			email: this.state.email,
			password: this.state.password
		};

		this.props.login(LoginRequest);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	
	clearEmail(){
		this.setState({
			errors:''
			
		})
		
	}
	
   clearPassword(){
	   this.setState({
			errors:''
			
		})
	   
	}
	render() {
		const { isPasswordShown } = this.state;
		//const { errors } = this.state;

		const { t } = this.props;

		 

		const card = {
			borderRight: "solid",
			borderLeft: "solid",
			borderBottom: "solid",
			borderWidth: "1px",
			borderColor: "#0098FF",
			boxShadow: "0px 3px 3px #00000066",
			margin: "10%",
		};

		const cardHeader1 = {
			padding: "0px",
			background: "#AAE5FF",
			font: "normal normal small 15px/18px Lato",
			fontSize:"small",
			letterSpacing: "0px",
			color: "#000000",
			height: "100%",
    		width: "100%",
			textAlign:"center",
			borderRadius:"100%",
			borderTopLeftRadius: 0,
  			borderTopRightRadius: 0,

		};

		const cardContent = {
			
		};

		const margin = {
			float: "inherit",
			width: "99%",
			marginBottom: "25px",
		};

		const button = {
			backgroundColor: "#ECF9FF",
			border: "solid",
			borderColor: "#0098FF5C",
			borderWidth: "1px",
			// width: "30%",
			minWidth:"85px",
		};

		 

		return (
			<div>
				<div >
					 <Grid container>
						<Grid item xs={12} sm={12} md={6} lg={8} xl={9} className="pt-60"  >
							<div className="login-component">
							<Grid container>
								<Grid item lg={1}></Grid>
								<Grid item md={12} sm={12} lg={11}>
								<Card className="login-card">
									<CardContent className="textAlignCenter">
									<Typography className="login-card-header" gutterBottom>
										{t("minervaIot")}
									</Typography>
									<Typography className="login-card-body">
										{t("applicationTitle")}
									</Typography>
									<hr style={{
										color: "#cad0c4", width: "50%"
									}}></hr>
									</CardContent>
								</Card>
								</Grid>
							</Grid>
							</div>

						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} xl={3} className="form-area pt-60">

				
							<Grid container justify ="center">

							<Grid item xs={12}  sm={12}>
							<Card style={card}>
								<CardHeader style={cardHeader1}
								titleTypographyProps={{variant:'subtitle1' }}
									title={t("signIn")}
								/>
								<form  className="loginForm" onSubmit={this.onSubmit}>
									<CardContent style={cardContent}>
										<FormControl style={margin}>
											<Grid container spacing={1}>
												<Grid className="iconGrid" item lg={1} md={1} sm={1} xs={1}>
													<EmailIcon />
												</Grid>
												<Grid item lg={11} md={11} sm={11} xs={11}>
													<TextField 
														p={2} 
														type="email" 
														name="email" 
														id="email" 
														label={t("email")} 
														className="text-field" 
														//placeholder={t("email")} 
														autoComplete="off" 
														onChange={this.onChange} 
														onClick={this.clearEmail} 
														error={this.state.errors.email? true : false} 
														helperText= {this.state.errors.email} 
														value={this.state.email} 
														// InputLabelProps={{ shrink: false }} 
														inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$", title:i18n.t('validEmailAddress') }} 
													/>
												</Grid>
												{/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{this.state.errors.email}</FormHelperText> */}
												{/* <div style={{color:"red", fontSize:"12px", marginLeft:"32px"}}>{this.state.errors.email}</div> */}
											</Grid>
											<br />
											<Grid container spacing={1}>
												<Grid className="iconGrid" item lg={1} md={1} sm={1} xs={1}>
													<LockIcon />
												</Grid>
												<Grid item lg={11} md={11} sm={11} xs={11}>
													<TextField 
														p={2} 
														type={isPasswordShown ? "text" : "password"} 
														name="password" 
														id="password" 
														label={t("password")}
														className="text-field" 
														//placeholder={t("password")} 
														autoComplete="off" 
														onChange={this.onChange} 
														onClick={this.clearPassword} 
														error={this.state.errors.password? true : false} 
														helperText= {this.state.errors.password} 
														value={this.state.password} 
														// InputLabelProps={{ shrink: false }} 
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		tabIndex="-1"
																		className="password-icon"
																		aria-label="toggle password visibility"
																		onClick={this.togglePasswordVisiblity}
																	>
																		{isPasswordShown ? (
																		<Visibility />
																		) : (
																		<VisibilityOff />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}} 
													/>
												</Grid>
												{/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{this.state.errors.password}</FormHelperText> */}
												{/* <div style={{color:"red", fontSize:"12px", marginLeft:"32px"}}>{this.state.errors.password}</div> */}
											</Grid>
										</FormControl>

										<Grid container spacing={1}  >

											<Grid item xs={6} >		
											{/* <div className="keepLoggedIn" >
												Keep me logged in</div>  */}
												
												
											</Grid>
										{/*	{/dev/emailVerification}*/}
											<br/>
											<Grid item xs={6} >
												<div ><a href="/dev/emailVerification"  className="forgotPassword" >{t("forgotPassword")}</a></div>
											</Grid>
										</Grid>
											<br/>
										<Grid container spacing={1}  justify="center">
												
											<Button style={button} variant="contained" type="submit"> {t("signIn")} </Button>

										</Grid>
										<br/>
										<br/>
										<Grid container spacing={1} justify="center">

											{/* <Grid item>
												<div className="signUpAccount" >{t("dontHaveAccount")}  <a href="/dev/signUp">{t("createOne")} </a></div>
											</Grid> */}

										</Grid>

									</CardContent>
								</form>
								<CardActionArea>

								</CardActionArea>
							</Card>
							</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	login: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	security: state.security,
	errors: state.errors
});

/* export default connect(
	mapStateToProps,
	{ login }
)(Login); */


export default compose(
	withTranslation(),connect(mapStateToProps, {login}))(Login);