import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import { LOGIN_DEVELOPER, CREATE_DEVELOPER, URL_EMAIL_VALIDATE,URL_VALIDATE_CODE,URL_VALIDATE_ACCOUNT } from "./EndPoints";
import setJWTToken from "../securityUtils/setJWTToken";
import jwt_decode from "jwt-decode";

 

import { withTranslation } from 'react-i18next';
import { default as i18n } from 'i18next';
const { t } = withTranslation();

 


export const createNewUser = (newUser, history) => async dispatch => {
  try {
   const res = await axios.post(CREATE_DEVELOPER, newUser);
    //history.push("/dev/login");
    history.push({
            pathname: '/dev/SuccessPage',
            state: { action:"REGISTER_USER", message: i18n.t("accountActivation"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage"), data:res.data }
    })
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

 

export const login = LoginRequest => async dispatch => {
  try {
    // post => Login Request
    const res = await axios.post(LOGIN_DEVELOPER, LoginRequest);
    // extract token from res.data
    const token = res.data.data;
    // store the token in the localStorage
    localStorage.setItem("jwtToken", token);
    // set our token in header ***
    setJWTToken(token);
    // decode token on React
    const decoded = jwt_decode(token);
    // dispatch to our securityReducer
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data.data
    });
  }
};

 


export const emailValidate = (email,history) => async dispatch => {
  try {
    const res = await axios.post(URL_EMAIL_VALIDATE+'?email='+email);
    if (res.status === 200) {
        history.push({
            pathname: '/dev/SuccessPage',
            state: { action:"EMAIL_VALIDATE", message: i18n.t("emailForResetPassword"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage"),data:res.data }
        })
    
    }
  } catch (error) {
        dispatch({
        type: GET_ERRORS,
        payload: error.response.data.data
        });
  }
};

 

export const resetPassword = (developer, history) => async dispatch => {
      try {
        
        const res = await axios.put(URL_EMAIL_VALIDATE, developer);
        if (res.status === 200) {
            history.push({
                  pathname: '/dev/SuccessPage',
                  state: { action:"RESET_PASSWORD", message: i18n.t("passwordResetSuccessfully"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage"),data:res.data }
            })
            
        } 
      } catch (error) {
          if (error.status === 404) {
              history.push({
                  pathname: '/dev/FailurePage',
                  state: { action:"RESET_PASSWORD", message: i18n.t("linkExpired"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage") }
              })
          } else if(error.status === 400) {
              dispatch({
                  type: GET_ERRORS,
                  payload: error.response.data.data
                });
          }

		dispatch({
                  type: GET_ERRORS,
                  payload: error.response.data.data
                });
    
      }
};

 


export const validateCode = (code, history) => async dispatch => {
      try {
        const res = await axios.post(URL_VALIDATE_CODE,{code:code});
        if(res.status === 200) {
            history.replace("/dev/reset-password?code="+code);
        }
      } catch (error) {
          history.push({
              pathname: '/dev/FailurePage',
              state: { action:"VALIDATE_CODE", message: i18n.t("linkExpired"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage") }
          })
          
      }
};

 

            export const validateAccount = (code, history) => async dispatch => {
                  try {
            
                    const res = await axios.put(URL_VALIDATE_ACCOUNT,{code:code});
                    
                    if(res.status === 200) {
                        history.push({
                              pathname: '/dev/SuccessPage',
                              state: { action:"ACCOUNT_ACTIVATE", message: i18n.t("accountActivatedSuccessfully"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage"),data:res.data }
                          })
                    }
                   
                    if(res.data.ret === "0")
                     {
                        history.push({
                              pathname: '/dev/SuccessPage',
                              state: { action:"REACCOUNT_ACTIVATE", message:i18n.t("clickBelowToResendAccountActivationLink") , link:"/dev/login", linkLabel: i18n.t("resendActivationLink")  ,data:res.data }
                          })
                     }
                  } catch (error) {
                      history.push({
                        pathname: '/dev/FailurePage',
                        state: { action:"ACCOUNT_ACTIVATE", message: i18n.t("linkExpired"), link:"/dev/login", linkLabel: i18n.t("gotoHomePage") }
                    })
                      
                  }
            };

 

export const logout = () => dispatch => {
  localStorage.removeItem("jwtToken");
  setJWTToken(false);
  dispatch({
    type: SET_CURRENT_USER,
    payload: {}
  });
};