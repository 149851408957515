import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label, Card, CardTitle, Row, CardHeader, FormFeedback, Alert, Col } from 'reactstrap';
import {  getDeveloper, updateDeveloper } from "../../actions/DeveloperActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import IntervalTimer from 'react-interval-timer';

import { withTranslation } from 'react-i18next';
import {compose} from 'redux';

class DeveloperEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id:"",
      developerId:"",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      secondaryEmail: "",
      state: "",
      city: "",
      country: "",
      zipCode: "",
      role:"",
      isActive:"",
      password:"",
      passwordTemp:"",
      passwordNew:"",
      passwordOld:"",
      address:"",
      profileUrl:"",
      success:false,
      errors: {},
      show:"none"
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			const {
        id,
        firstName,
        lastName,
        email,
        phone,
        secondaryEmail,
        state,
        city,
        country,
        zipCode,
        role,
        isActive,
        address,
        ciry,
        profileUrl,
        success,
        show
			} = nextProps.developer;
			this.setState({
        id,
        firstName,
        lastName,
        email,
        phone,
        state,
        city,
        country,
        zipCode,
        role,
        isActive,
        secondaryEmail,
        address,
        ciry,
        profileUrl,
        success,
        show
			});
		} else {

    }

	}


  componentDidMount() {
    const { id } = this.props.match.params;
    this.state.developerId = id;
    this.props.getDeveloper(this.state.developerId, this.props.history);
	}
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const newDeveloper = {
      id:this.state.id,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      email:this.state.email,
      phone:this.state.phone,
      secondaryEmail:this.state.secondaryEmail,
      state:this.state.state,
      city:this.state.city,
      country:this.state.country,
      zipCode:this.state.zipCode,
      role:this.state.role,
      address:this.state.address,
      profileUrl:this.state.profileUrl,
      newPassword:this.state.passwordNew,
      oldPassword:this.state.passwordOld,
      confirmPassword:this.state.passwordTemp,
      success:this.state.success,
      show:this.state.show
    };
    this.props.updateDeveloper(newDeveloper, this.props.history);
  }

  render() {
    const { errors } = this.state;

    const { t } = this.props;

    return <div>
      <Container>
        <Row  style={{marginLeft:"0px",marginRight:"0px",marginBottom:"10px"}}>
          <Col xs="4">
            <Card body inverse color="info">
              <CardTitle>
                <h3>{this.state.firstName +" "+ this.state.lastName}</h3>
                <small>{this.state.isActive === true ? 'Active' : 'In Active'}</small>
              </CardTitle>
              <CardTitle>{this.state.role}</CardTitle>
              {/* <Button color="warning" >De-register</Button> */}
            </Card>
          </Col>
          <Col xs="4">
          </Col>
          {this.state.success && (
            <Col xs="4">
              <Alert color="success" style={{display:this.state.show}}>
                {t("profileUpdateStatus")}
              </Alert>
              <IntervalTimer
                    timeout={5000}
                    callback={()=>this.setState({ show:"none",success:false })}
                    enabled={true}
                    repeat={true}
                />
            </Col>
            
          )}
          
          
        </Row>
        <Card body outline color="secondary">
          <Form onSubmit={this.onSubmit}> 
          <input type="hidden" name="role" id="role"/>
          <CardHeader outline>{t("profileDetails")}</CardHeader>
           <div className="row">
            <FormGroup className="col-md-4 mb-3">
              <Label for="firstName">{t("firstName")}</Label>
              <Input type="text" name="firstName" id="firstName" value={this.state.firstName || ''}
                    onChange={this.onChange} autoComplete="firstName"
                    className={classnames("form-control", {
                      "is-invalid": errors.firstName
                    })}/>
              {errors.firstName && (
                     <FormFeedback>{errors.firstName}</FormFeedback>
                  )}
            </FormGroup>
            <FormGroup className="col-md-4 mb-3">
              <Label for="lastName">{t("lastName")}</Label>
              <Input type="text" name="lastName" id="lastName" value={this.state.lastName || ''}
                    onChange={this.onChange} autoComplete="lastName"
                    className={classnames("form-control", {
                      "is-invalid": errors.lastName
                    })}/>
            </FormGroup>
            </div>
            <div className="row">
            <FormGroup className="col-md-4 mb-3">
              <Label for="email">{t("email")}</Label>
              <Input type="text" name="email" id="email" value={this.state.email || ''}
                    onChange={this.onChange} autoComplete="email"  
                    className={classnames("form-control", {
                      "is-invalid": errors.email
                    })}/>
              {errors.email && (
                     <FormFeedback>{errors.email}</FormFeedback>
                  )}
              
            </FormGroup>
            <FormGroup className="col-md-4 mb-3">
              <Label for="secondaryEmail">{t("secondaryEmail")}</Label>
              <Input type="text" name="secondaryEmail" id="email" value={this.state.secondaryEmail || ''}
                    onChange={this.onChange} autoComplete="secondaryEmail"  
                    className={classnames("form-control", {
                      "is-invalid": errors.secondaryEmail
                    })}/>
              {errors.secondaryEmail && (
                     <FormFeedback>{errors.secondaryEmail}</FormFeedback>
                  )}
              
            </FormGroup>
            <FormGroup className="col-md-4 mb-3">
              <Label for="phone">{t("phone")}</Label>
              <Input type="text" name="phone" id="phone" value={this.state.phone || ''}
                    onChange={this.onChange} autoComplete="phone"
                    className={classnames("form-control", {
                      "is-invalid": errors.phone
                    })}/>
              {errors.phone && (
                  <FormFeedback>{errors.phone}</FormFeedback>
              )}
            </FormGroup>
            
           </div>
          
          <CardHeader outline>{t("addrsDetails")}</CardHeader>
          <div className="row">
            <FormGroup className="col-md-12 mb-3">
              <Label for="state">Address</Label>
              <Input type="text" name="address" id="address" value={this.state.address || ''}
                     onChange={this.onChange} autoComplete="address"
                     className={classnames("form-control", {
                      "is-invalid": errors.city
                    })}/>
              {errors.address && (
                     <FormFeedback>{errors.address}</FormFeedback>
                  )}
            </FormGroup>
            <FormGroup className="col-md-3 mb-3">
              <Label for="state">{t("city")}</Label>
              <Input type="text" name="city" id="city" value={this.state.city || ''}
                     onChange={this.onChange} autoComplete="city"
                     className={classnames("form-control", {
                      "is-invalid": errors.city
                    })}/>
              {errors.city && (
                     <FormFeedback>{errors.city}</FormFeedback>
                  )}
            </FormGroup>
            <FormGroup className="col-md-3 mb-3">
              <Label for="state">{t("stateOrProvience")}</Label>
              <Input type="text" name="state" id="state" value={this.state.state || ''}
                     onChange={this.onChange} autoComplete="state"
                     className={classnames("form-control", {
                      "is-invalid": errors.secondaryEmail
                    })}/>
              {errors.secondaryEmail && (
                     <FormFeedback>{errors.secondaryEmail}</FormFeedback>
                  )}
            </FormGroup>
            <FormGroup className="col-md-3 mb-3">
              <Label for="country">{t("country")}</Label>
              <Input type="text" name="country" id="country" value={this.state.country || ''}
                     onChange={this.onChange} autoComplete="country"/>
            </FormGroup>
            <FormGroup className="col-md-3 mb-3">
              <Label for="zipCode">{t("postalCode")}</Label>
              <Input type="text" name="zipCode" id="zipCode" value={this.state.zipCode || ''}
                     onChange={this.onChange} autoComplete="zipCode"/>
            </FormGroup>
          </div>
          <CardHeader outline>{t("changePassword")}</CardHeader>
          <div className="row">
            <FormGroup className="col-md-4 mb-3">
              <Label for="state">{t("changePassword")}</Label>
              <Input type="password" name="passwordOld" id="password" value={this.state.passwordOld || ''}
                     onChange={this.onChange} autoComplete="passwordOld"
                     className={classnames("form-control", {
                      "is-invalid": errors.password
                    })}/>
              {errors.password && (
                     <FormFeedback>{errors.password}</FormFeedback>
                  )}
            </FormGroup>
            <FormGroup className="col-md-4 mb-3">
              <Label for="country">{t("newPassword")}</Label>
              <Input type="password" name="passwordNew" id="passwordNew" value={this.state.passwordNew || ''}
                     onChange={this.onChange} autoComplete="passwordNew" 
                     className={classnames("form-control", {
                      "is-invalid": errors.newPassword
                    })}/>
              {errors.newPassword && (
                     <FormFeedback>{errors.newPassword}</FormFeedback>
                  )}
            </FormGroup>
            <FormGroup className="col-md-4 mb-3">
              <Label for="zipCode">{t("confirmPasswod")}</Label>
              <Input type="password" name="passwordTemp" id="zipCode" value={this.state.passwordTemp || ''}
                     onChange={this.onChange} autoComplete="passwordTemp"
                     className={classnames("form-control", {
                      "is-invalid": errors.confirmPassword
                    })}/>
              {errors.confirmPassword && (
                     <FormFeedback>{errors.confirmPassword}</FormFeedback>
                  )}
            </FormGroup>
          </div>

          <FormGroup>
            <Button color="primary" type="submit">{t("save")}</Button>{' '}
            <Button color="secondary" tag={Link} to="/developers">{t("back")}</Button>
          </FormGroup>
        </Form>
        </Card>
        
      </Container>
    </div>
  }
}

DeveloperEdit.propTypes = {
  getDeveloper: PropTypes.func.isRequired,
  updateDeveloper: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  developer:state.developer.developer,
  errors: state.errors
});

/* export default connect(
  mapStateToProps,
    { getDeveloper, updateDeveloper }
)(DeveloperEdit); */


export default compose(
	withTranslation(),connect(mapStateToProps, {getDeveloper, updateDeveloper}))(DeveloperEdit);