//import React, { Component } from "react";
import "./App.css";
import Dashboard from "./components/Dashboard";
import Applications from "./components/Application/Applications";
import Header from "./components/Layout/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Register from "./components/DeveloperManagement/Register";
import Login from "./components/DeveloperManagement/Login";
import SuccessPage from "./components/Layout/SuccessPage";
import FailurePage from "./components/Layout/FailurePage";
import AccountVerification from "./components/Layout/AccountVerification";
import Applicationtab from "./components/Layout/Applicationtab";

import jwt_decode from "jwt-decode";
import setJWTToken from "./securityUtils/setJWTToken";
import { SET_CURRENT_USER } from "./actions/types";
import { logout } from "./actions/securityActions";
import SecuredRoute from "./securityUtils/SecureRoute";
import AddApplication from "./components/Application/AddApplication";

import DeveloperEdit from "./components/DeveloperManagement/DeveloperEdit";

import ServiceList from "./components/Services/ServiceList";
import { createBrowserHistory } from "history";
import "./actions/ReactEndPoints";
import {
  REACT_PATH_TEST,
  REACT_URL_SER_EDIT,
  REACT_URL_DASHBOARD,
  REACT_URL_APP_LIST,
  REACT_URL_DEVELOPER_EDIT,
  REACT_URL_APP_HOME,
  REACT_URL_APP_ADD,
  REACT_URL_APP_EDIT,
  REACT_URL_SERVICE_LIST,
  REACT_URL_CHANGE_PASSWORD,
  REACT_PATH_ADD_SERVICE,
} from "./actions/ReactEndPoints";

import Footer from "./components/Layout/Footer";
import Home from "./components/Layout/Home";
import AppHome from "./components/Application/ApplicationHome";

import ProfilePage from "./components/DeveloperManagement/ProfilePage";
import ClientCredentials from "./components/Application/ClientCredentials";
import ChangePassword from "./components/DeveloperManagement/ChangePassword";
import ResetPassword from "./components/DeveloperManagement/ResetPassword";
import EmailVerification from "./components/Layout/EmailVerification";
import ComingSoon from "./components/Layout/ComingSoon";

import ApplicationDetails from "./components/Application/ApplicationDetails";
import { addMember } from "./actions/MemberActions";
import ApplicationHome from "./components/Application/ApplicationHome";
import MinervaIotPlatform from "./components/Layout/MinervaIotPlatform";
import AddServices from "./components/Services/AddServices";
//

import React, { Component, Suspense } from "react";
import { withTranslation, Translation } from "react-i18next";
import BrowserLanguage from "./components/Util/BrowserLanguage";
import i18n from "i18next";
import axios from "axios";
import ServiceListDetail from "./components/Services/ServiceListDetail";
import ServiceEditDetails from "./components/Services/ServiceEditDetails";
import AllUsers from "./components/DeveloperManagement/AllUsers";
import DashboardPage from "./components/DeveloperManagement/DashboardPage";
import CloudAccessDashboard from "./components/DeveloperManagement/CloudAccessDashboard";

const jwtToken = localStorage.jwtToken;

if (jwtToken) {
  setJWTToken(jwtToken);
  const decoded_jwtToken = jwt_decode(jwtToken);
  store.dispatch({
    type: SET_CURRENT_USER,
    payload: decoded_jwtToken,
  });

  const currentTime = Date.now() / 1000;
  if (decoded_jwtToken.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = "/";
  }
}

axios.interceptors.request.use(
  (config) => {
    const language = BrowserLanguage.getDefaultLanguage();
    config.headers.common["accept-language"] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class App extends Component {
  //

  componentDidMount() {
    let language = BrowserLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    i18n.changeLanguage(language);
  }

  render() {
    const history = createBrowserHistory();
    var devPortalLabel = "none";

    if (jwtToken) {
      devPortalLabel = "";
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App" style={{ marginBottom: "50px" }}>
            <Header />

            <Footer />
            {
              //Public Routes<Route exact path={REACT_PATH_TEST} component={AllUsers} />
            }

            <Route exact path="/" component={Home} />
            {/* <Route exact path='/dev/signUp' component={Register} /> */}
            <Route exact path="/dev/login" component={Login} />

            <Route exact path="/dev/footer" component={Footer} />
            <Route exact path="/dev/custom/appHome" component={AppHome} />
            <Route exact path="/dev/online-market" component={ComingSoon} />
            <Route
              exact
              path="/dev/application-tab"
              component={Applicationtab}
            />
            <Route exact path="/dev/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/dev/emailVerification"
              component={EmailVerification}
            />
            <Route exact path="/dev/SuccessPage" component={SuccessPage} />
            <Route exact path="/dev/FailurePage" component={FailurePage} />
            <Route
              exact
              path="/dev/activate-account"
              component={AccountVerification}
            />
            <Route exact path="/dev/platform" component={MinervaIotPlatform} />

            {
              //<Route exact path={REACT_PATH_TEST} component={DashboardPage} />
              //Private Routes
            }
            <Switch>
              <SecuredRoute
                exact
                path={REACT_URL_DASHBOARD}
                component={Applications}
              />
              <SecuredRoute
                exact
                path={REACT_URL_APP_LIST}
                component={Applications}
              />
              <SecuredRoute
                exact
                path={REACT_URL_DEVELOPER_EDIT}
                component={ProfilePage}
              />
              <SecuredRoute
                exact
                path={REACT_URL_APP_ADD}
                component={AddApplication}
              />
              <SecuredRoute
                exact
                path={REACT_URL_APP_HOME}
                component={AppHome}
              />
              <SecuredRoute
                exact
                path={REACT_URL_APP_EDIT}
                component={ApplicationDetails}
              />
              <SecuredRoute
                exact
                path={REACT_URL_SER_EDIT}
                component={ServiceEditDetails}
              />
              <SecuredRoute
                exact
                path={REACT_URL_SERVICE_LIST}
                component={ServiceList}
              />
              <SecuredRoute
                exact
                path={REACT_PATH_ADD_SERVICE}
                component={AddServices}
              />
              <SecuredRoute
                exact
                path={REACT_URL_CHANGE_PASSWORD}
                component={ChangePassword}
              />
              <SecuredRoute
                exact
                path={REACT_PATH_TEST}
                component={DashboardPage}
              />
              <SecuredRoute
                exact
                path={"/dev/cloud-access"}
                component={CloudAccessDashboard}
              />

              <SecuredRoute exact path="/dashboard" component={Dashboard} />
              <SecuredRoute exact path="/dev/appps/" component={Applications} />
              <SecuredRoute
                exact
                path="/profile/:id"
                component={DeveloperEdit}
              />
              <SecuredRoute
                exact
                path="/app/add/:id"
                component={AddApplication}
              />
              <SecuredRoute
                exact
                path="/application/home2/:id&:appType&:appRole"
                component={ApplicationHome}
              />
              <SecuredRoute
                exact
                path="/application/update/:id"
                component={ApplicationDetails}
              />
              <SecuredRoute
                exact
                path="/application/member/:id"
                component={addMember}
              />
              <SecuredRoute
                exact
                path="/services/:id"
                component={ServiceList}
              />

              <SecuredRoute
                exact
                path="/dev/ApplicationDetail/:clientId&:clientSecrate"
                component={ClientCredentials}
              />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
