import axios from "axios";
import { GET_SERVICES,GET_SERVICE } from "./types";
import { GET_ERRORS   } from "./types";


export const getServiceList = (history) => async dispatch => {
	const res = await axios.get("/api/v1.0/rest/service/services");
	  dispatch({
	    type: GET_SERVICES,
	    payload: res.data.data
	  });
};

export const getService = (id, history) => async dispatch => {
	try {
	  const res = await axios.get(`/api/v1.0/rest/service/${id}`);
	  if(res.status===200)
	  {
		dispatch({
			type: GET_ERRORS,
			payload: {}
			
		  }); 
	  }
	  dispatch({
		type: GET_SERVICE,
		payload: res.data.data
	  });
	} catch (error) {
	  history.push("/");
	}
  };

export const createService = (service, history) => async dispatch => {
	try {
		
	  const res = await axios.post("/api/v1.0/rest/service/addservice", service);
	  
	  
	  if(res.status===200)
	  {
		dispatch({
			type: GET_ERRORS,
			payload: {}
			
		  }); 
	  }
		dispatch({
		type: GET_ERRORS,
		payload: {}
		
	  });
	  history.push("services/INTERNAL_DEVELOPER");
	} catch (err) {
		
	  dispatch({
		type: GET_ERRORS,
		payload: err.response.data.data
	  });
	  
	}
  };

  export const updateService = (serId, service,history) => async dispatch => {
	try {
	 
		const res =await axios.put("/api/v1.0/rest/service/" + serId, service);
	 
	  if(res.status===200)
	  {
		dispatch({
			type: GET_ERRORS,
			payload: {}
			
		  }); 
	  }
	  dispatch({
		type: GET_ERRORS,
		payload: {}
	  });
	  history.push("/dev/services/INTERNAL_DEVELOPER");
	} catch (err) {
	  dispatch({
		type: GET_ERRORS,
		payload: err.response.data.data
	  });
	}
  };

	