import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updateApplication,
  getApplication,
  regenerate,
} from "../../actions/ApplicationAction";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import { Avatar, Button } from "@material-ui/core";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DoneIcon from "@material-ui/icons/Done";
import IntervalTimer from "react-interval-timer";
import Alert from "@material-ui/lab/Alert";
import "./ApplicationDetailsStyle.css";
import NoImage from "../../images/NoImage.jpg";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { default as i18n } from "i18next";
import ApplicationLogo from "./ApplicationLogo";

class ApplicationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedUser: this.props.security.user,
      applicationId: this.props.appId,
      id: "",
      applicationName: "",
      applicationLogo: "",
      applicationUrl: "",
      applicationType: "",
      applicationPlatform: "",
      version: "",
      apiSpecificationLink: "",
      clientId: "",
      clientSecrate: "",
      callbackUrl: "",
      appDeletionWebhook: "",
      userDeletionWebhook: "",
      errors: {},
      success: false,
      show: "none",
      appRole: this.props.appRole,
      edit: false,
      display: true,
      editButton: "visible",
      displayButton: "hidden",
      tempApplicationName: "",
      tempApplicationType: "",
      tempApplicationPlatform: "",
      tempCallbackUrl: "",
      tempDeletionWebhook: "",
      tempUserDeletionWebhook: "",
      tempVersion: "",
      tempApiSpecificationLink: "",
      status: "",
      image: "",
    };
    this.edit = this.edit.bind(this);
    this.display = this.display.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.regenerateClietCredentials =
      this.regenerateClietCredentials.bind(this);
  }
  handleImage(file) {
    this.setState({ applicationLogo: file.base64 });
    this.setState({ image: file });
  }

  edit() {
    this.setState({
      edit: true,
      display: false,
      editButton: "hidden",
      displayButton: "visible",
      tempApplicationName: this.state.applicationName,
      tempApplicationType: this.state.applicationType,
      tempApplicationPlatform: this.state.applicationPlatform,
      tempCallbackUrl: this.state.callbackUrl,
      tempDeletionWebhook: this.state.appDeletionWebhook,
      tempUserDeletionWebhook: this.state.userDeletionWebhook,
      tempVersion: this.state.version,
      tempApiSpecificationLink: this.state.apiSpecificationLink,
      status: this.state.status,
      image: this.state.image,
    });
  }

  display() {
    this.setState({
      edit: false,
      display: true,
      displayButton: "hidden",
      editButton: "visible",
      applicationName: this.state.tempApplicationName,
      applicationLogo: this.state.tempApplicationLogo,
      applicationUrl: this.state.tempApplicationUrl,
      applicationType: this.state.tempApplicationType,
      applicationPlatform: this.state.tempApplicationPlatform,
      callbackUrl: this.state.tempCallbackUrl,
      appDeletionWebhook: this.state.tempDeletionWebhook,
      userDeletionWebhook: this.state.tempUserDeletionWebhook,
      version: this.state.tempVersion,
      apiSpecificationLink: this.state.tempApiSpecificationLink,
      status: this.state.status,
      image: this.state.applicationLogo,
      errors: {},
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      if (JSON.stringify(nextProps.errors) === "{}") {
        this.setState({
          edit: false,
          display: true,
          displayButton: "hidden",
          editButton: "visible",
        });
      }

      this.setState({ errors: nextProps.errors });
      const {
        id,
        applicationId,
        applicationName,
        applicationLogo,
        applicationUrl,
        applicationType,
        applicationPlatform,
        clientId,
        clientSecrate,
        callbackUrl,
        appDeletionWebhook,
        userDeletionWebhook,
        version,
        apiSpecificationLink,
        status,
        success,
        show,
      } = nextProps.devapp;
      this.setState({
        id,
        applicationId,
        applicationName,
        applicationType,
        applicationLogo,
        applicationUrl,
        applicationPlatform,
        clientId,
        clientSecrate,
        callbackUrl,
        appDeletionWebhook,
        userDeletionWebhook,
        version,
        apiSpecificationLink,
        status,
        success,
        show,
      });
    }
  }

  componentDidMount() {
    this.props.getApplication(this.state.applicationId, this.props.history);
  }

  regenerateClietCredentials() {
    this.props.regenerate(this.props.appId);
  }

  onSubmit(e) {
    e.preventDefault();

    const newApplication = {
      id: this.state.id,
      applicationId: this.state.applicationId,
      applicationName: this.state.applicationName,
      applicationLogo: this.state.applicationLogo,
      applicationUrl: this.state.applicationUrl,
      applicationType: this.state.applicationType,
      applicationPlatform: this.state.applicationPlatform,
      clientId: this.state.clientId,
      clientSecrate: this.state.clientSecrate,
      callbackUrl: this.state.callbackUrl,
      appDeletionWebhook: this.state.appDeletionWebhook,
      userDeletionWebhook: this.state.userDeletionWebhook,
      version: this.state.version,
      apiSpecificationLink: this.state.apiSpecificationLink,
      status: this.state.status,
      success: this.state.success,
      show: this.state.show,
    };
    this.props.updateApplication(
      this.state.loggedUser.id,
      newApplication,
      this.props.history
    );
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="applicationDetailsStyle">
        <form autoComplete="off" onSubmit={this.onSubmit}>
          <div>
            <Box
              display="flex"
              p={1}
              bgcolor="rgb(193, 193, 193, 0.5)"
              color="black"
              boxShadow="0px 0px 1px #FFFFFFC4"
              justifyContent="flex-end"
            >
              <Box flexGrow={1}>{t("generalDtls")}</Box>
              <Box>
                {this.state.success && (
                  <Box xs="4">
                    <Alert
                      color="success"
                      style={{
                        display: this.state.errors ? "none" : this.state.show,
                      }}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      {t("appUpdateStatus")}
                    </Alert>
                    <IntervalTimer
                      timeout={2000}
                      callback={() =>
                        this.setState({ show: "none", success: false })
                      }
                      enabled={true}
                      repeat={true}
                    />
                  </Box>
                )}
              </Box>
              <Box visibility={this.state.displayButton}>
                <Tooltip title={t("saveChanges")}>
                  <IconButton
                    className="saveChanges"
                    aria-label="Save changes"
                    type="submit"
                  >
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box visibility={this.state.displayButton}>
                <Tooltip title={t("cancelChanges")}>
                  <IconButton
                    className="cancelChanges"
                    aria-label="Edit"
                    onClick={this.display}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box visibility={this.state.editButton}>
                <Tooltip title={t("edit")}>
                  <IconButton
                    className="edit"
                    aria-label="Edit"
                    onClick={this.edit}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {this.state.display && (
              <div>
                <img
                  src={
                    this.state.applicationLogo
                      ? this.state.applicationLogo
                      : NoImage
                  }
                  alt=""
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </div>
            )}
            {this.state.edit && (
              <Box display="flex" justifyContent="center" width="100%">
                <ApplicationLogo
                  isEdit={true}
                  id={"application-logo-image"}
                  onsetProfile={(image) => this.handleImage(image)}
                  profilePhoto={
                    this.state.applicationLogo
                      ? this.state.applicationLogo
                      : NoImage
                  }
                />
              </Box>
            )}
          </div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flex-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <div>
                      <label>
                        <small className="applicationDetailsTitle">
                          {t("appName")}
                        </small>
                        <br />
                        <small className="applicationDetailsInfo">
                          {this.state.applicationName}
                        </small>
                      </label>
                    </div>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <TextField
                        error={this.state.errors.applicationName ? true : false}
                        helperText={this.state.errors.applicationName}
                        name="applicationName"
                        // id="applicationName"
                        value={this.state.applicationName}
                        onChange={this.onChange}
                        id="custom-css-standard-input"
                        label={t("appName")}
                        style={{
                          width: "100%",
                          color: "white",
                          marginBottom: 10,
                        }}
                        inputProps={{
                          pattern: "^d*[a-zA-Z][a-zA-Z0-9 ]*$",
                          title: i18n.t("allowsAlphaNumeric"),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("version")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfo">
                        {this.state.version}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <TextField
                        name="version"
                        value={this.state.version}
                        error={this.state.errors.version ? true : false}
                        helperText={this.state.errors.version}
                        inputProps={{
                          pattern: "^(?:0|[0-9][0-9]*)(?:.(0|[0-9][0-9]*))*$",
                          title: i18n.t("versionAllowsNumsAndDots"),
                        }}
                        onChange={this.onChange}
                        id="custom-css-standard-input"
                        label={t("version")}
                        style={{
                          width: "100%",
                          color: "white",
                          marginBottom: 10,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("appType")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfo">
                        {this.state.applicationType}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel
                          id="demo-controlled-open-select-label"
                          style={{ fontSize: "small" }}
                        >
                          {t("appType")}
                        </InputLabel>
                        <Select
                          name="applicationType"
                          error={
                            !this.state.errors.applicationPlatform
                              ? false
                              : true
                          }
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={this.state.open}
                          onClose={this.handleClose}
                          onOpen={this.handleOpen}
                          value={this.state.applicationType}
                          onChange={this.onChange}
                        >
                          <MenuItem value="">
                            <em>{t("none")}</em>
                          </MenuItem>
                          <MenuItem value="Personal">{t("personal")}</MenuItem>
                          <MenuItem value="Team Application">
                            {t("teamApp")}
                          </MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#f44336" }}>
                          {this.state.errors.applicationType}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("appPlatform")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfo">
                        {this.state.applicationPlatform}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <FormControl>
                        <InputLabel
                          id="demo-controlled-open-select-label"
                          style={{ fontSize: "small" }}
                        >
                          {t("appPlatform")}
                        </InputLabel>
                        <Select
                          error={
                            !this.state.errors.applicationPlatform
                              ? false
                              : true
                          }
                          // helperText={this.state.errors.applicationPlatform}
                          name="applicationPlatform"
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={this.state.open}
                          onClose={this.handleClose}
                          onOpen={this.handleOpen}
                          value={this.state.applicationPlatform}
                          onChange={this.onChange}
                        >
                          <MenuItem value="">
                            <em>{t("none")}</em>
                          </MenuItem>
                          <MenuItem value="Web">{t("web")}</MenuItem>
                          <MenuItem value="Native">{t("native")}</MenuItem>
                          <MenuItem value="Service">{t("service")}</MenuItem>
                          <MenuItem value="single-page app">
                            {t("singlePageApp")}
                          </MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#f44336" }}>
                          {this.state.errors.applicationPlatform}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("callBackUrl")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfoUrl">
                        {this.state.callbackUrl}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <TextField
                        name="callbackUrl"
                        value={this.state.callbackUrl}
                        error={this.state.errors.callbackUrl ? true : false}
                        helperText={this.state.errors.callbackUrl}
                        onChange={this.onChange}
                        id="custom-css-standard-input"
                        label={t("callBackUrl")}
                        style={{
                          width: "100%",
                          color: "white",
                          marginBottom: 10,
                        }}
                        //inputProps={{ style: {textTransform: 'lowercase'} ,pattern: '^(ftp|http|https):\/\/[^ "]+$',title:i18n.t('validUrl')}}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("appDltWebhook")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfoUrl">
                        {this.state.appDeletionWebhook}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <TextField
                        name="appDeletionWebhook"
                        value={this.state.appDeletionWebhook}
                        onChange={this.onChange}
                        id="custom-css-standard-input"
                        label={t("appDltWebhook")}
                        style={{
                          width: "100%",
                          color: "white",
                          marginBottom: 10,
                        }}
                        inputProps={{
                          style: { textTransform: "lowercase" },
                          pattern: '^(ftp|http|https)://[^ "]+$',
                          title: i18n.t("validUrl"),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("status")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfo">
                        {this.state.status}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <FormControl>
                        <InputLabel
                          id="demo-controlled-open-select-label"
                          style={{ fontSize: "small" }}
                        >
                          {t("status")}
                        </InputLabel>
                        <Select
                          error={!this.state.errors.status ? false : true}
                          // helperText={this.state.errors.applicationPlatform}
                          name="status"
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={this.state.open}
                          onClose={this.handleClose}
                          onOpen={this.handleOpen}
                          value={this.state.status}
                          onChange={this.onChange}
                        >
                          <MenuItem value="">
                            <em>{t("none")}</em>
                          </MenuItem>
                          <MenuItem value="CREATED">{t("created")}</MenuItem>
                          <MenuItem value="RELEASED">{t("released")}</MenuItem>
                          <MenuItem value="ABSOLUTE">{t("absolute")}</MenuItem>
                        </Select>
                        <FormHelperText style={{ color: "#f44336" }}>
                          {this.state.errors.status}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <label>
                      <small className="applicationDetailsTitle">
                        {t("userDltWebhook")}
                      </small>
                      <br />
                      <small className="applicationDetailsInfoUrl" style={{}}>
                        {this.state.userDeletionWebhook}
                      </small>
                    </label>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <TextField
                        name="userDeletionWebhook"
                        value={this.state.userDeletionWebhook}
                        onChange={this.onChange}
                        id="custom-css-standard-input"
                        label={t("userDltWebhook")}
                        style={{
                          width: "100%",
                          color: "white",
                          marginBottom: 10,
                        }}
                        inputProps={{
                          style: { textTransform: "lowercase" },
                          pattern: '^(ftp|http|https)://[^ "]+$',
                          title: i18n.t("validUrl"),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flex-start" p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={1}
                  width="100%"
                >
                  {this.state.display && (
                    <div>
                      <label>
                        <small className="applicationDetailsTitle">
                          {"Application Url"}
                        </small>
                        <br />
                        <small className="applicationDetailsInfo">
                          {this.state.applicationUrl}
                        </small>
                      </label>
                    </div>
                  )}
                  {this.state.edit && (
                    <Box display="flex" justifyContent="center" width="100%">
                      <TextField
                        name="applicationUrl"
                        value={this.state.applicationUrl}
                        onChange={this.onChange}
                        id="custom-css-standard-input"
                        label={"Application Url"}
                        style={{
                          width: "100%",
                          color: "white",
                          marginBottom: 10,
                        }}
                        inputProps={{
                          style: { textTransform: "lowercase" },
                          pattern: '^(ftp|http|https)://[^ "]+$',
                          title: i18n.t("validUrl"),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <div className="applicationDetailsClientCredentials">
            {/* <Box display="flex" p={1} bgcolor="rgb(35, 104, 130, 0.14)" color="white" boxShadow="0px 0px 1px #FFFFFFC4" justifyContent="flex-end"> */}
            <Box
              display="flex"
              p={1}
              bgcolor="rgb(193, 193, 193, 0.5)"
              color="black"
              boxShadow="0px 0px 1px #FFFFFFC4"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box flexGrow={1}>{t("clientCredential")}</Box>
              <Box>
                <Tooltip title={t("regenerate")}>
                  {/* <RefreshIcon /> */}
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "capitalize" }}
                    onClick={this.regenerateClietCredentials}
                  >
                    {t("regenerate")}
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flext-start" p={1}>
                <Box display="flex" justifyContent="flex-start" p={1}>
                  <label>
                    <small className="applicationDetailsTitle">
                      {t("clientId")}
                    </small>
                    <br />
                    <small className="applicationDetailsClientIdInfo">
                      {this.state.clientId}
                    </small>
                  </label>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {this.state.clientSecrate && (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10}>
                <Box display="flex" justifyContent="flext-start" p={1}>
                  <Box display="flex" justifyContent="flex-start" p={1}>
                    <label>
                      <small
                        style={{ textTransform: "uppercase", color: "#000000" }}
                      >
                        {t("clientSecret")}
                      </small>
                      <br />
                      <small
                        style={{ color: "#000000", wordBreak: "break-word" }}
                      >
                        {this.state.clientSecrate}
                      </small>
                    </label>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
    );
  }
}

ApplicationDetails.propTypes = {
  regenerate: PropTypes.func.isRequired,
  getApplication: PropTypes.func.isRequired,
  updateApplication: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  devapp: state.devapp.devapp,
  errors: state.errors,
});
/*
 * export default connect( mapStateToProps, { getApplication, updateApplication,
 * regenerate } )(ApplicationDetails);
 */

export default compose(
  withTranslation(),
  connect(mapStateToProps, { getApplication, updateApplication, regenerate })
)(ApplicationDetails);
