import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { withStyles} from '@material-ui/core/styles';
import  ApplicationDetails from "../Application/ApplicationDetails";
import FeatureDataTable from '../Application/FeatureDataTable';
import MemberDataTable from '../Application/MemberDataTable';
import UserRoleDataTable from './UserRoleDataTable';
import './FullWidthTabsStyle.css';
import { useTranslation } from 'react-i18next';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
 

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginBottom:'10px',
    '& > span': {
      maxWidth: 30,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff !important',
    opacity:'none' ,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
	  border:'none',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
  },
}))((props) => <Tab disableRipple {...props} />);




const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"#00506E",
    width: "100%",
  },

  styletabs:{
	background:"#005a72",
	width: "100%",
  }
}));

export default function FullWidthTabs(props) {
  const appRole = props.appRole;
  const appType = props.appType;
  const appId = props.appId;
  let Personal = (appType === "Personal" || appRole==='ADMIN')? "" : "none";
  let team = appType !== "Personal" ? "" : "none";
  const developerId = props.devId;
  const appHome = props.appHome;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(Personal !== "" ? 1 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
   const { t, i18n } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.styletabs}>
        <StyledTabs value={value} onChange={handleChange} variant="fullWidth" aria-label="styled tabs example" >
            <StyledTab label={t("applicationDetails")} {...a11yProps(0)} style={{display:Personal}}/>
            <StyledTab label={t("features")} {...a11yProps(1)} />
            <StyledTab label={t("members")} {...a11yProps(2)} style={{display:team}}/>
          <StyledTab label={t("userRole")} {...a11yProps(3)} />
        </StyledTabs>
      </div>
      <SwipeableViews
        className="fullWidthTabsStyle"
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel className="tabPanel" value={value} index={0} dir={theme.direction}>
          <ApplicationDetails appRole = {appRole} appId={appId} developerId={developerId} appDetHistory={appHome} appType = {appType}/>
        </TabPanel>
        <TabPanel className="tabPanel" value={value} index={1} dir={theme.direction}>
           <FeatureDataTable appRole = {appRole} appId={appId} developerId={developerId} appDetHistory={appHome} appType = {appType}/>
        </TabPanel>
        <TabPanel className="tabPanel" value={value} index={2} dir={theme.direction}>
          <MemberDataTable appRole = {appRole} appId={appId} developerId={developerId} appDetHistory={appHome} appType = {appType}/>
        </TabPanel>
		    <TabPanel className="tabPanel" value={value} index={3} dir={theme.direction}>
          <UserRoleDataTable appRole={appRole}  appId={appId} developerId={developerId} appDetHistory={appHome} appType = {appType}/> 
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
