import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { validateCode,resetPassword } from "../../actions/securityActions";
import { Grid, Card,  CardContent, FormControl, InputLabel, Input, Button, FormHelperText } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import "../../App.css";
import "../Application/Application.css";
import "../DeveloperManagement/DeveloperMgmt.css";

import { withTranslation } from 'react-i18next';
import {compose} from 'redux';


class ResetPassword extends Component {
	//set state
	state = {
		isPasswordShown: false,
		isPasswordShown1: false
		};

		togglePasswordVisiblity = () => {
		const { isPasswordShown } = this.state;
		this.setState({ isPasswordShown: !isPasswordShown });
		};
		togglePasswordVisiblity1 = () => {
		const { isPasswordShown1 } = this.state;
		this.setState({ isPasswordShown1: !isPasswordShown1 });
	};


	constructor(props) {
    super(props);
    this.state = {
      password:"",
      confirmPassword:"",
      newPassword:"",
      success:false,
      errors: {},
	  show:"none",
	  edit:false,
	  readonly:true,
	  tempPasswordTemp:"",
      tempPasswordNew:"",
	};
	this.resetState = this.resetState.bind(this);
	this.onCloseAlert = this.onCloseAlert.bind(this);
    this.onChange = this.onChange.bind(this);
	this.onSubmit = this.onSubmit.bind(this);
	this.handleEdit = this.handleEdit.bind(this);
  }
	async componentWillReceiveProps(nextProps) {
	      this.setState({ errors: nextProps.errors });
	  }
	
  onCloseAlert() {
	this.setState({ success: false, edit: false, readonly: true, errors:{} });
	localStorage.removeItem("jwtToken");
	//setJWTToken(false);
	//window.location.href = "/dev/login";
	}

  handleEdit(){
	
	this.setState({
		
		edit:!this.state.edit,
		readonly:false,
		 tempPasswordNew: this.state.newPassword,
		 tempPasswordTemp: this.state.confirmPassword,
	})
  }

  resetState(){
	
	this.setState({
		
		edit:!this.state.edit,
		readonly:true,errors:{},
	    newPassword: this.state.tempPasswordNew,
	    confirmPassword: this.state.tempPasswordTemp
	
	})
  }

/*  async componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			
			const {
		      
		        success,
		        isActive,
				show,
			} = nextProps.developer;
			this.setState({
		        isActive,
		        success,
				show
			});
		} else {

    	}
	}*/

	componentDidMount() {
		//const { id } = this.props.match.params;
		const value = this.props.location.search
		var paramsArr = [];
		paramsArr = value.split('&temp');
		var code = paramsArr[0].substring(6);
		if(code != null) {
			this.props.validateCode(code, this.props.history);
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		//const { id } = this.props.match.params;
		const value = this.props.location.search
		var paramsArr = [];
		paramsArr = value.split('&temp');
		var code = paramsArr[0].substring(6);
		const Developer = {
			code:code,
			newPassword:this.state.newPassword,
			confirmPassword:this.state.confirmPassword,
			success:this.state.success,
			show:this.state.show
		};
		this.props.resetPassword(Developer, this.props.history);
	}



	render() {
		const { isPasswordShown } = this.state;
        const { isPasswordShown1 } = this.state;
		const { errors } = this.state;
		const { t } = this.props;
		
		function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

	 

		return (
			<div className="devPortalLabel changePasswordSection resetpwdContainer"> 
				<Grid container justify="center"  >
					
					<Grid item xs={12} sm={2}>
					</Grid>

					<Grid item xs={10} sm={8} style={{ height: "100%" }}>
						
						<div >
                            <Grid container style={{ paddingTop: "7%" }}>
                                <Grid item xs={12} sm={8} style={{ borderBottom: "1px solid #0099FF", boxShadow: "none !important", textAlign: "left", flexBasis: "100%", maxWidth: "100%", marginBottom: "20px" }}>
                                    <label style={{ font: "Montserrat,medium ", fontSize: "20px", marginRight: "5px", color: "#003E67" }}>
                                        {t("resetPassword")}
                        			</label>
                                </Grid>
                            </Grid>

 

                        </div>
						<Card className="changePasswordBlock" >
							<CardContent title="" className="cardContentContainer changePasswordHeader">
							{/*<Avatar size={100} style={avatar} className="test" >{this.state.firstName[0]}</Avatar>*/}
							<Grid container justify="start"></Grid>	
							</CardContent>
							<form onSubmit={this.onSubmit}>
								<CardContent className="changePasswordBody">

									<Grid container justify="center">																				
										
											<Grid item xs={12} sm={5}>
												<FormControl className="form-control">
													<InputLabel required className="input-label">{t("newPassword")}</InputLabel>
													<Input required name="newPassword"  className="input-field"
														placeholder="New Password"
														autoComplete="off"
														value={this.state.newPassword}
														onChange={this.onChange}
														error={this.props.errors.newPassword ? true : false}
														type={isPasswordShown ? "text" : "password"}
														endAdornment={
																<InputAdornment position="end">
																	<IconButton className="password-icon"
																	tabindex="-1"
																	aria-label="toggle password visibility"
																	onClick={this.togglePasswordVisiblity}
																	>
																	{isPasswordShown ? <Visibility /> : <VisibilityOff />}
																	</IconButton>
																</InputAdornment>
						  }
														inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30' }}
													/>
													<FormHelperText error={true}>{this.props.errors.newPassword}</FormHelperText>
			
												</FormControl>
												<FormControl className="form-control">
													<InputLabel className="input-label">{t("confirmPasswod")}</InputLabel>
													<Input  required name="confirmPassword"  className="input-field"
														placeholder="Confirm Password"
														autoComplete="off"
														value={this.state.confirmPassword}
														onChange={this.onChange}
														error={this.props.errors.confirmPassword ? true : false}
														type={isPasswordShown1 ? "text" : "password"}
														endAdornment={
															<InputAdornment position="end">
															<IconButton className="password-icon"
															tabindex="-1"
																aria-label="toggle password visibility"
																onClick={this.togglePasswordVisiblity1}
																>
																{isPasswordShown1 ? <Visibility /> : <VisibilityOff />}
															</IconButton>
															</InputAdornment>
															}
														inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case one number and one special charcter. Minimum 8 maximum 30' }}
													/>
													<FormHelperText error={true}>{this.props.errors.confirmPassword}</FormHelperText>													
												</FormControl>
												
											</Grid>
											{/*<Grid item xs={12} sm={5}>									
										</Grid>*/}
										{/* <Grid container style={{display:this.state.edit ? '' : 'none'}}>
											<Grid item xs={12} sm={5}></Grid>
											<Grid item xs={6} sm={1} className="two-button-grid">
												<Button variant="contained" color="primary" type="submit" className="update-button">
													Update
     					 						</Button>
											</Grid>
											<Grid item xs={6} sm={1} className="two-button-grid">
												<Button variant="contained" color="secondary" className="addApplicationBtn" onClick={this.resetState}>CANCEL</Button>
											</Grid>
										</Grid> */}
										
										<Grid container justify="center">
											<Grid item xs={6} className="two-button-grid" container
												direction="row"
												justify="flex-end"
												alignItems="flex-start" >
												<Button variant="contained" color="primary" type="submit" className="update-button">
													{t("update")}
     					 						</Button>
											</Grid>
											<Grid item xs={6} className="two-button-grid" 
												container
												direction="row"
												justify="flex-start"
												alignItems="flex-start">
												<Button variant="contained" color="secondary" className="addApplicationBtn" component={ Link } to="/">{t("cancel")}</Button>
											</Grid>
										</Grid>



									</Grid>
								</CardContent>


							</form>
						</Card>
					</Grid>

					<Grid item xs={12} sm={2}>
						{(this.state.success && (Object.keys(errors).length === 0)) && (
							<Snackbar style={{ height: "100%" }} anchorOrigin={{ vertical: "center", horizontal: "center" }} open={this.state.success} autoHideDuration={5000} onClose={this.onCloseAlert}>
								<Alert onClose={this.onCloseAlert} severity="success">
									{t("passwordUpdateStatus")}
								</Alert>
							</Snackbar>
						)}
					</Grid>

				</Grid >

			</div >
		);
	}
}



// export default ChangePassword;
ResetPassword.propTypes = {
  	changePassword: PropTypes.func.isRequired,
  	errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	security: state.security,
  	errors: state.errors
});

/* export default connect(
	mapStateToProps,
	{ validateCode,resetPassword }
)(ResetPassword); */

export default compose(
	withTranslation(),connect(mapStateToProps, {validateCode,resetPassword}))(ResetPassword);