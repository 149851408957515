import axios from "axios";
import { GET_ERRORS, GET_USERROLES, GET_USERROLE, DELETE_USERROLE } from "./types";


export const createUserRole = (appid, userRole) => async dispatch => {
  try {
    await axios.post("/api/v1.0/user-roles/" + appid, userRole);
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

export const updateUserRole = (appid, userRole) => async dispatch => {
  try {
    await axios.put("/api/v1.0/user-roles/" + appid, userRole);
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};

export const getUserRoles = (appId) => async dispatch => {
  const res = await axios.get("/api/v1.0/applications/"+ appId+"/userRoles");
  dispatch({
    type: GET_USERROLES,
    payload: res.data.data
  });
};

export const getGlobalRoles = () => async dispatch => {
  const res = await axios.get("/api/v1.0/user-roles/global");
  dispatch({
    type: GET_USERROLES,
    payload: res.data.data
  });
};

export const getUserRole = (id, history) => async dispatch => {
  try {
    const res = await axios.get(`/api/api/v1.0/user-roles/${id}`);
    dispatch({
      type: GET_USERROLE,
      payload: res.data.data
    });
  } catch (error) {
    history.push("/");
  }
};

export const deleteUserRole = id => async dispatch => {
  await axios.delete(`/api/v1.0/user-roles/${id}`);
  dispatch({
    type: DELETE_USERROLE,
    payload: id
  });
};
