import React, { Component } from 'react';
import './Application.css';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createFeature, updateFeature } from "../../actions/FeatureAction";
import './Application.css';
import { Button } from '@material-ui/core';
import IntervalTimer from 'react-interval-timer';
import './FeatureDataTableStyle.css';
import './Feature.css';

import { withTranslation } from 'react-i18next';
import {compose} from 'redux';

class FeatureDataTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
		id:"",
		featureName: "",
		developerId:this.props.developerId,
		applicationId: this.props.appId,
		features: [],
		isLoading: true,
		appRole:this.props.appRole,
		appType:this.props.appType,
		data:[],
		iserror:false,
		errorMessages:[],
		errors: {},
		showError:''
	};

    this.getFeatureList = this.getFeatureList.bind(this);
	this.handleRowUpdate = this.handleRowUpdate.bind(this);
	this.handleRowDelete = this.handleRowDelete.bind(this);
	this.handleRowAdd = this.handleRowAdd.bind(this);
  }

  validateEmail(email){
	const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
	return re.test(String(email).toLowerCase());
	}

  handleRowUpdate = (newData, oldData, resolve) => {
	 
	//validation
	const { t } = this.props; 
    let errorList = []
    var OK = /^\d*[a-zA-Z][a-zA-Z0-9_ ]*$/.test(newData.featureName)
    if (!OK) {
    	errorList.push(t("allowsAlphaNumeric"))
      }  
    		
    if(newData.featureName === ""){
      errorList.push(t("pleaseEnterFeatureName"))
    }
    if(newData.featureType == ""){
        errorList.push(t("pleaseEnterfeatureType"))
      }
    

    if(errorList.length < 1){
		const updateFeature = {
			id: newData.id,
			featureName: newData.featureName,
			featureType: newData.featureType,
			showError: this.state.showError
		};
		this.props.updateFeature(this.state.applicationId,updateFeature , this.props.history, this.state.appType, this.state.appRole);
		setTimeout(() => {
              axios.get("/api/v1.0/applications/"+this.state.applicationId+"/features")
				.then(( response)=> {
					this.setState({ 
						features: response.data.data, isLoading: false
					});
					});
					if((Object.keys(this.state.errors).length !== 0)){
						this.setState({showError:''})
					}
              resolve();
			}, 500)
			
        
		  
    }else{
      this.setState({errorMessages:errorList})
      this.setState({iserror:true,showError:''})
      resolve()

    }
	
	
  }

  handleRowAdd = (newData, resolve) => {
	  
	  const { t } = this.props;
    //validation
    let errorList = []
    		 var OK = /^\d*[a-zA-Z][a-zA-Z0-9_ ]*$/.test(newData.featureName)
    		    if (!OK && newData.featureType == undefined ) {
    		    	errorList.push(t("featureNameAllowsAlphaNumericAndType"))
    		      }  
    		    else if(!OK){
    		        errorList.push(t("allowsAlphaNumeric"))
    		   
    		      }
    
				    if(OK && newData.featureName === "" || newData.featureName == undefined){
						errorList.push(t("pleaseEnterFeatureName"))
					}
				    if(OK && newData.featureType == undefined){
				        errorList.push(t("pleaseEnterfeatureType"))
				      }
	

    if(errorList.length < 1){ 
		const newFeature = {
			
			featureName: newData.featureName,	   
			featureType: newData.featureType,
			showError: this.state.showError
		
		};
		this.props.createFeature(this.state.applicationId, newFeature, this.props.history, this.state.appType, this.state.appRole);
		setTimeout(() => {
              axios.get("/api/v1.0/applications/"+this.state.applicationId+"/features")
				.then((response )=> {
					this.setState({ 
						features: response.data.data, isLoading: false
					});
					});
			
				if((Object.keys(this.state.errors).length !== 0)){
						this.setState({showError:''})
					}
                resolve();
			}, 500)

    }else{
      this.setState({errorMessages:errorList})
      this.setState({iserror:true,showError:''})
      resolve()
    }

    
  }

  componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			const {
				id,
				featureName
			} = nextProps.appfeature;
			this.setState({
				id,
				featureName
			});
		}
	}

  componentDidMount() {
    this.setState({isLoading: true});
     axios.get("/api/v1.0/applications/"+this.state.applicationId+"/features")
      .then(( response )=> {
          this.setState({ 
            features: response.data.data, isLoading: false
          });
        });
 
  }

  getFeatureList() {
    this.setState({isLoading: true});
     axios.get("/api/v1.0/applications/"+this.state.applicationId+"/features")
      .then(( response )=> {
          this.setState({ 
            features: response.data.data, isLoading: false
          });
        });
 
  }

  async handleRowDelete(id,resolve) {
    await axios.delete(`/api/v1.0/feature/${id}/features`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
     let updated = [...this.state.features].filter(i => i.id !== id);
      this.setState({features: updated});
	});
	resolve();
  }

  

  render() {
	const { t } = this.props; 
	const columns = [
	{title: t("id"), field: "id", hidden: true},
	/* {title: "Avatar", render: rowData => <Avatar maxInitials={1} size={40} round={true} name={rowData === undefined ? " " : rowData.featureName} />  },
	
	rowData.name === '' ? { isValid: false, helperText: 'Name cannot be empty' } : true,
rowStyle: rowData => ({           backgroundColor: !!rowData.parentOnly ? "#EEE" : "#333"         })
	                                                                                             (area == 1) ? icon1 : (area == 2) ? icon2 : icon0;
	*/
	{title: t("featureName"), field: "featureName", validate: rowData => (rowData.featureName == "") ? { isValid: false, helperText: t("pleaseEnterFeatureName")}: (rowData.featureName == undefined)?{   }:true,
	rowStyle: rowData => ({backgroundColor: !!rowData.parentOnly ? "#333" : "#333"  })
		,headerStyle: {
			color:'black',
			backgroundColor: 'transparent',
		}},
	{title: t("featureType"), field: "featureType" , lookup: { 'New': t("new"), 'Customization': t("customization") },
		headerStyle: {
				color:'black',
				backgroundColor: 'transparent',
			},
			validate: rowData => (rowData.featureType == undefined) ? {}: true,
		
		}
	];

	const tableIcons = {
			Add: forwardRef((props, ref) => (<Button {...props} ref={ref} variant="contained" style={{background:"#0099FF",color:"white"}} disabled>{t("create")}</Button>
            )),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'green' }} tooltip=""/>),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'red' }} />),
		Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }}/>),
		DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }}/>),
		Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }}/>),
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }}/>),
		FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }}/>),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
		NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }}/>),
		PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }}/>),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }}/>),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }}/>),
		SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }}/>),
		ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }}/>),
		ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }}/>)
	};
	const { errors } = this.state;

	 

	return (
		<div className={"App dataTableContainer"}>
		
		<Grid container className="featureDataTableStyle">
			<Grid item xs={2}></Grid>
			<Grid item xs={12} sm={8}>
			<div>

				{errors.featureName && 
				<Alert severity="error" onClose={() => this.setState({ showError:"none" })} style={{display:this.state.showError}}>
					{errors.featureName && (
                      <div>{errors.featureName}</div>
                    )}
					<IntervalTimer
                        timeout={2000}
                        callback={()=>this.setState({ showError:"none",errors:{} })}
                        enabled={true}
                        repeat={true}
                    />
				</Alert>
				} 

				{this.state.iserror && 
				<Alert severity="error" onClose={() => this.setState({ showError:"none" })} style={{display:this.state.showError,color:'red'}}>
					{this.state.errorMessages.map((msg, i) => {
						return <div key={i}>{msg}</div>
					})}
					<IntervalTimer
                        timeout={2000}
                        callback={()=>this.setState({ showError:"none" })}
                        enabled={true}
                        repeat={true}
                    />
				</Alert>
				
				}    
			</div>
				<MaterialTable
				title="Features"
				columns={columns}
				data={this.state.features}
				icons={tableIcons}
				options={{
					actionsColumnIndex: -1,
					addRowPosition: 'first',
					showTitle: false,
					toolbarButtonAlignment:"left",
					toolbar:this.state.appRole !== 'TEAM_MEMBER',
					headerStyle: { position: 'sticky', top: 0 },
			          maxBodyHeight: 500,
				}}
				 localization={{
                                body: {
                                    emptyDataSourceMessage: t("emptyRecordsInfo"),
                                    editTooltip: t("edit"),
                                    deleteTooltip: t("delete"),
                                    addTooltip: t("create"),
                                    editRow: {
                                        deleteText: t("deleteWarning"),
                                        cancelTooltip: t("cancel"),
                                        saveTooltip: "",
                                    },
                                },
                                pagination: {
                                    labelDisplayedRows:'{from}-{to}' + "  "+t("of")+"  "+'{count}',
                                    labelRowsSelect: t("rows"),
                                    firstAriaLabel: t("firstPage"),
                                    firstTooltip: t("firstPage"),
                                    previousAriaLabel: t("previousPage"),
                                    previousTooltip: t("previousPage"),
                                    nextAriaLabel: t("nextPage"),
                                    nextTooltip: t("nextPage"),
                                    lastAriaLabel: t("lastPage"),
                                    lastTooltip: t("lastPage"),
                                },
								header: {
									actions: t("action")
								},
								toolbar: {
									searchPlaceholder: t("searchPlaceholder"),
									searchTooltip: t("search")
								},

 

                            }}

				editable={{
					isDeleteHidden: rowData => this.state.appRole === 'TEAM_MEMBER',
					isEditHidden: rowData => this.state.appRole === 'TEAM_MEMBER',
					onRowUpdate: (newData, oldData) =>
					new Promise((resolve) => {
						this.handleRowUpdate(newData, oldData, resolve);
						
					}),
					onRowAdd: (newData) =>
					new Promise((resolve) => {
						this.handleRowAdd(newData, resolve)
					}),
					onRowDelete: (oldData) =>
					new Promise((resolve) => {
						this.handleRowDelete(oldData.id, resolve)
					}),
				}}
				/>
			</Grid>
			<Grid item xs={2}></Grid>
			</Grid>
		</div>
	);

    
  }
}

FeatureDataTable.propTypes = {
  updateFeature: PropTypes.func.isRequired,
  createFeature: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	appfeature:state.devapp.appFeature,
	errors: state.errors,
	security: state.security
});

/* export default connect(
  mapStateToProps,
  { createFeature, updateFeature }
)(FeatureDataTable); */

export default compose(
	withTranslation(),connect(mapStateToProps, {createFeature, updateFeature }))(FeatureDataTable);
