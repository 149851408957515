import axios from "axios";
import { GET_ERRORS, GET_MEMBERS, DELETE_MEMBER, GET_MEMBERLIST } from "./types";

export const addMember = (appid, member) => async dispatch => {
  try {
    await axios.post("/api/v1.0/applications/add/"+ appid+"/developers", member);
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data.data
    });
  }
};


export const getMembers = (appId) => async dispatch => {
  const res = await axios.get("/api/v1.0/applications/"+ appId+"/developers");
  dispatch({
    type: GET_MEMBERLIST,
    payload: res.data.data
  });
};

export const suggestMembers = (email) => async dispatch => {
  const res = await axios.get("/api/v1.0/developers/email/" + email);
  dispatch({
    type: GET_MEMBERS,
    payload: res.data.data
  });
};

/* export const getUserRole = (id, history) => async dispatch => {
  try {
    const res = await axios.get(`/member/${id}`);
    dispatch({
      type: GET_MEMBER,
      payload: res.data
    });
  } catch (error) {
    history.push("/");
  }
};
 */

export const deleteMember = (appid, id) => async dispatch => {
  await axios.delete("/api/developer/remove/", appid, id);
  dispatch({
    type: DELETE_MEMBER,
    payload: id
  });
};
