import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import code from  '../../images/code.png';

import { login } from "../../actions/securityActions";



import { withTranslation } from 'react-i18next';
import {compose} from 'redux';
import { connect } from "react-redux";

class DevPortalLabel extends Component {

  render() {


     const { t } = this.props;
    return (
      <div className="projects" className="devPortalLabel">
        <Grid>
          <Grid item xs={12} style={{background:"#002143",borderRadius:"0px",height:"80px",color:"white"}}
            container
            direction="row"
            justify="center"
            alignItems="center"
            >
            <label style={{font:"Montserrat,medium ",fontSize:"20px",marginRight:"20px"}}>
             {t("developerCenter")}
            </label> <img src={code} width="50px" style={{opacity :1}} alt=""/>
          </Grid>
          
        </Grid>

        
      </div>
    );
  }
}


const mapStateToProps = state => ({
  security: state.security
});

export default compose(withTranslation(),connect(mapStateToProps, { login }))(DevPortalLabel);



