export const GET_ERRORS = "GET_ERRORS";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";


export const GET_USERS = "GET_PROJECTS";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";

export const GET_DEVELOPERS = "GET_PROJECTS";
export const GET_DEVELOPER = "GET_DEVELOPER";
export const DELETE_DEVELOPER = "DELETE_DEVELOPER";
export const GET_DASHBOARD = "GET_DASHBOARD";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATION = "GET_APPLICATION";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";

export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURE = "GET_FEATURE";
export const DELETE_FEATURE = "DELETE_FEATURE";
export const UPDATE_FEATURE = "UPDATE_FEATURE";

 

export const GET_USERROLES = "GET_USERROLES";
export const GET_USERROLE = "GET_USERROLE";
export const DELETE_USERROLE = "DELETE_USERROLE";
export const UPDATE_USERROLE = "UPDATE_USERROLE";

 


export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBER = "GET_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const GET_MEMBERLIST = "GET_MEMBERLIST";

export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICE = "GET_SERVICE";
export const EMAIL_VALIDATE = "EMAIL_VALIDATE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const VALIDATE_CODE = "VALIDATE_CODE";

GET_DASHBOARD

