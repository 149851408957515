import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import styles from "./headerStyle";
import HeaderLinks from "./HeaderLinks";
import { connect } from "react-redux";
import { logout } from "../../actions/securityActions";
import { NavLink } from "react-router-dom";
import MinervaIOTLogoBlack from '../../images/Minerva_LogoColorBlock.svg';
import MinervaIOTLogo from '../../images/Minerva_LogoColor.svg';
import ZKTecoLogo from '../../images/ZKTeco_Gray-Green.png';
import UnsecureHeaderLinks from "./UnsecureHeaderLinks";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(styles);


function Header(props) {

  const logout = () => {
    props.logout();
    window.location.href = "/";
  }

  const { validToken, user } = props.security;



  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = (e) => {
    let myStr = e.target.className.toString();

    if (myStr.includes("MuiAvatar")) {

      setMobileOpen(true);

    }
    else if (myStr.includes("SVGAnimatedString")) {
      setMobileOpen(true);
    }
    else {
      setMobileOpen(!mobileOpen);
    }


  };

  const { color, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  let rightLinks = <HeaderLinks align="" user={user}></HeaderLinks>
  let rightVerticalLinks = <HeaderLinks align="vertical" user={user}></HeaderLinks>

  let rightunSecureLinks = <UnsecureHeaderLinks user={user}></UnsecureHeaderLinks>
  let rightVerticalunSecureLinks = <UnsecureHeaderLinks align="vertical" user={user}></UnsecureHeaderLinks>

  let HeaderBody = "";
  let SecureHeader = (
    <AppBar className={appBarClasses} style={{ position: 'fixed', zIndex: "99999", backgroundColor: "#0099FF", color: 'white', borderRadius: "0px", padding: "0px", marginBottom: "0px", height: "70px" }} >

      <div className={classes.flex} style={{ paddingLeft: "2%", textAlign: "left" }}>
        <NavLink to="/dev/apps/" aria-selected="true" className="nav-link" style={{ textDecoration: 'none', color: 'unset', display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
          <img src={MinervaIOTLogo} className="header_logo" alt="Logo" />
          {/* <p className="powered_by"> 
            Powered by 
            <img src={ZKTecoLogo} alt="ZKTeco Logo" className="zkteco_logo" />
          </p> */}
        </NavLink>
        {/* <NavLink to="/dev/apps/" aria-selected="true" className="nav-link" style={{ textDecoration: 'none', color: 'unset', textTransform: 'capitalize' }}>
          <img src={MinervaIOTLogo} className="header-logo" alt="Logo" />
          <p className="powerd-by"> 
            Powered by 
            <img src={ZKTecoLogo} alt="ZKTeco Logo" className="zkteco-logo" />
          </p>
        </NavLink> */}
      </div>
      {/* <div className={classes.flex} style={{paddingLeft:"2%"}}>
                        <img src={ZktecoLogo} alt="Logo" />
                      </div> */}
      <Toolbar>


        <Hidden smDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js" >
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
          onClick={handleDrawerToggle}
          style={{ zIndex: "99999" }}
        >
          <div className={classes.appResponsive}>
            {rightVerticalLinks}
          </div>
        </Drawer>
      </Hidden>
      {/* <Link style={{color:"white"}}
                            className="nav-link"
                            to="/logout"
                            onClick={logout}
                          >
                            Logout
                      </Link> */}
    </AppBar>
  );

  let unsecureHeader = (
    <AppBar className={appBarClasses} style={{ position: 'fixed', zIndex: "99999", backgroundColor: "#0099FF", color: 'white', borderRadius: "0px", padding: "0px", marginBottom: "0px", height: "70px", boxShadow: "none" }} >

      <div className={classes.flex} style={{ paddingLeft: "2%", textAlign: "left" }}>
        {/* <NavLink to="/" aria-selected="true" className="nav-link" style={{ textDecoration: 'none', color: 'unset', display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
          <img src={MinervaIOTLogo} className="header-logo" alt="Logo" />
          <p className="powerd-by"> Powered by 
          <img src={ZKTecoLogo} alt="ZKTeco Logo" className="zkteco-logo" /></p>
        </NavLink> */}
        
        <Box display="flex" flexDirection="row" p={1} m={1} >
        <Box >
        <NavLink to="/" aria-selected="true" className="nav-link" style={{ textDecoration: 'none', color: 'unset', display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
          <img src={MinervaIOTLogo} className="header-logo" alt="Logo" />
        </NavLink>
        </Box>
        
      </Box>
      </div>
      <Toolbar >
        <Hidden smDown implementation="css" >
          {rightunSecureLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
          onClick={handleDrawerToggle}
          style={{ zIndex: "99999" }}
        >
          <div className={classes.appResponsive}>
            {rightVerticalunSecureLinks}

          </div>

        </Drawer>
      </Hidden>

    </AppBar>
  );

  if (validToken && user) {
    HeaderBody = SecureHeader;
  } else {
    HeaderBody = unsecureHeader;
  }
  return <div>
    {HeaderBody}
  </div>
}

Header.propTypes = {
  //fixed: true,
  absolute: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  security: state.security
});

export default connect(
  mapStateToProps,
  { logout }
)(Header);