import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import "./Application.css"
import FullWidthTabs from "../../components/Application/FullWidthTabs";
import TimeLine from "../Layout/Breadcrumb";
import { getApplication } from "../../actions/ApplicationAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DevPortalLabel from "../Layout/DevPortalLabel";
import './ApplicationHomeStyle.css';

import { withTranslation } from 'react-i18next';
import {compose} from 'redux';


class AppHome extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loggeduser: this.props.security.user,
      applicationId: this.props.location.devId,
      developerId: this.props.location.devId,
      appRole: "",
      appType: "",
      application: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      const {
        application
      } = nextProps.devapp;
      this.setState({
        application
      });
    }
  }

  componentDidMount() {
    this.props.getApplication(this.state.applicationId, this.history)

  }

  render() {

    const { t } = this.props;

    const { id, appType, appRole } = this.props.match.params;
    this.state.appType = appType;
    this.state.appRole = appRole;
    var pieces = id.split("@");
    this.state.applicationId = pieces[0];
    var tabIndex = "";
    if (pieces[1] != undefined || pieces[1] != null) {
      tabIndex = pieces[1];
    }
    const path = t("applications")+"/" + this.props.devapp.applicationName;
    return (

      <div className="root applicationHomeStyle">
        <DevPortalLabel></DevPortalLabel>
        <Grid>
          <TimeLine path={path} />
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs={12} sm={8}>
              <Grid>
                <FullWidthTabs
                  app={this.state.application}
                  devId={this.state.loggeduser.id}
                  appHome={this.props.history}
                  appRole={this.state.appRole}
                  appType={this.state.appType}
                  appId={this.state.applicationId}
                  tabIndex={tabIndex} />
              </Grid>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>

      </div>
    );
  }
}

AppHome.propTypes = {
  security: PropTypes.object.isRequired,
  getApplication: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  devapp: state.devapp.devapp,
  security: state.security
});

/* export default connect(
  mapStateToProps,
  { getApplication }
)(AppHome); */

export default compose(
	withTranslation(),connect(mapStateToProps, { getApplication }))(AppHome);