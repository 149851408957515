import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AvatarComponent from "./AvatarComponent";
import { CustomLeftArrow, CustomRightArrow } from "./custom";
import "./styles.css";
import Background from "./Svg/home.svg";
import shapeSvg from "./Svg/subtraction_shape.svg";
import pathSvg from "./Svg/path_shape.svg";
import { Link } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {compose} from 'redux';
import { login } from "../../actions/securityActions";
import DeviceMangIconSvg from "./Svg/DeviceMangIcon.svg";
import NotfmanageIconSvg from "./Svg/NotfmanageIcon.svg";
import subscriptionIconSvg from "./Svg/Subscription.svg";
import multipleCompanyIconSvg from "./Svg/MultipleCompany.svg";
import iotIconSvg from "./Svg/IOT.svg";
import biometricVerificationIconSvg from "./Svg/BiometricVerification.svg";
import userManagementIconSvg from "./Svg/UserMangment.svg";
import bigdataAnalysisIconSvg from "./Svg/BigDataAnalysis.svg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1400 },
    items: 5,
    slidesToSlide: 3
  },
  laptop: {
    breakpoint: { max: 1399, min: 1280 },
    items: 4,
    slidesToSlide: 2 
  },
  tabletBig: {
    breakpoint: { max: 1279, min: 992 },
    items: 3,
    slidesToSlide: 2 
  },
  tabletSmall: {
    breakpoint: { max: 991, min: 576 },
    items: 2,
    slidesToSlide: 2 
  }, 
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

class Home extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      textValue:"",
      textLable :"",
    };
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
		this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
  }

handleDeleteDialogOpen(modalText,labelName) {
		this.setState({ open: true ,textValue:modalText,textLable: labelName});
	}

	handleDeleteDialogClose() {
		this.setState({ open: false });
	}

  render() {
    const { t } = this.props;

    const HomeDetails = [
    {	
      id:1,
      labelName:t("deviceManagement"),
      image:DeviceMangIconSvg,
      Desc:t("integrationWithThirdPartyDevices"),
      modalText:t("deviceManagementFunctionProvides")
    },
    {
      id:2,
      labelName:t("notificationManagement"),
      image:NotfmanageIconSvg,
      Desc:t("getNotificationOnSocialPlatforms"),
      modalText:t("whatNotificationManagementAllows")
    },
    {
      id:3,
      labelName:t("userAuthentication"),
      image:userManagementIconSvg,
      Desc:t("identityManagementService"),
      modalText:t("whichProductsMinervaIoTEnablesTouToAccess")
    },
    {
      id:4,
      labelName:t("subscriptionManagement"),
      image:subscriptionIconSvg,
      Desc:t("applicationPaymentPlanningService"),
      modalText:t("minervaIoTCloudFacilitatesLicenseManagement")
    },
    {
      id:5,
      labelName:t("multipleCompany"),
      image:multipleCompanyIconSvg,
      Desc:t("multipleCompanyAccountManagement"),
      modalText:t("multipleCompanyFunctionAllows")
    },
    {
      id:6,
      labelName:t("ioTEcosystem"),
      image:iotIconSvg,
      Desc:t("improveMonitoringWithIoTEcosystem"),
      modalText:t("providedIntegratedServices")
    },
    {
      id:7,
      labelName:t("biometricVerification"),
      image:biometricVerificationIconSvg,
      Desc:t("authenticationThroughCloudAPI"),
      modalText:t("biometricVerificationAPIServiceEnables")
    },
    {
      id:8,
      labelName:t("bigDataAnalysis"),
      image:bigdataAnalysisIconSvg,
      Desc:t("takeYourBusinessIntoNextLevel"),
      modalText:t("ourBigDataAnalysisOffers")
    },
  ]

    const cardDetails =  HomeDetails.map(details => ( 
    		<Card  key={details.id} className="cardContainer" onClick={this.handleDeleteDialogOpen} onClick={() => { this.handleDeleteDialogOpen(details.modalText,details.labelName); }}>     
    		<CardContent >
		         <Grid container spacing={2}>
		           <Grid item={true} xs={12}>
		             <div className="card-label">
		              {details.labelName}
		             </div>
		           </Grid>
		           <Grid item={true} xs={12} sm={12}>
		             <div className="card-image"
		             >
		               <img
		                 src= {details.image}
		                 alt="Remy Sharp"
		                 width="80"
		                 height="80"
		               />
		             </div>
		           </Grid>
		
		           <Grid item={true} xs={12} sm={12}>
		             <div className={"card-Desc"}>
		               {details.Desc}
		             </div>
		           </Grid>
		
		           <Grid item={true} xs={12} sm={12}>
		             <div className="text-style">
		               <ArrowForwardIosIcon 
		                 style={{ fontSize: 10 }}
		                 fontSize="small"
		               />{" "}
		               {t("more")}
		             </div>
		           </Grid>
		         </Grid>
	       </CardContent>
	     </Card>
     )); 
    
    return (
      <div className="devPortalHome">
      	<Dialog style={{ textAlign: "center" }}
					open={this.state.open}
					onClose={this.handleDeleteDialogClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{this.state.textLable}</DialogTitle>
					<DialogContent>{this.state.textValue}
					</DialogContent>
					<div style={{ marginRight: "40%" }}>
						<DialogActions >
							<Button onClick={this.handleDeleteDialogClose} variant="contained" color="primary">
								{t("close")}
         			 </Button>
						</DialogActions>
					</div>
				</Dialog>
        <Grid container spacing={2}>
	      	 <Grid item={true} xs={12} sm={12} lg={12} md={12}>
		        <div
		          className="paperContainer"
		          style={{
		            backgroundImage: `url(${Background})`,
		          }}
		        >
		          <div className="bannerContent">
                <Grid item={true} xs={12} sm={10} lg={5} md={7}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <h3 className={"label-style brandLabel textAlignCenter"}>
                   {t("minervaIot")} 
                  </h3>
                  <h5 className={"txtLabel"}>
                   {t("applicationTitle")} 
                  </h5>
                  <h5 className={"txtLabel"}>
                   {t("useOfZktecoServices")}  
                  </h5>
                  <div className="textAlignCenter">
		                  {/* <Button className="btn-style" component={ Link } to={'/dev/signUp'}>
		                    <span>{t("signUp")}</span>
		                  </Button> */}
		                </div>              
                </Grid> 
		          </div>
		        </div>
	        </Grid>
        </Grid>

        <div
          className="shape"
          style={{
            backgroundImage: `url(${shapeSvg})`           
          }}
        >
          
          <Grid item={true} xs={12} sm={12}>
            <div className={"labeltxt-style textAlignCenter"}>
              {t("companyName")}
            </div>
          </Grid>
          <div className="servicesCarousel"  >
            <Carousel 
              swipeable={false}
              draggable={true}
              arrows
              showDots={false}
              slidesToSlide={1}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              keyBoardControl={true}
              transitionDuration={100}
              containerClass="carousel-container"
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-60-px"
              renderDotsOutside={true}
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
           >
            	{cardDetails}
            </Carousel>
            <div className="divider-style" ></div>
          </div>
          
        </div>
         <Grid container direction="row" justify="center" alignItems="center" >
          <Grid item={true} xs={12} sm={12} md={12} lg={10} xl={10}>
            <div>
              <div
                className="avatarContainer"
                style={{ backgroundImage: `url('${pathSvg}')` }} >
                <Grid item={true} xs={12} sm={12}>
                  <div className={"labelText-style textAlignCenter"}>
                   {t("whoRequiresIot")} 
                  </div>
                </Grid>
                <div className="avatarPad">
                  <AvatarComponent />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item={true} xs={12} sm={12}>
            <div className="footerContainer">
              <div>
               {t("shareUsingCloudApi")}  
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Home.propTypes = {
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  security: state.security
});

export default compose(withTranslation(),connect(mapStateToProps, {login}))(Home);
   
