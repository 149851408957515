import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import itProfessionalSvg from "./Svg/ItProfessional.svg";
import TechnologyIconSvg from "./Svg/TechnologyIcon.svg";
import organizationIconSvg from "./Svg/organizationIcon.svg";
import developerIconSvg from "./Svg/developer.svg";
import Grid from "@material-ui/core/Grid";

class AvatarComponent extends Component {
  render() {
    return (
      <div style={{ display: "flex", minHeight: "190px" }}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6} md={3} lg={3} xl={2} sm={6}>
            <div className="orgImage">
              <Avatar
                className="cta-avtar-img"
                src={organizationIconSvg}
                alt="Organisations"
              />
            </div>
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} sm={6}>
            <Avatar
              className="cta-avtar-img"
              src={itProfessionalSvg}
              alt="IT Professionals"              
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={2} sm={6}>
            <Avatar
              className="cta-avtar-img"
              src={TechnologyIconSvg}
              alt="Technology Geeks"
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3} xl={3} sm={6}>
            <Avatar
              className="cta-avtar-img"
              src={developerIconSvg}
              alt="Developers"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AvatarComponent;
