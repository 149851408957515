import { GET_SERVICES,GET_SERVICE } from "../actions/types";

const initialState = {
	serviceList : {},
	service: {}

};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_SERVICES:
			return {
				...state,
				serviceList: action.payload
			};
	   case GET_SERVICE:
			return {
				...state,
				service: action.payload
			};
		default:
			return state;
	}
}
