import { GET_DEVELOPER, GET_MEMBERS,GET_DASHBOARD } from "../actions/types";

const initialState = {
	developer: {},
	developerList : {},
	devdata : {}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_DEVELOPER:
			return {
				developer: action.payload
			};
		case GET_DASHBOARD:
			return {
				devdata: action.payload 
			};
			
		case GET_MEMBERS:
			return {
				developerList: [ ...action.payload ]
			};
			
		
		default:
			return state;
	}
}
