import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from 'axios';

//change
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import i18n from './components/Util/i18n';
import BrowserLanguage from "./components/Util/BrowserLanguage";

axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
    if (401 === error.response.status) {
		localStorage.removeItem("jwtToken");
  		window.location = '/dev/login';
    } else {
        return Promise.reject(error);
    }
});

// axios.interceptors.request.use((config) => {

//   const language = "?language="+BrowserLanguage.getDefaultLanguage();
//   //config.url = config.url+language;
//   return config
// }, error => {
//   return Promise.reject(error)
// })

/*axios.interceptors.request.use(config => {
     const language = BrowserLanguage.getDefaultLanguage();
     config.headers.common[ 'accept-language'] = language ;
  		return config;
}, error => {
  
  return Promise.reject(error);
});
*/

//ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();

ReactDOM.render(
		<I18nextProvider i18n={i18n}>
    	<App />
  		</I18nextProvider>, document.getElementById('root'));

//ReactDOM.render(<App />, document.getElementById('root'));


