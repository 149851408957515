import React, { Component } from 'react';
import Headerimage from '../../components/Layout/images/home.png';
import Businessimage from '../../images/Picture1.png';
import './MinervaIotPlatform.css';
import { Card, Grid, Typography } from '@material-ui/core';
import security from '../../images/ZktecoLogo.png';
import zktecoimg from '../../images/zktecodevice.PNG';
import deviceimg from '../../images/3rdpartydevice.PNG';
import systemintimg from '../../images/Picture66.1.png';
import pic1 from '../../images/Picture62.png';
import pic3 from '../../images/Picture64.png';
import pic2 from '../../images/Picture63.png';
import pic4 from '../../images/Picture65.png';
import Paper from '@material-ui/core/Paper';
import sdkser from '../../images/Picture73.PNG';
import apiser from '../../images/Picture74.PNG';
import googleimg from '../../images/Picture57.png';
import arrowimg from '../../images/Picture61.png';
import iotimg from '../../images/iot.png';
import iot1 from '../../images/Picture9.jpg';
import licenceimg from '../../images/Picture50.png';
import paymentgateway from '../../images/payment.png';
import twitterimg from '../../images/Picture56.png';
import facebookimg from '../../images/Picture59.png';
import paytm from '../../images/Picture52.png';
import paypal from '../../images/Picture53.png';
import ofc365 from '../../images/Picture14.png';
import cardimg from '../../images/Picture51.png';
import bankimg from '../../images/Picture54.png';
import linkedimg from '../../images/Picture55.png';
import orgimg from '../../images/Picture21.png';
import onlineimg from '../../images/Picture76.PNG';
import iot2 from '../../images/Picture68.png';
import businessimg from '../../images/Picture1.png';
import userauthentication from '../../images/user-authentication.png';
import iotsys from '../../images/6.PNG';
import sdk1 from '../../images/Picture69.png';
import face from '../../images/Picture70.png';
import fingure from '../../images/Picture71.png';
import iris from '../../images/Picture72.png';
import bigdata from '../../images/bigdata.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import AppsIcon from '@material-ui/icons/Apps';
import CloudIcon from '@material-ui/icons/Cloud';
import EcoIcon from '@material-ui/icons/Eco';
import 'react-multi-carousel/lib/styles.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PublicIcon from '@material-ui/icons/Public';
import PeopleIcon from '@material-ui/icons/People';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import HowToRegIcon from '@material-ui/icons/HowToReg';




const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


class ApplicationPage extends Component {
    // Business Logical Architecture​
    render() {
        return (
            <div id="notfound">

                <div class="jumbotron text-center">
                    <h1 className="header1">Minerva IoT Cloud</h1>
                    <h5 className="header2">IoT platform with wide range of ZKTeco Services</h5>
                </div>

                {/* <div spacing="0">

                    <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                        <Grid item xs={12} sm={12} lg={1}>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={10}>
                            <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                <img alt="..." className="img-fluid p-2 logicalimage businessimage" src={businessimg} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={1}>
                        </Grid>
                    </Grid>
                </div> */}

                <div>
                    <div className="containertext">
                        <div className="header3">BUSINESS LOGICAL ARCHITECTURE
                            <hr class="hrline"></hr>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}></Grid>
                            <Grid item xs={12} sm={12} lg={10}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2 logicalimage businessimage" src={businessimg} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}></Grid>
                        </Grid>
                    </div>
                    <div className="containertext">
                        <div className="header3">ADVANTAGES OF MINERVA
                        <hr class="hrline"></hr>
                            <div className="subheading">ZKTeco Minerva IoT Platform </div>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="text-center">
                                    <LockIcon style={{ fontSize: 50 }} color="Primary" />
                                </div>
                                <div className="advantages">Enhances Security</div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="text-center d-flex align-items-center pt-3 justify-content-center">
                                    <HowToRegIcon style={{ fontSize: 50 }} color="Primary" />
                                </div>
                                <div className=" text-center advantages">Solution Creation</div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="card-box card-shadow-first p-4 ">
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <AppsIcon style={{ fontSize: 50 }} color="Primary" />
                                    </div>
                                    <div className="text-center advantages">3rd Party Platform Integration</div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="card-box card-shadow-first p-4 ">
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <CloudIcon style={{ fontSize: 50 }} color="Primary" />
                                    </div>
                                    <div className="text-center advantages">Amazon Web Services</div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="card-box card-shadow-first p-4 ">

                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <EcoIcon style={{ fontSize: 50 }} color="Primary" />
                                    </div>
                                    <div className=" text-center advantages">	Open IoT Eco-System</div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="card-box card-shadow-first p-4 ">

                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <ShowChartIcon style={{ fontSize: 50 }} color="Primary" />
                                    </div>
                                    <div className=" text-center advantages ">Business Intelligence</div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="card-box card-shadow-first p-4 ">

                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <PublicIcon style={{ fontSize: 50 }} color="Primary" />
                                    </div>
                                    <div className="text-center advantages">Connects Various Cloud Services</div>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="card-box card-shadow-first p-4 ">

                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <PeopleIcon style={{ fontSize: 50 }} color="Primary" />
                                    </div>
                                    <div className="text-center advantages">3rd Party Services Integration</div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="containertext">
                        <div spacing="10">
                            <div className="header3" >DEVICE MANAGEMENT: INTERNAL & 3RD PARTY DEVICES
                            <hr class="hrline"></hr></div>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>

                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>
                                <div className="card-box card-shadow-first p-4 ">
                                    <div className="subheading text-center"><b>ZKTeco Devices</b> </div>
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={zktecoimg} style={{width:'500px'}}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>
                                <div className="card-box card-shadow-first p-4 ">
                                    <div className="subheading text-center"><b>3rd Party Devices</b></div>
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={deviceimg} style={{width:'600px'}}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>

                            </Grid>
                        </Grid>
                    </div>
                    <div className="containertext">
                        <div className="header3">INTERNAL DEVICE & 3RD PARTY DEVICE MANAGEMENT
                        <hr class="hrline"></hr>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5} className="m-4" style={{ marginTop: '4rem !important' }}>
                                <Grid container direction="row" justify="center">
                                    <Grid item md={6}>
                                        <img alt="..." className="img-fluid p-2" src={pic1} />
                                    </Grid>
                                    <Grid item md={6}>
                                        <img alt="..." className="img-fluid p-2" src={pic2} />
                                    </Grid>

                                </Grid>
                                <Grid container direction="row" justify="center">
                                    <Grid item md={6}>
                                        <img alt="..." className="img-fluid p-2" src={pic3} />
                                    </Grid>
                                    <Grid item md={6}>
                                        <img alt="..." className="img-fluid p-2" src={pic4} />
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={5}>
                                <div className="card-box card-shadow-first p-4 ">
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={systemintimg} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                    </div>


                    <div className="containertext">

                        <div className="header3">NOTIFICATION SERVICE MANAGEMENT
                        <hr class="hrline"></hr>
                            <div className="subheading text-center">Widely used Social Media Platforms </div>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={10}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={iotsys} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>

                    </div>

                    <div className="containertext">
                        <div className="header3" >USER AUTHENTICATION MANAGEMENT
                        <hr class="hrline"></hr>
                            <div className="subheading text-center">Single User credential to integrate Minerva IoT Platform </div>
                        </div>
                        {/* <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={linkedimg} />
                                </div>
                                <Grid container direction="row" justify="center">
                                    <Grid item md={6}>
                                        <img alt="..." className="img-fluid p-2" src={twitterimg} />
                                    </Grid>
                                    <Grid item md={6} align="center">
                                        <img alt="..." className="img-fluid p-2" src={googleimg} />
                                        <img alt="..." className="img-fluid p-2" src={ofc365} />
                                    </Grid>

                                </Grid>
                                <Grid align="center" justify="center">
                                    <img alt="..." className="img-fluid p-2" src={facebookimg} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2} container direction="row" justify="center" alignItems="center">
                                <img alt="..." className="img-fluid" src={arrowimg} />
                                <div className="auth"><b>Auth Protocol like SAML, Oauth, LDAP</b></div>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>

                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={iotimg} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid> */}
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={10}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={userauthentication} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="containertext">
                        <div className="header3">SUBSCRIPTION MANAGEMENT
                        <hr class="hrline"></hr>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <div className="card-box card-shadow-first">
                                    <div className="subheading text-center"><b>License Management</b></div>
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={licenceimg} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={2}>
                                <div spacing="4">
                                    <div className="text-center mt-4 subheading"><b>“Implementing secure & easy payment gateways”</b></div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <div className="subheading text-center"><b>Payment Gateway</b></div>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={paymentgateway} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                        <div className="advantages text-center">
                            The license management plays a powerful role in enhancing the IoT services.   <br></br>
                        ZKTeco MinervaIoT Cloud has a variety of licenses to access IoT services and the license<br></br> can be customized based on
                        Users, Devices, Event logs, and many more services.<br></br>
                        </div>
                    </div>

                    <div className="containertext">
                    <div className="header3"> MULTIPLE COMPANY MANAGEMENT
                        <hr class="hrline"></hr>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>

                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={orgimg} style={{width:'600px'}}/>
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                
                                <div className="card-box card-shadow-first m-4">
                                    {/* <div className="header4">MULTIPLE COMPANY MANAGEMENT</div> */}

                                    {/* <List subheader={<ListSubheader><h5> Multiple Users & Multiple Accounts</h5></ListSubheader>} className="m-4">
                                        <ListItem>
                                            <ListItemIcon>
                                                <ArrowRightAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Supports Multiple Company Account Management" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ArrowRightAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Customized Owner/Vendor/Employee roles to meet different organizational requirements" />
                                        </ListItem>
                                    </List> */}
                                    {/* <div className="text-center mt-4 subheading2">Supports Multiple Company Account Management</div> */}
                                    <h2 class="m-4 devtext text-left">Supports Multiple Company Account Management</h2>
                                    <ListItem style={{ paddingTop: '10px;' }}>
                                        <ListItemIcon>
                                            <FiberManualRecordIcon style={{ fontSize: '10px' }} />
                                        </ListItemIcon>
                                        <ListItemText className="ecotext1" primary="Supports Multiple Company Account Management" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <FiberManualRecordIcon style={{ fontSize: '10px' }} />
                                        </ListItemIcon>
                                        <ListItemText className="ecotext1" primary="Customized Owner/Vendor/Employee roles to meet different organizational requirements" />
                                    </ListItem>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="containertext">

                        <div className="header3">DEVELOPER CENTER
                        <hr class="hrline"></hr>
                        </div>
                        
                         <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={8}>
                            <div className="card-box card-shadow-first p-4 ">
                                    {/* <div className="subheading text-center"><b>API Services</b></div> */}
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={sdkser} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={8}>
                            <div className="card-box card-shadow-first p-4 ">
                                    {/* <div className="subheading text-center"><b>SDK Services</b></div> */}
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={apiser} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="containertext">
                        <div>
                            <div className="header3">IoT ECOSYSTEM
                            <hr class="hrline"></hr>
                            </div>

                            <h4 className="m-4 ecotext ">Minerva IoT provides Ecosystem integration with software frameworks such<br/> as Microsoft Office 365, Apple HomeKit, Amazon Alexa, GSuit, Google Home and so on</h4>
                        </div>
                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={10}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={iot2} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="containertext">
                        <div className="header3"> BIOMETRIC VERIFICATION API SERVICE
                        <hr class="hrline"></hr>
                        </div>

                        <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }}>

                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2 sdlimg" src={sdk1} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>

                                <div className='mt-4'>
                                    <h2 className="m-4 devtext text-left" >USER BIOMETRIC VERIFICATION </h2>
                                    <h4 className="m-4 mt-4 ecotext text-left ">Users can use biometric verification methods such as Face, Fingerprint, Iris to authenticate through Cloud API</h4>
                                </div>
                                <Grid container direction="row" justify="center" className="m-4">
                                    <Grid item md={4} >
                                        <img alt="..." className="img-fluid" src={face} />
                                    </Grid>
                                    <Grid item md={4}>
                                        <img alt="..." className="img-fluid" src={fingure} />
                                    </Grid>
                                    <Grid item md={4}>
                                        <img alt="..." className="img-fluid" src={iris} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="containertext">
                    <div className="header3">BIG-DATA ANALYSIS SERVICE
                        <hr class="hrline"></hr>
                        </div>
                        
                        <Grid container direction='row' justify='space-evenly' alignItems='center' style={{ width: '-webkit-fill-available' }}>
                        
                            <Grid item xs={12} sm={6} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={5}>
                            
                                <div className="card-box card-shadow-first m-4 ">
                                <h2 className="devtext text-left" style={{marginLeft:"1rem"}} >STATISTICS</h2>
                                    {/* <div className="header3" style={{ textAlign: 'left' }}> BIG-DATA ANALYSIS SERVICE</div> */}
                                    <ListItem className="mt-4">
                                        <ListItemIcon>
                                            <FiberManualRecordIcon style={{ fontSize: '10px' }} />
                                        </ListItemIcon>
                                        <ListItemText className="ecotext1" primary="Provides big data analysis service for the efficient management of the enterprise " />
                                    </ListItem>
                                    <ListItem className="mt-2">
                                        <ListItemIcon>
                                            <FiberManualRecordIcon style={{ fontSize: '10px' }} />
                                        </ListItemIcon>
                                        <ListItemText className="ecotext1" primary="Big data analysis helps the organization to better understand the business data and also helps to identify the most important data for future business decisions" />
                                    </ListItem>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={5}>
                                <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                    <img alt="..." className="img-fluid p-2" src={bigdata} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={1}>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="containertext" >
                        <div>
                            <div className="header3">ONLINE MARKET SERVICE
                            <hr class="hrline"></hr>
                                <h4 className="m-4 ecotext ">Clients can purchase any product or solution from the Online market as shown below: </h4>
                            </div>
                            <Grid container direction='row' justify='space-evenly' alignItems='center' className="m-4 mb-4" style={{ width: '-webkit-fill-available' }} >
                                <Grid item xs={12} sm={12} lg={1}>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={10} style={{marginBottom: "2rem"}}>
                                    <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                                        <img alt="..." className="img-fluid p-2" src={onlineimg} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={1}>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default (ApplicationPage);