import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import './Application.css';
import { Link } from "react-router-dom";
import { REACT_URL_DASHBOARD } from "../../actions/ReactEndPoints";
import TimeLine from '../Layout/Breadcrumb';
import DevPortalLabel from '../Layout/DevPortalLabel';
import './ClientCredentialsStyle.css';

import { withTranslation } from 'react-i18next';


class ClientCredentials extends Component {
   
  render() {
    const { t } = this.props;
    const { clientId, clientSecrate } = this.props.match.params;
    return (
      <div className="projects clientCredentialsStyle">
        <DevPortalLabel></DevPortalLabel>
        <TimeLine path={t("applicationsClientCredentials")} />
        <Grid   xs={8} className="container ApplicationDetailContainer">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4} >
              <Box display="flex" justifyContent="flex-start" p={1} >
                <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flext-start" p={1} >
                <Box display="flex" justifyContent="flex-start" p={1} width="100%">
                </Box>
              </Box>
            </Grid>
          </Grid>
          <div className="clientCredentialsBlock">
            <Box display="flex" p={1} bgcolor="rgb(193, 193, 193, 0.5)" color="black" boxShadow="0px 0px 1px #FFFFFFC4" justifyContent="flex-end">
              <Box flexGrow={1}>
                {t("clientCredential")}
                    </Box>
              <Box>
              </Box>
            </Box>
          </div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flext-start" p={1} >
                <Box display="flex" justifyContent="flex-start" p={1}>
                  <label>
                    <small className="clientCredentialsClientIdTitle">{t("clientId")}</small>
                    <br />
                    <small className="clientCredentialsClientIdInfo">{clientId}</small>
                  </label>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flext-start" p={1} >
                <Box display="flex" justifyContent="flex-start" p={1} >
                  <label>
                    <small className="clientCredentialsClientSecretTitle">{t("clientSecretOneTime")}</small>
                    <br />
                    <small className="clientCredentialsClientSecretInfo">{clientSecrate}</small>
                  </label>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" p={1} >
                <Box p={3} >
                  <Button variant="outlined" color="primary"  component={Link} to={REACT_URL_DASHBOARD}>{t("back")}</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}




//export default ClientCredentials;

export default withTranslation()(ClientCredentials);



