import React,{Component}from'react';import"./ComingSoon.css";
import{Link}from'react-router-dom';
import{withTranslation}from'react-i18next';
import Button from'@material-ui/core/Button';
import{Grid}from"@material-ui/core";

import{connect}from"react-redux";
import{compose}from'redux';
import{login}from"../../actions/securityActions";

class ComingSoon extends Component {
	render() {
        const { t } = this.props;
        return (
        		<Grid container  
        		  spacing={1}
        		  direction="column"
        		  alignItems="center"
        		  justify="center"
        		  style={{ minHeight: '100vh' }} >
               <Grid  xs={12}  md={4}  ></Grid>
               <Grid     xs={12} md={4}  >
                   <div style={{border:"solid 2px #f5f6f7",padding:"32px",borderTop: "solid 4px #0099ff"}}  >
                   {t("onlineMarketTab")} 
                   <div   style={{ textAlign:"center",margin:"22px"}}>
                       <Button variant="contained" color="primary"   style={{
                           borderRadius: 10,
                           backgroundColor: "#002143",
                           padding: "10px 24px",
                           fontSize: "14px"
                       }} component={Link} to={{pathname:"../dev/login"}}  >{t("goToLoginpage")}</Button>
                   </div>
                </div>
                 </Grid>
                 <Grid  xs={12}  md={4} ></Grid>
                 </Grid>
        );
    }
}

const mapStateToProps=state=>({security:state.security});

export default compose(withTranslation(),connect(mapStateToProps,{login}))(ComingSoon);