import { GET_APPLICATION, GET_FEATURE, GET_USERROLE, GET_MEMBER, GET_APPLICATIONS, GET_MEMBERLIST } from "../actions/types";

const initialState = {
	devapp: {},
	appFeature:{},
	appMember:{},
	appUserRole:{},
	devAppList:{},
	memberList:{}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_APPLICATION:
			return {
				...state,
				devapp: action.payload
			};
		case GET_FEATURE:
			return {
				...state,
				appFeature: action.payload
			};
		case GET_MEMBER:
			return {
				...state,
				appMember: action.payload
			};
		case GET_MEMBER:
			return {
				...state,
				appMember: action.payload
			};
		case GET_MEMBERLIST:
			return {
				...state,
				memberList: action.payload
			};
		case GET_USERROLE:
			return {
				...state,
				appUserRole: action.payload
			};
		case GET_APPLICATIONS:
			return {
				...state,
				devAppList: action.payload
			};


		default:
			return state;
	}
}
