import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { NavLink } from 'react-router-dom';

class TimeLine  extends Component {
	constructor(props){
		super(props)
		this.setState(this.props);
	}
  render() {
    
    return (
		
      <div className="root">
        <Grid container style={{paddingTop:"2%"}} justify="center">
			<Grid item xs={10} sm={8} style={{borderBottom:"1px solid #0099FF",boxShadow:"none !important"}}>



				<NavLink to={this.props.page || "/dev/apps/"} style={{ textDecoration: 'none', color: 'unset' }} >
					<label className="timeLine" >
						{this.props.path || 'Applications' }
					</label><AccountCircleIcon style={{color:"#0099FF"}}/>
				</NavLink >

				
			</Grid>
		</Grid>
       
      </div>
    );
  }
}

export default TimeLine;