import React, { Component } from 'react';
import './Application.css';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid'
import { updateUserRole } from "../../actions/UserRoleAction";
import MaterialTable from "material-table";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUserRole } from "../../actions/UserRoleAction";
import './Application.css';
import { Button } from '@material-ui/core';
import IntervalTimer from 'react-interval-timer';
import './UserRoleDataTableStyle.css';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class UserRoleDataTable extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			name: "",
			featureName: "",
			developerId: this.props.developerId,
			applicationId: this.props.appId,
			userRoles: [],
			isLoading: true,
			appRole: this.props.appRole,
			appType: this.props.appType,
			data: [],
			iserror: false,
			errorMessages: [],
			errors: {},
			show: ''
		};

		this.getFeatureList = this.getFeatureList.bind(this);
		this.handleRowUpdate = this.handleRowUpdate.bind(this);
		this.handleRowDelete = this.handleRowDelete.bind(this);
		this.handleRowAdd = this.handleRowAdd.bind(this);
	}



	handleRowUpdate = (newData, oldData, resolve) => {
		//validation
		const { t } = this.props;
		let errorList = []
		var codevalidations = /^[a-zA-Z0-9-_]+$/.test(newData.code)
		var OK = /^\d*[a-zA-Z][a-zA-Z0-9_ ]*$/.test(newData.name)
		 if (newData.name === "") {
			errorList.push(t("pleaseEnterRoleName"))
		}
		else if (!OK) {
			errorList.push(t("allowsAlphaNumeric"));
		}

		
		else if (newData.code === "" || newData.code === undefined) {
			errorList.push(t("usercode"))

		}
		else if (!codevalidations) {
			errorList.push(t("userValidcode"))

		}

		if (errorList.length < 1) {
			const updateUserRole = {
				id: newData.id,
				name: newData.name,
				code: newData.code,
			};
			this.props.updateUserRole(this.state.applicationId, updateUserRole, this.props.history, this.state.appType, this.state.appRole);
			setTimeout(() => {
				axios.get("/api/v1.0/applications/" + this.state.applicationId + "/userRoles")
					.then((response) => {
						this.setState({
							userRoles: response.data.data, isLoading: false
						});
					});
				if ((Object.keys(this.state.errors).length !== 0)) {
					this.setState({ show: '' })
				}
				resolve();
			}, 500)



		} else {
			debugger
			this.setState({ errorMessages: errorList })
			this.setState({ iserror: true, show: '' })
			resolve()

		}

	}

	handleRowAdd = (newData, resolve) => {

		//validation
		const { t } = this.props;
		let errorList = []
		var codevalidations = /^[a-zA-Z0-9-_]+$/.test(newData.code)
		var OK = /^\d*[a-zA-Z][a-zA-Z_ ]*$/.test(newData.name)
		if (newData.name === "" || newData.name === undefined) { 
			errorList.push(t("pleaseEnterRoleName"))
		}
		else if (!OK) { 
			errorList.push(t("allowsAlphaNumeric"))
		}

		else if (newData.code === "" || newData.code === undefined) {
			errorList.push(t("usercode"))
		}
		else if (!codevalidations) {
			errorList.push(t("userValidcode"))
		}

		if (errorList.length < 1) {
			const newFeature = {
				name: newData.name,
				code: newData.code,

			};
			this.props.createUserRole(this.state.applicationId, newFeature, this.props.history, this.state.appType, this.state.appRole);
			setTimeout(() => {
				axios.get("/api/v1.0/applications/" + this.state.applicationId + "/userRoles")
					.then((response) => {
						this.setState({
							userRoles: response.data.data, isLoading: false
						});
					});
				if ((Object.keys(this.state.errors).length !== 0)) {
					this.setState({ show: '' })
				}
				resolve();
			}, 500)

		} else {
			this.setState({ errorMessages: errorList })
			this.setState({ iserror: true, show: '' })
			resolve()
		}


	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
			const {
				id,
				name
			} = nextProps.appuserRole;
			this.setState({
				id,
				name
			});
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true });

		axios.get("/api/v1.0/applications/" + this.state.applicationId + "/userRoles")
			.then((response) => {
				this.setState({
					userRoles: response.data.data, isLoading: false
				});
			});

		axios.get("/userRole/global")
			.then((response) => {
				this.setState({
					globalRoles: response.data.data, isLoading: false
				});
			});

	}

	getFeatureList() {
		this.setState({ isLoading: true });
		axios.get("/api/v1.o/applications/" + this.state.applicationId + "/features")
			.then((response) => {
				this.setState({
					features: response.data.data, isLoading: false
				});
			});

	}

	async handleRowDelete(id, resolve) {
		await axios.delete(`/api/v1.0/user-roles/${id}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}).then(() => {
			let updated = [...this.state.userRoles].filter(i => i.id !== id);
			this.setState({ userRoles: updated });
		});
		resolve();
	}



	render() {
		const { t } = this.props;
		const columns = [
			{ title: "id", field: "id", hidden: true },
			{
				title: t("userRoleName"), field: "name",
				headerStyle: {
					color: 'black',
					backgroundColor: 'transparent',
				}
			}, {
				title: t("code"), field: "code",
				headerStyle: {
					color: 'black',
					backgroundColor: 'transparent',
				}
			}
		];

		const tableIcons = {
			Add: forwardRef((props, ref) => (<Button {...props} ref={ref} variant="contained" style={{ background: "#0099FF", color: "white" }} disabled>{t("create")}</Button>
			)),
			Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={{ fill: 'green' }} />),
			Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'red' }} />),
			Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ fill: 'black' }} />),
			DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ fill: 'black' }} />),
			Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ fill: 'black' }} />),
			Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={{ fill: 'black' }} />),
			FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={{ fill: 'black' }} />),
			LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={{ fill: 'black' }} />),
			NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={{ fill: 'black' }} />),
			PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={{ fill: 'black' }} />),
			ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={{ fill: 'black' }} />),
			Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={{ fill: 'black' }} />),
			SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={{ fill: 'black' }} />),
			ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={{ fill: 'black' }} />),
			ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{ fill: 'black' }} />)
		};
		const { errors } = this.state;



		return (
			<div className={"App dataTableContainer userRoleDataTableStyle"}>
				<Grid className="userRoleDataTableMaterialTable" container  >
					<Grid item xs={2}></Grid>
					<Grid item xs={12} sm={8}>
						<div>
							{errors.name &&
								<Alert severity="error" onClose={() => this.setState({ show: "none" })} style={{ display: this.state.show }}>
									{errors.name && (
										<div>{errors.name}</div>
									)}
									<IntervalTimer
										timeout={2000}
										callback={() => this.setState({ show: "none" })}
										enabled={true}
										repeat={true}
									/>
								</Alert>
							}

							{this.state.iserror &&
								<Alert severity="error" onClose={() => this.setState({ show: "none" })} style={{ display: this.state.show }}>
									{this.state.errorMessages.map((msg, i) => {
										return <div key={i}>{msg}</div>
									})}
									<IntervalTimer
										timeout={2000}
										callback={() => this.setState({ show: "none" })}
										enabled={true}
										repeat={true}
									/>
								</Alert>
							}
						</div>
						<MaterialTable
							title={t("userRoles")}
							columns={columns}
							data={this.state.userRoles}
							icons={tableIcons}
							color="black"
							options={{
								actionsColumnIndex: -1,
								addRowPosition: 'first',
								showTitle: false,
								toolbarButtonAlignment: "left",
								toolbar: this.state.appRole !== 'TEAM_MEMBER',
								headerStyle: { position: 'sticky', top: 0 },
								maxBodyHeight: 500,
							}}
							localization={{
								body: {
									emptyDataSourceMessage: t("emptyRecordsInfo"),
									editTooltip: t("edit"),
									deleteTooltip: t("delete"),
									addTooltip: t("create"),
									editRow: {
										deleteText: t("deleteWarning"),
										cancelTooltip: t("cancel"),
										saveTooltip: "",
									},
								},
								pagination: {
									labelDisplayedRows: '{from}-{to}' + "  " + t("of") + "  " + '{count}',
									labelRowsSelect: t("rows"),
									firstAriaLabel: t("firstPage"),
									firstTooltip: t("firstPage"),
									previousAriaLabel: t("previousPage"),
									previousTooltip: t("previousPage"),
									nextAriaLabel: t("nextPage"),
									nextTooltip: t("nextPage"),
									lastAriaLabel: t("lastPage"),
									lastTooltip: t("lastPage"),
								},
								header: {
									actions: t("action")
								},
								toolbar: {
									searchPlaceholder: t("searchPlaceholder"),
									searchTooltip: t("search")
								},



							}}
							editable={{
								isDeleteHidden: rowData => (rowData.isGlobal || this.state.appRole === 'TEAM_MEMBER'),
								isEditHidden: rowData => (rowData.isGlobal || this.state.appRole === 'TEAM_MEMBER'),
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve) => {


										this.handleRowUpdate(newData, oldData, resolve);

									}),
								onRowAdd: (newData) =>
									new Promise((resolve) => {
										this.handleRowAdd(newData, resolve)
									}),
								onRowDelete: (oldData) =>
									new Promise((resolve) => {
										this.handleRowDelete(oldData.id, resolve)
									}),
							}}
						/>
					</Grid>
					<Grid item xs={2}></Grid>
				</Grid>
			</div>
		);


	}
}

UserRoleDataTable.propTypes = {
	updateUserRole: PropTypes.func.isRequired,
	createUserRole: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	security: PropTypes.object.isRequired,
	//getUserRole: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
	devapp: state.devapp.devapp,
	appuserRole: state.devapp.appUserRole,
	errors: state.errors,
	security: state.security
});

/* export default connect(
	mapStateToProps,
	{ createUserRole, updateUserRole }
)(UserRoleDataTable); */

export default compose(
	withTranslation(), connect(mapStateToProps, { createUserRole, updateUserRole }))(UserRoleDataTable);
