import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../actions/securityActions";
import { Grid, Card, CardHeader, CardActionArea, CardContent, Button, Typography, Avatar } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { REACT_PATH_DASHBOARD } from "../../actions/ReactEndPoints";
import { createNewUser } from "../../actions/securityActions";
import code from '../../images/code-small.png';
import './RegisterStyle.css';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withTranslation } from 'react-i18next';
import {compose} from 'redux';
import { default as i18n } from 'i18next';
class Register extends Component {
  state = {
    isPasswordShown: false,
    isPasswordShown1: false
  };

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  togglePasswordVisiblity1 = () => {
    const { isPasswordShown1 } = this.state;
    this.setState({ isPasswordShown1: !isPasswordShown1 });
  };
  constructor() {
    super();

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      errors: {},
      autoPassword:'',
      isAutoGeneratedPassword : false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.generateP=this.generateP.bind(this);
		this.getSelectedItem = this.getSelectedItem.bind(this);
		this.generatePassword = this.generatePassword.bind(this);
		this.shuffleArray = this.shuffleArray.bind(this);
     
  }

  componentDidMount() {
    if (this.props.security.validToken) {
      this.props.history.push(REACT_PATH_DASHBOARD);



    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  
   generatePassword(passwordLength) {
		var numberChars = "0123456789";
		var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var lowerChars = "abcdefghiklmnopqrstuvwxyz";
	  var symbols = '!@#$%&*';
		var allChars = numberChars + upperChars + lowerChars + symbols;
		var randPasswordArray = Array(passwordLength);
	  randPasswordArray[0] = numberChars;
	  randPasswordArray[1] = upperChars;
	  randPasswordArray[2] = lowerChars;
	  randPasswordArray[3] = symbols;
	  randPasswordArray = randPasswordArray.fill(allChars, 4);
	  return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
	}
	
	shuffleArray(array) {
	  for (var i = array.length - 1; i > 0; i--) {
	    var j = Math.floor(Math.random() * (i + 1));
	    var temp = array[i];
	    array[i] = array[j];
	    array[j] = temp;
	  }
	  return array;
	}

  generateP() {

    
    this.setState({autoPassword :this.generatePassword(15)})
    
  }

  getSelectedItem(val) {
    this.setState({confirmPassword :val,password:val})
	}

 

  onSubmit(e) {
    e.preventDefault();
    const newDeveloper = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    };
   
    this.props.createNewUser(newDeveloper, this.props.history);
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
let icon = null;
    if (this.state.name === "Password") {
      icon = <Visibility />;
    }

    const top100Films = [
  { code: 'Use a securely Generate Password', title:this.state.autoPassword, year: 1994 },
   ];
    const { isPasswordShown } = this.state;
    const { isPasswordShown1 } = this.state;
    const { t } = this.props;
    const { errors } = this.state;
    return (
      <div className="registration-form">
        <Grid className="registerStyle" container>
          <Grid className="registerStyleItem" item sm={12} md={6} lg={8} xl={9}>
            <div className="login-component">
              <Grid container>
                <Grid item lg={1}></Grid>
                <Grid item md={12} sm={12} lg={11}>
                  <Card className="registration-card">
                    <CardContent>
                      <Typography className="registration-card-header" gutterBottom>
                        {t("minervaIot")}
                      </Typography>
                      <Typography className="registerStyleCardSubHeader" variant="h5" component="h2">
                        {t("developerCenter")}
                      </Typography>
                      <Typography className="registration-card-body">
                        {t("developerAppWideRangeServices")}
                      </Typography>
                      <Avatar size={100} className="registration-page-avatar"><img src={code} alt="Code" /></Avatar>
                      <hr></hr>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>

          </Grid>
          <Grid className="registerStyleItem" item xs={12} sm={12} md={6} lg={4} xl={3}>

            {/* ================ */}

            <Grid container justify="center" className="register-form-area">

              <Grid item xs={12} sm={12}>
                <Card className="card-container">
                  <CardHeader className="card-header-1"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    title={t("developer")}

                  />
                  <CardHeader className="card-header-2 textAlignCenter"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    title={t("signUp")}
                  />
                  <form onSubmit={this.onSubmit} className="input-field registerForm">
                    <CardContent>
                      <FormControl className="login-form-control">
                        {/* <Grid container spacing={1} p={2} alignItems="flex-end">
                          <Grid item className="form-label">
                            <PersonIcon />
                          </Grid>
                          <Grid item>
                            First Name
                          </Grid>
                        </Grid>

                        <TextField p={2}
                          type="text"                          
                          error={errors.firstName ? true : false}
                          helperText={errors.firstName}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.firstName}
                          onChange={this.onChange}
                          name="firstName"
                          id="firstName"
                          inputProps={{ pattern: '[a-zA-Z ]{2,30}', title: "Characters only of length 2 to 30" }}
                        /> */}
                        <Grid container spacing={1}>
                          <Grid className="iconGrid" item xs={1}>
                            <PersonIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField 
                              p={2} 
                              type="text" 
                              name="firstName" 
                              id="firstName" 
                              label={t("firstName")} 
                              placeholder="" 
                              autoComplete="off" 
                              onChange={this.onChange} 
                              error={errors.firstName ? true : false}
                              helperText={errors.firstName} 
                              value={this.state.firstName} 
                              // InputLabelProps={{ shrink: false }} 
                              inputProps={{ pattern: '[a-zA-Z ]{2,30}', title:i18n.t('validFirstName') }} 
                           
                            />
                          </Grid>
                          {/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{errors.firstName}</FormHelperText> */}
											  </Grid>
                      </FormControl>
                      <FormControl className="login-form-control">
                        {/* <Grid container spacing={1} p={2} alignItems="flex-end">
                          <Grid item className="form-label">
                            <PersonIcon />
                          </Grid>
                          <Grid item>
                            Last Name
                          </Grid>
                        </Grid>

                        <TextField p={2}
                          type="text"
                          error={errors.lastName ? true : false}
                          helperText={errors.lastName}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.lastName}
                          onChange={this.onChange}
                          name="lastName"
                          id="lastName"
                          inputProps={{ pattern: '[a-zA-Z ]{1,30}', title: "Characters only of length 1 to 30" }}
                        /> */}
                        <Grid container spacing={1}>
                          <Grid className="iconGrid" item xs={1}>
                            <PersonIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField 
                              p={2} 
                              type="text" 
                              name="lastName" 
                              id="lastName" 
                              label={t("lastName")} 
                              placeholder=" " 
                              autoComplete="off" 
                              onChange={this.onChange} 
                              error={errors.lastName ? true : false}
                              helperText={errors.lastName} 
                              value={this.state.lastName} 
                              // InputLabelProps={{ shrink: false }} 
                              inputProps={{ pattern: '[a-zA-Z ]{1,30}',title:i18n.t('validLastName')}} 
                            />
                          </Grid>
                          {/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{errors.lastName}</FormHelperText> */}
											  </Grid>
                      </FormControl>
                      <FormControl className="login-form-control">
                        {/* <Grid container spacing={1} alignItems="flex-end">
                          <Grid item className="form-label">
                            <EmailIcon />
                          </Grid>
                          <Grid item >
                            Email
                          </Grid>
                        </Grid>

                        <TextField p={2}
                          type="email"                          
                          error={errors.email ? true : false}
                          helperText={errors.email}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.email}
                          onChange={this.onChange}
                          name="email"
                          id="email"
                          inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$", title: "Please enter a valid email address." }}
                        /> */}
                        <Grid container spacing={1}>
                          <Grid className="iconGrid" item xs={1}>
                            <EmailIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField 
                              p={2} 
                              type="email" 
                              name="email" 
                              id="email" 
                              label= {t("email")}  
                              placeholder="" 
                              autoComplete="off" 
                              onChange={this.onChange} 
                              error={errors.email ? true : false} 
                              helperText= {errors.email} 
                              value={this.state.email} 
                              // InputLabelProps={{ shrink: false }} 
                              inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",title:i18n.t('validEmailAddress') }} 
                            />
                          </Grid>
                          {/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{errors.email}</FormHelperText> */}
                        </Grid>
                      </FormControl>
                      <FormControl className="login-form-control">
                        {/* <Grid container spacing={1} alignItems="flex-end">
                          <Grid item className="form-label">
                            <PhoneIcon />
                          </Grid>
                          <Grid item>
                            Contact
                          </Grid>
                        </Grid>

                        <TextField p={2}
                          type="text"
                          inputProps={{ pattern: '[0-9]{8,15}', title: "Numbers only of length 8 to 15" }}
                          autoComplete="off"
                          error={errors.phone ? true : false}
                          helperText={errors.phone}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.phone}
                          onChange={this.onChange}
                          name="phone"
                          id="phone"
                        /> */}
                        <Grid container spacing={1}>
                          <Grid className="iconGrid" item xs={1}>
                            <PhoneIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <TextField 
                              p={2} 
                              type="text" 
                              name="phone" 
                              id="phone" 
                              label={t("contact")}
                              placeholder=" " 
                              autoComplete="off" 
                              onChange={this.onChange} 
                              error={errors.phone ? true : false} 
                              helperText= {errors.phone} 
                              value={this.state.phone} 
                              // InputLabelProps={{ shrink: false }} 
                              inputProps={{ pattern: '[0-9]{8,15}', title:i18n.t('validPhoneNumber')}} 
                            />
                          </Grid>
                          {/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{errors.phone}</FormHelperText> */}
                        </Grid>
                      </FormControl>
                      <FormControl className="login-form-control">
                        {/* <Grid container spacing={1} alignItems="flex-end">
                          <Grid item className="form-label">
                            <LockIcon style={{ fill: "#0098FF5C " }} />
                          </Grid>
                          <Grid item>
                            Password
                          </Grid>
                        </Grid>
                        <div>  
                          <div className="passwordContainer" style={{float:'right', position: 'relative', top: '7px', right: '-11px', zIndex: '999'}}>             
                            <InputAdornment position="end">
                              <IconButton
                                className="password-icon"
                                tabindex="-1"
                                aria-label="toggle password visibility"
                                onClick={this.togglePasswordVisiblity}
                              >
                                {isPasswordShown ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>                            
                          </div>
                          <div>
                            <Autocomplete
                              id="virtualize-demo"
                              freeSolo
                              options={top100Films}
                              autoHighlight
                              getOptionLabel={(option) => option.title }
                              onChange={(event, newValue) => {
                                if(newValue!=null)
                                this.getSelectedItem(newValue.title);
                              }}
                              renderOption={(option) => (
                                <React.Fragment>
                                  <span>{option.title}</span>
                                </React.Fragment>
                              )}
                              groupBy={(option) => 
                                <React.Fragment>
                                  <span style={{fontWeight:"bold",color:"black"}}>{option.code}</span>
                                </React.Fragment>
                              }
                              renderInput={(params) => (
                                <TextField className="passwordInputFiled" { ...params}
                                  error={errors.password ? true : false}
                                  helperText={errors.password}
                                  InputLabelProps={{ shrink: false }}
                                  autoComplete="off"
                                  value={this.state.password}
                                  onChange={this.onChange}
                                  name="password"
                                  id="password"
                                  type={isPasswordShown ? "text" : "password"}
                                  InputLabelProps={{ shrink: false }}
                                  margin="normal" 
                                  onClick={this.generateP} 
                                  inputProps={{
                                    ...params.inputProps, pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$',
                                    title: 'At least One upper case one lower case and one special charcter. Minimum 8 maximum 30' 
                                  }}
                                />
                              )}                  
                            />
                          </div>
                        </div> */}
                        <Grid container spacing={1}>
                          <Grid item xs={1} className="form-label iconGrid">
                            <LockIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <div>  
                              <div className="passwordContainer" style={{float:'right', position: 'relative', top: '25px', right: '-11px', zIndex: '999'}}>             
                                <InputAdornment position="end">
                                  <IconButton
                                    className="password-icon"
                                    tabindex="-1"
                                    aria-label="toggle password visibility"
                                    onClick={this.togglePasswordVisiblity}
                                  >
                                    {isPasswordShown ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>                            
                              </div>
                              <div>
                                <Autocomplete
                                  id="virtualize-demo"
                                  freeSolo
                                  options={top100Films}
                                  autoHighlight
                                  getOptionLabel={(option) => option.title }
                                  onChange={(event, newValue) => {
                                    if(newValue!=null)
                                    this.getSelectedItem(newValue.title);
                                  }}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      <span>{option.title}</span>
                                    </React.Fragment>
                                  )}
                                  groupBy={(option) => 
                                    <React.Fragment>
                                      <span style={{fontWeight:"bold",color:"black"}}>{option.code}</span>
                                    </React.Fragment>
                                  }
                                  renderInput={(params) => (
                                    <TextField className="passwordInputFiled" { ...params}
                                      error={errors.password ? true : false}
                                      helperText={errors.password} 
                                      // InputLabelProps={{ shrink: false }}
                                      autoComplete="off"
                                      value={this.state.password}
                                      onChange={this.onChange}
                                      name="password"
                                      id="password" 
                                      label={t("password")}
                                      placeholder=" "
                                      type={isPasswordShown ? "text" : "password"}
                                      margin="normal" 
                                      onClick={this.generateP} 
                                      inputProps={{
                                        ...params.inputProps, pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$',
                                        title:i18n.t('validPassword') 
                                      }}
                                    />
                                  )}                  
                                />
                              </div>
                            </div>
                          </Grid>
                          {/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{errors.password}</FormHelperText> */}
                        </Grid>
                      </FormControl>
                      <FormControl className="login-form-control">
                        {/* <Grid container spacing={1} alignItems="flex-end">
                          <Grid item className="form-label">
                            <LockIcon style={{ fill: "#0098FF5C " }} />
                          </Grid>
                          <Grid item>
                            Confirm Password
                          </Grid>
                        </Grid>
                        <TextField 
                          p={2}
                          error={errors.confirmPassword ? true : false}
                          helperText={errors.confirmPassword}
                          InputLabelProps={{ shrink: false }}
                          autoComplete="off"
                          value={this.state.confirmPassword}
                          onChange={this.onChange}
                          name="confirmPassword"
                          id="confirmPassword"
                          type={isPasswordShown1 ? "text" : "password"}
                          InputLabelProps={{ shrink: false }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{position: 'relative', top: '-6px', right: '-13px'}}
                                  className="password-icon one "
                                  tabindex="-1"
                                  aria-label="toggle password visibility"
                                  onClick={this.togglePasswordVisiblity1}
                                >
                                  {isPasswordShown1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$', title: 'At least One upper case one lower case and one special charcter. Minimum 8 maximum 30' }}
                        /> */}
                        <Grid container spacing={1}>
                          <Grid item xs={1} className="form-label iconGrid">
                            <LockIcon />
                          </Grid>
                        <Grid item xs={11}>
                          <TextField 
                            p={2}
                            error={errors.confirmPassword ? true : false}
                            helperText={errors.confirmPassword} 
                            autoComplete="off"
                            value={this.state.confirmPassword}
                            onChange={this.onChange}
                            name="confirmPassword"
                            id="confirmPassword"
                            label={t("confirmPasswod")} 
                            placeholder=" "
                            type={isPasswordShown1 ? "text" : "password"}
                            // InputLabelProps={{ shrink: false }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    style={{position: 'relative', top: '-6px', right: '-13px'}}
                                    className="password-icon one"
                                    tabindex="-1"
                                    aria-label="toggle password visibility"
                                    onClick={this.togglePasswordVisiblity1}
                                  >
                                    {isPasswordShown1 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{ pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$',title:i18n.t('validPassword')}}
                          />
                        </Grid>
                        {/* <FormHelperText style={{marginLeft:"32px"}} error={true}>{errors.confirmPassword}</FormHelperText> */}
                      </Grid>
                    </FormControl>

                    <Grid container spacing={5} justify="center" className="textAlignCenter">
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div>{t("haveAccount")}<a className="link" href="/dev/login">{t("signIn")}</a></div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}><Button className="login-button" variant="contained" type="submit">{t("signUp")}</Button></Grid>
                    </Grid>
                      
                  </CardContent>
                </form>
                  <CardActionArea>

                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>

            {/* ====================== */}

          </Grid>

        </Grid>
      </div>
    );
  }
}

Register.propTypes = {
  createNewUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  security: state.security
});
/* export default connect(
  mapStateToProps,
  { createNewUser }
)(Register); */

export default compose(
	withTranslation(),connect(mapStateToProps, {createNewUser}))(Register);
