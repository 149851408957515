import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceArrDet from './ServiceListDetails';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3} component='div'>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',

      backgroundColor: '#0880d4',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: 'black !important',
    display: 'inline',
    opacity: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    border: 'none',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      outline: 'none',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor:"#00506E",
    flexGrow: 1,
  },

  styletabs: {
    //background:"#005a72",

    padding: theme.spacing(2),
  },
}));

export default function ServiceTabs(props) {
  const publicServiceArr = props.publicServiceArr;
  const internalServiceArr = props.internalServiceArr;
  const id = props.id;
  const role = props.role;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  let displayStatus = role === 'EXTERNAL_DEVELOPER' ? 'none' : '';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <div className={classes.styletabs}>
        <Grid container className='serviceTabs'>
          <Grid item xs={12}>
            <StyledTabs value={value} onChange={handleChange} aria-label='styled tabs example' style={{ float: 'right', paddingRight: '13%' }}>
              <StyledTab label={t('public')} {...a11yProps(0)} />
              <StyledTab label={t('internal')} {...a11yProps(1)} style={{ display: displayStatus }} />
            </StyledTabs>
          </Grid>
        </Grid>
      </div>
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction} style={{ padding: 0, margin: 0, minHeight: 500 }}>
          <ServiceArrDet key='0' ServiceArr={publicServiceArr} id={id} role={role} user={props.user} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} style={{ padding: 0, margin: 0, minHeight: 500 }}>
          <ServiceArrDet key='1' ServiceArr={internalServiceArr} id={id} role={role} user={props.user} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
