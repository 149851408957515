import React, { Component } from "react";
import { ButtonGroup } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {
  Grid,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import TimeLine from "../Layout/Breadcrumb";
import {
  REACT_PATH_APP_HOME,
  REACT_PATH_APP_ADD,
} from "../../actions/ReactEndPoints";
import { Link } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddRoundedIcon from "@material-ui/icons/PlaylistAddRounded";
import DevPortalLabel from "../Layout/DevPortalLabel";
import NoImage from "../../images/NoImage.jpg";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

class Applications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.security.user,
      applications: [],
      developerId: "",
      success: false,
      show: "none",
      isLoading: true,
      open: false,
      deleteAppId: "",
    };
    this.remove = this.remove.bind(this);
    this.confirm = this.confirm.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleClickOpen(appId) {
    this.setState({ open: true, deleteAppId: appId });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    this.state.developerId = id;
    axios
      .get("/api/v1.0/applications/developers/" + this.state.user.id)
      .then((response) => {
        this.setState({
          applications: response.data.data,
          isLoading: false,
        });
      });
  }

  async remove(id) {
    this.setState({ show: "none", success: true });
    await axios
      .delete(`/api/v1.0/applications/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        let updatedAppList = [...this.state.applications].filter(
          (i) => i.id !== id
        );
        this.setState({ applications: updatedAppList });
        this.setState({ show: "", success: false, open: false });
      });
  }

  confirm = (id) => {
    const { t } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        const confirmStyle = {
          textAlign: "center",
          width: "500px",
          padding: "40px",
          background: "#28bae6",
          boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
          color: "#fff",
        };
        return (
          <div style={confirmStyle}>
            <h1>{t("forConfirmation")}</h1>
            <p>{t("conformationForDltApp")}</p>
            <ButtonGroup>
              <Button onClick={onClose}>{t("no")}</Button>
              <Button
                color="danger"
                onClick={() => {
                  this.remove(id);
                  onClose();
                }}
              >
                {t("yesDltStatus")}
              </Button>
            </ButtonGroup>
          </div>
        );
      },
    });
  };

  render() {
    const { t } = this.props;
    const { applications, isLoading } = this.state;
    const hidden = this.state.appRole === "ADMIN" ? "" : "none";
    if (isLoading) {
      return <p>Loading...</p>;
    }
    const btnStyle = {
      background: "#0099FF",
      "&:focus": {
        outline: "none !important",
        outlineOffset: "none !important",
        boxShadow: "none !important",
      },
      ":hover": {
        outline: "none !important",
        outlineOffset: "none !important",
        boxShadow: "none !important",
      },
    };
    const classes = {
      root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
      },
      gridList: {
        width: "100",
        height: "100",
      },
    };

    let appList = applications.map((application, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
        <Card
          className="root"
          style={{
            boxShadow: "0px 5px 5px #00000066",
            borderRadius: "5px",
            width: "100%",
            height: "350px",
            maxHeight: "350px",
            background:
              "transparent linear-gradient(180deg, #0984DB 0%, #004372 100%) 0% 0% no-repeat padding-box",
            opacity: 1,
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8} sm={8}>
                <div>
                  <Link
                    className="appName"
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      font: "normal normal small 12px/5px Lato",
                      letterSpacing: "0px",
                      color: "white",
                      opacity: "1",
                      wordWrap: "breakWord",
                      textTransform: "capitalize",
                    }}
                    to={{
                      pathname:
                        REACT_PATH_APP_HOME +
                        application.id +
                        "&" +
                        application.applicationType +
                        "&" +
                        application.role,
                      appId: application.id,
                      devId: this.state.developerId,
                      appRole: application.role,
                      appType: application.applicationType,
                    }}
                  >
                    {application.applicationName}
                  </Link>
                </div>
              </Grid>
              <Grid item xs={4} sm={4}>
                <div style={{ float: "right" }}>
                  <DeleteIcon
                    fontSize="small"
                    style={{
                      fill: "#FFFFFF",
                      display: application.role === "ADMIN" ? "" : "none",
                    }}
                    onClick={() => this.handleClickOpen(application.id)}
                  />
                  <Link
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      font: "normal normal small 12px/5px Lato",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      opacity: "1",
                    }}
                    to={{
                      pathname:
                        REACT_PATH_APP_HOME +
                        application.id +
                        "&" +
                        application.applicationType +
                        "&" +
                        application.role,
                      appId: application.id,
                      devId: this.state.developerId,
                      appRole: application.role,
                      appType: application.applicationType,
                    }}
                  >
                    <EditOutlinedIcon
                      fontSize="small"
                      style={{ color: "#FFFFF" }}
                    />
                  </Link>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    font: "normal normal small 12px/5px Lato",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: "1",
                  }}
                >
                  <label>
                    <small
                      style={{ textTransform: "uppercase", color: "#FFFFFFB3" }}
                    >
                      {" "}
                      {t("version")}
                    </small>
                    <br />
                    <small
                      style={{
                        textTransform: "capitalize",
                        color: "White",
                        wordBreak: "break-word",
                      }}
                    >
                      {application.version}
                    </small>
                  </label>
                </div>
                <div
                  style={{
                    paddingRight: "5px",
                  }}
                >
                  <img
                    src={
                      application.applicationLogo
                        ? application.applicationLogo
                        : NoImage
                    }
                    alt=""
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </div>
              </Grid>
              {application.applicationUrl && (
                <Grid item xs={12} sm={12}>
                  <small
                    style={{ textTransform: "uppercase", color: "#FFFFFFB3" }}
                  >
                    {" "}
                    {t("applicatioUrl")}
                  </small>
                  <br />
                  <Tooltip title={application.applicationUrl} placement="top">
                    <a
                      style={{
                        textAlign: "left",
                        textDecoration: "underline",
                        font: "normal normal small 12px/5px Lato",
                        letterSpacing: "0px",
                        color: "#FFFFFF",
                        opacity: "1",
                      }}
                      href={application.applicationUrl}
                      target="_blank"
                    >
                      {application.applicationUrl.length > 20
                        ? application.applicationUrl.substring(0, 20) + "..."
                        : application.applicationUrl}
                    </a>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    textAlign: "left",
                    font: "normal normal small 12px/5px Lato",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: "1",
                  }}
                >
                  <label>
                    <small
                      style={{ textTransform: "uppercase", color: "#FFFFFFB3" }}
                    >
                      {t("appType")}
                    </small>
                    <br />
                    <small
                      style={{ textTransform: "capitalize", color: "White" }}
                    >
                      {application.applicationType}
                    </small>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div
                  style={{
                    textAlign: "left",
                    font: "normal normal small 12px/5px Lato",
                    letterSpacing: "0px",
                    opacity: "1",
                    textTransform: "none",
                  }}
                >
                  <label>
                    <small
                      style={{ textTransform: "uppercase", color: "#FFFFFFB3" }}
                    >
                      {" "}
                      {t("appPlatform")}
                    </small>
                    <br />
                    <small
                      style={{ textTransform: "capitalize", color: "White" }}
                    >
                      {application.applicationPlatform}
                    </small>
                  </label>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display:
                    application.apiSpecificationLink === "" ||
                    application.apiSpecificationLink == null
                      ? "none"
                      : "",
                }}
              ></Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    ));

    if (appList.length < 1) {
      appList = (
        <Grid
          container
          style={{ paddingTop: "2%" }}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <label
            style={{
              font: "Montserrat,medium ",
              fontSize: "20px",
              marginRight: "5px",
              textAlign: "center",
              color: "#9E9E9E",
            }}
          >
            {t("forAppEmpty")}
            <br />
            {t("iconForAppCreate")}
          </label>
        </Grid>
      );
    }

    /*  const classes = useStyles(); */
    return (
      <div className="root">
        <DevPortalLabel></DevPortalLabel>
        <Grid>
          <TimeLine path={t("applications")} />
          <Grid container style={{ paddingTop: "1%" }}>
            <Grid item xs></Grid>
            <Grid
              item
              xs={8}
              style={{ border: "none", boxShadow: "none !importent" }}
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Fab
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                style={btnStyle}
                component={Link}
                to={REACT_PATH_APP_ADD}
              >
                <PlaylistAddRoundedIcon />
              </Fab>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs style={{ backgroundColor: "white" }}></Grid>
            <Grid
              item
              xs={8}
              style={{ border: "2px", boxShadow: "none !important" }}
            >
              <div style={classes.root}>
                <Grid container spacing={3} justify="flex-start">
                  {appList}
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              xs
              style={{ backgroundColor: "white" }}
              display="flex"
            ></Grid>
          </Grid>
        </Grid>

        <Dialog
          style={{ textAlign: "center" }}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>{t("deleteAccount")}</p>
            </DialogContentText>
          </DialogContent>
          <div style={{ marginRight: "25%" }}>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                variant="contained"
                color="primary"
              >
                {t("no")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  this.remove(this.state.deleteAppId);
                }}
              >
                {t("yes")}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  devAppList: state.devapp.devAppList,
  security: state.security,
});

/* export default connect(
  mapStateToProps
)(Applications); */

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(Applications);
