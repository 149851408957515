import axios from "axios";
import BrowserLanguage from "../components/Util/BrowserLanguage";

const setJWTToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setJWTToken;
