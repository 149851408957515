import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { emailValidate } from "../../actions/securityActions";
import { Grid, Card, CardHeader, CardActionArea, CardContent, Button, Typography  } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import "./EmailVerificationStyle.css";
import { withTranslation } from 'react-i18next';
import {compose} from 'redux';

class EmailVerification extends Component {
	constructor() {
		super();
		this.state = {
			email: "",
			errors: {}
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.clearEmail=this.clearEmail.bind(this);
	}

	clearEmail(){
		this.setState({
			errors:''
			
		})
		
	}
	onSubmit(e) {
		e.preventDefault();
		/*const emailValidateRequest = {
			email: this.state.email,
		};*/

		this.props.emailValidate(this.state.email,this.props.history);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
		this.setState({ errors: nextProps.errors });
		}
    }

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		//const { errors } = this.state;
		const { t } = this.props;
	 
		const card = {
			borderRight: "solid",
			borderLeft: "solid",
			borderBottom: "solid",
			borderWidth: "1px",
			borderColor: "#0098FF",
			boxShadow: "0px 3px 3px #00000066",
			margin: "10%",
		};

		const cardHeader1 = {
			padding: "0px",
			background: "#AAE5FF",
			font: "normal normal small 15px/18px Lato",
			fontSize:"small",
			letterSpacing: "0px",
			color: "#000000",
			height: "100%",
    		width: "100%",
			textAlign:"center",
			borderRadius:"100%",
			borderTopLeftRadius: 0,
  			borderTopRightRadius: 0,

		};

		const cardContent = {
			
		};

		const margin = {
			float: "inherit",
			width: "99%",
			marginBottom: "25px",
		};

		const button = {
			backgroundColor: "#ECF9FF",
			border: "solid",
			borderColor: "#0098FF5C",
			borderWidth: "1px",
			width: "30%",
		};

		 

		return (
			<div>
				<div >
					 <Grid container>
						<Grid item sm={12} md={6} lg={8} xl={9}  className="pt-60"  >
							<div className="login-component">
							<Grid container>
								<Grid lg={1} item></Grid>
								<Grid item md={12} sm={12} lg={11}>
								<Card className="login-card">
									<CardContent className="textAlignCenter">
									<Typography className="login-card-header" gutterBottom>
										{t("minervaIot")}
									</Typography>
									<Typography className="login-card-body">
										{t("iotRangeOfServices")}
									</Typography>
									<hr style={{
										color: "#cad0c4", width: "50%"
									}}></hr>
									</CardContent>
								</Card>
								</Grid>
							</Grid>
							</div>

						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} xl={3} className="form-area pt-60">

				
							<Grid container justify ="center">

							<Grid item xs={12}  sm={12}>
							<Card style={card}>
								<CardHeader style={cardHeader1}
								titleTypographyProps={{variant:'subtitle1' }}
									title={t("reset")}
								/>
								<form onSubmit={this.onSubmit} className="emailVerificationForm">
									<CardContent style={cardContent}>
										
										
										<FormControl style={margin}>
											<Grid container spacing={1}>
											
												<Grid className="iconGrid" item xs={1}>
													<PersonIcon />
												</Grid>
												<Grid xs={11} item>
													<TextField 
														p={2} 
														type="email" 
														label={t("email")}
														placeholder=" "
														required
														className="text-field"
														error={this.state.errors.email? true : false}
														helperText= {this.state.errors.email} 
														id="input-with-icon-textfield"
														// InputLabelProps={{ shrink: false }}
														autoComplete="off"
														value={this.state.email}
														onChange={this.onChange}
														onClick={this.clearEmail}
														name="email" 
														//id="email" 
														inputProps={{ pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$", title: t("emailVerification") }}
													/>
													{this.state.errors.email ? 
													"" : 
														<div className="inputHint">{t("enterRegisteredEmail")}</div>
													}
												</Grid>
											</Grid>
										</FormControl>
										
										
										
										

										{/*<Grid container spacing={1}  >

											<Grid item xs={6} >		
											<div style={{color:"#656565",float:"left", font:"normal normal small 12px/15px Lato", fontSize:"small"}}>
												Keep me logged in</div> 
												
												
											</Grid>
											<br/>
											<Grid item xs={6} >
												<div ><a href="/dev/emailVerification" style={{color:"#FF6E6E",  textDecoration: "underline",float:"right",  font:"normal normal small 12px/15px Lato", fontSize:"small"}}>Forgot password?</a></div>
											</Grid>
										</Grid>*/}
											<br/>
										<Grid container spacing={1}  justify="center">
											<Button style={button} variant="contained" type="submit"> {t("submit")} </Button>
										</Grid>
										<br/>
										<br/>
										{/*<Grid container spacing={1} justify="center">

											<Grid item>
												<div style={{color:"#656565",float:"left", font:"normal normal small 12px/15px Lato", fontSize:"small"}} >Don't have an account? <a href="/dev/signUp">Sign Up</a></div>
											</Grid>

										</Grid>*/}

									</CardContent>
								</form>
								<CardActionArea>

								</CardActionArea>
							</Card>
							</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}

EmailVerification.propTypes = {
	errors: PropTypes.object.isRequired,
	//security: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	errors: state.errors
});

export default compose(withTranslation(),connect(mapStateToProps, { emailValidate }))(EmailVerification);